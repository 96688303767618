import { Selection, CheckboxVisibility, CommandBar, Label, Link, MarqueeSelection, SelectionMode, ShimmeredDetailsList, mergeStyleSets, ColumnActionsMode, Panel, PanelType, IIconProps, DetailsListLayoutMode } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { toast } from "react-toastify";
import { ErrorInfo, UserDto } from "../../generated";
import { EntityChangedEventArgs } from "../../Services/ISignalRClient";
import { InjectedProps } from "../InjectedProps";
import CreateUser from "../Users/CreateUser";
import EditUser from "../Users/EditUser";
import PasswordIcon from '@mui/icons-material/Password';
import ChangePassword from "../Users/ChangePassword";

interface IUserInfo {
    key: string | number,
    displayName: string,
    firstName: string,
    lastName: string,
    eMail: string | undefined,
    account: string,
    accountId: string
}

interface UserListState {
    knownUsers: IUserInfo[],
    selectionDetails: string,
    isEditPanelOpen: boolean,
    isNewPanelOpen: boolean,
    isChangePasswordPanelOpen: boolean,
    editPanelUserName: string | undefined,
    changePasswordUserKey: string | number | undefined,
    userDto: UserDto[] | undefined,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class UserList extends Component<InjectedProps, UserListState> {
    static defaultProps = {} as InjectedProps;
    private listSelection: Selection;

    constructor(props: InjectedProps, state: UserListState) {
        super(props);

        this.ListItemInvoked = this.ListItemInvoked.bind(this);
        this.UsersChanged = this.UsersChanged.bind(this);
        this.UserChangeCanceled = this.UserChangeCanceled.bind(this);
        this.DownloadUsers = this.DownloadUsers.bind(this);
        this.UpdateCurrentUsersAsync = this.UpdateCurrentUsersAsync.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);

        this.UserModified = this.UserModified.bind(this);

        this.listSelection = new Selection({
            onSelectionChanged: () => this.setState({ ...this.state, selectionDetails: this.GetSelectionDetails() })
        });

        this.state = {
            knownUsers: [],
            selectionDetails: this.GetSelectionDetails(),
            isEditPanelOpen: false,
            editPanelUserName: undefined,
            isNewPanelOpen: false,
            isChangePasswordPanelOpen: false,
            changePasswordUserKey: undefined,
            userDto: undefined,
        }

        this.ToggleNewPanel = this.ToggleNewPanel.bind(this);
    }

    classNames = mergeStyleSets({
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
    });
    controlStyles = {
        root: {
            margin: '0 30px 20px 0',
            maxWidth: '300px',
        },
    };

    private GetSelectionDetails(): string {
        const selectionCount = this.listSelection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'Kein Eintrag ausgewählt';
            case 1:
                return (
                    `Ein Eintrag gewählt: ${(this.listSelection.getSelection()[0] as IUserInfo).displayName}`
                );
            default:
                return `${selectionCount} Einträge ausgewählt`;
        }
    }

    render() {
        return (<div style={{ marginTop: "10px", marginLeft: "30px" }} data-is-scrollable="true">
            {this.RenderHeader()}
            {this.RenderToolbar()}
            {this.RenderTable()}
            {this.RenderEditPanel()}
            {this.RenderPasswordChangePanel()}
            {this.RenderNewPanel()}
        </div>);
    }

    private RenderEditPanel(): ReactNode {
        if (this.state.isEditPanelOpen && this.state.editPanelUserName !== undefined) {
            return (
                <Panel
                    type={PanelType.custom}
                    customWidth="40vw"
                    isBlocking={true}
                    isOpen={this.state.isEditPanelOpen}
                    hasCloseButton={false}
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel="Close">
                    <EditUser showChangePasswordLink={false} onUpdated={this.UsersChanged} onCancel={this.UserChangeCanceled} userName={this.state.editPanelUserName} />
                </Panel>)
        }
        else {
            <div />
        }
    }

    private RenderPasswordChangePanel(): ReactNode {
        if (this.state.isChangePasswordPanelOpen && this.state.changePasswordUserKey !== undefined) {
            return (<Panel
                type={PanelType.custom}
                customWidth="40vw"
                isBlocking={true}
                isOpen={this.state.isChangePasswordPanelOpen}
                hasCloseButton={false}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close">
                <ChangePassword onCancel={this.UserChangeCanceled} onUpdated={this.UsersChanged} accountId={this.state.changePasswordUserKey} />
            </Panel>)
        }
    }

    private RenderNewPanel(): ReactNode {
        if (this.state.isNewPanelOpen) {
            return (<Panel
                type={PanelType.custom}
                customWidth="40vw"
                isBlocking={true}
                isOpen={this.state.isNewPanelOpen}
                hasCloseButton={false}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close">
                <CreateUser onCreated={this.UsersChanged} onCancel={this.UserChangeCanceled} />
            </Panel>)
        }
        else {
            <div />
        }
    }

    private UsersChanged(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: false,
            isEditPanelOpen: false,
            isChangePasswordPanelOpen: false,
        });
        this.UpdateCurrentUsersAsync();
    }

    private UserChangeCanceled(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: false,
            isEditPanelOpen: false,
            isChangePasswordPanelOpen: false,
        })
    }

    private RenderTable(): ReactNode {
        return (
            <div className={this.classNames.selectionDetails}>
                <Label>{this.state.selectionDetails}</Label>
                <MarqueeSelection selection={this.listSelection}>
                    <ShimmeredDetailsList
                        checkboxVisibility={CheckboxVisibility.always}
                        setKey="knownUsers"
                        items={this.listSelection.getItems()}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        columns={[
                            {
                                key: "keyId",
                                name: "Id",
                                ariaLabel: "Id",
                                fieldName: "key",
                                minWidth: 250,                                
                                isResizable: false,
                                columnActionsMode: ColumnActionsMode.clickable,
                                onRender: (item) => {
                                    { var userItem = item as IUserInfo; }
                                    return (<Link data-selection-invoke={true} >{userItem.key}</Link>)
                                }
                            }, {
                                key: "keyLogin",
                                name: "Login",
                                ariaLabel: "Login",
                                fieldName: "account",
                                minWidth: 100,
                                maxWidth: 500,                                
                                isResizable: true
                            }, {
                                key: "keyDisplayName",
                                name: "Anzeigename",
                                ariaLabel: "Anzeigename",
                                fieldName: "displayName",
                                minWidth: 100,
                                isResizable: true
                            }, {
                                key: "keyGivenName",
                                name: "Vorname",
                                ariaLabel: "Vorname",
                                fieldName: "firstName",
                                minWidth: 100,
                                isResizable: true
                            }, {
                                key: "keySurName",
                                name: "Nachname",
                                ariaLabel: "Nachname",
                                fieldName: "lastName",
                                minWidth: 100,
                                isResizable: true
                            }, {
                                key: "keyEMail",
                                name: "E-Mail",
                                ariaLabel: "E-Mail",
                                fieldName: "eMail",
                                minWidth: 100,
                                isResizable: true
                            }, {
                                key: "keyChangePass",
                                name: "Passwort ändern",
                                ariaLabel: "Passwort ändern",
                                minWidth: 150,
                                isResizable: false,
                                columnActionsMode: ColumnActionsMode.clickable,
                                onRender: (item) => {
                                    return (<Link data-selection-invoke={false} onClick={() => this.ChangePassword((item as IUserInfo).accountId)} ><PasswordIcon /></Link>)
                                }
                            }
                        ]}
                        selection={this.listSelection}
                        selectionMode={SelectionMode.multiple}
                        enterModalSelectionOnTouch={true}
                        enableShimmer={!this.state.knownUsers}
                        ariaLabelForShimmer="Content is being fetched"
                        ariaLabelForGrid="Item details"
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={this.ListItemInvoked}
                        listProps={{
                            renderedWindowsAhead: 0,
                            renderedWindowsBehind: 0,
                        }}
                    />
                </MarqueeSelection>
            </div>
        );
    }

    private ChangePassword(userId: string | number): void {
        this.setState({
            ... this.state,
            isChangePasswordPanelOpen: true,
            changePasswordUserKey: userId,
        })
    }

    private RenderToolbar(): ReactNode {
        return (
            // <FocusTrapZone>
            <CommandBar
                items={[
                    {
                        key: 'newItem',
                        text: 'Neu',
                        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                        iconProps: { iconName: 'Add' },
                        onClick: () => this.ToggleNewPanel(),
                    },
                    {
                        key: 'download',
                        text: 'Liste herunterladen',
                        iconProps: { iconName: 'Download' },
                        onClick: () => this.DownloadUsers(),
                    },
                    {
                        key: 'delete',
                        text: 'Löschen',
                        iconProps: { iconName: 'Delete' },
                        onClick: () => { this.DeleteUsersAsync() },
                    },
                    {
                        key: 'refresh',
                        ariaLabel: "Refresh",
                        iconProps: { iconName: 'Refresh' },
                        onClick: () => { this.UpdateCurrentUsersAsync() },
                    }
                ]}
                farItems={[
                    // {
                    //     key: 'info',
                    //     text: 'Info',
                    //     // This needs an ariaLabel since it's icon-only
                    //     ariaLabel: 'Info',
                    //     iconOnly: true,
                    //     iconProps: { iconName: 'Info' },
                    //     onClick: () => console.log('Info'),
                    // },
                ]}
                ariaLabel="Aktionen"
            />

            // </FocusTrapZone>
        );
    }

    private DownloadUsers(): void {
        var lines = [];
        lines.push("ID;Login;Anzeigename;Vorname;Nachname;E-Mail;Ist Admin\r\n");

        if (this.state.userDto !== undefined) {
            this.state.userDto!.forEach(element => {
                lines.push(`${element.id};${element.account?.login};${element.displayName};${element.givenName};${element.surName};${element.email};${element.account?.isSuperUser}\r\n`);
            });
        }

        var blob = new Blob(lines, { type: 'text/csv' })

        const a = document.createElement('a')
        a.download = "Users.csv"
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    private async DeleteUsersAsync(): Promise<void> {
        try {
            var selectedItems = this.listSelection.getSelection();
            for (var i = 0; i < selectedItems.length; i++) {
                await this.props.webApiClient.DeleteUserAsync(this.props.userStore.accessToken, selectedItems[i].key as string)
            }
            // this.listSelection.getSelection().forEach(async element => await this.props.webApiClient.DeleteUserAsync(this.props.userStore.accessToken, element.key as string));
        }
        catch (reason) {
            var error = "Ein unbekannter Fehler ist aufgetreten.";

            if (reason.body as ErrorInfo !== null) {
                var errorInfo = reason.body as ErrorInfo;
                error = errorInfo.errors !== null && errorInfo.errors !== undefined
                    ? Object.entries(errorInfo.errors).map(([k, v]) => (v)).join(", ") : "Keine Errorinfo erhalten";
            }

            toast.error(`Konnte die ausgewählten Daten nicht löschen: ${error}`);
        }

        await this.UpdateCurrentUsersAsync();
    }

    private RenderHeader(): ReactNode {
        return (
            <div>
                <h2>Benutzer</h2>
                <p>Diese Seite ist zur Bearbeitung von Benutzern gedacht. Dabei handelt es sich um nat&uuml;rliche Personen.</p>
                <p>Sie k&ouml;nnen hier Benutzer neu hinzuf&uuml;gen, bearbeiten und auch l&ouml;schen.<br />
                    Außerdem k&ouml;nnen Sie Benutzern auch Administrationsrechte geben und die Passw&ouml;rter neu setzen.</p>
            </div>);
    }

    componentDidMount(): void {
        this.UpdateCurrentUsersAsync();

        this.props.signalRClient.onUserModified.subscribe(this.UserModified);
        window.addEventListener('beforeunload', this.InterceptRefresh);
    }

    private InterceptRefresh(event: BeforeUnloadEvent) {
        event.returnValue = true;
    }
    componentWillUnmount(): void {
        this.props.signalRClient.onUserModified.unsubscribe(this.UserModified);
    }

    private UserModified(userModifiedArgs: EntityChangedEventArgs): void {
        if (userModifiedArgs.source !== this.props.userStore.userName) {
            toast.info("Die Benutzer wurden serverseitig geändert. Hole Daten neu...",
                {
                    autoClose: 5000,
                    closeButton: true,
                    pauseOnFocusLoss: false,
                    position: "top-center"
                });
            this.UpdateCurrentUsersAsync();
        }
    }

    private ToggleNewPanel(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: true,
        });
    }

    private ListItemInvoked(item: IUserInfo): void {
        console.log(`Item invoked: ${item.displayName}`);
        this.setState({
            ... this.state,
            isEditPanelOpen: true,
            editPanelUserName: String(item.account)
        })
    };

    private async UpdateCurrentUsersAsync(): Promise<void> {
        var users = await this.props.webApiClient.GetAllUsersAsync(this.props.userStore.accessToken);

        var userInfo: IUserInfo[] = [];
        users.forEach(element => {
            if (element.account!.login !== this.props.userStore.userName) {
                userInfo.push({
                    key: element.id!,
                    displayName: element.displayName!,
                    eMail: element.email ?? undefined,
                    firstName: element.givenName!,
                    lastName: element.surName!,
                    account: element.account!.login!,
                    accountId: element.account!.id!,                    
                });
            }
        });
        this.setState({
            ...this.state,
            knownUsers: userInfo,
            userDto: users
        })

        this.listSelection.setItems(userInfo);
    }
}