/**
 * Defines the modification type of an entity.
 */
export enum ModificationType {
    /** No type has been specified */
    None = 0,

    /** The entity was created */
    Created = 1,

    /** The entity was updated */
    Updated = 2,

    /** The entity was deleted */
    Deleted = 3
}