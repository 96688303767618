import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, TextField, Toggle } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent } from "react";
import { ErrorInfo } from "../../generated";
import { InjectedProps } from "../InjectedProps";


interface CreateReadPointState {
    displayName: string | undefined;
    login: string | undefined;
    password: string | undefined;
    distanceToStart: number | undefined;
    unparsedDistanceToStart: string | undefined;
    valueChanged: boolean;
    isIntermediate: boolean;
    mustChangePassword: boolean;
    saveError: string | undefined;

    displayNameErrorMessage: string | undefined;
    loginErrorMessage: string | undefined;
    passwordErrorMessage: string | undefined;
    distanceErrorMessage: string | undefined;
}

interface CreateReadPointProps extends InjectedProps {
    onCreated: () => void | undefined;
    onCancel: () => void | undefined;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class CreateReadPoint extends Component<CreateReadPointProps, CreateReadPointState>{
    static defaultProps = {} as CreateReadPointProps;

    readonly displayNameEmptyMessage: string = "Der Anzeigename muss gefüllt sein.";
    readonly loginEmptyMessage: string = "Das Login muss gefüllt sein";
    readonly passwordEmptyMessage: string = "Das Password muss gefüllt sein";

    /**
     * Initializes a new instance of CreateUser.
     */
    constructor(props: CreateReadPointProps) {
        super(props);

        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);
        this.ChangeDisplayName = this.ChangeDisplayName.bind(this);
        this.ChangeLogin = this.ChangeLogin.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);
        this.HasErrors = this.HasErrors.bind(this);
        this.ChangeIsIntermediate = this.ChangeIsIntermediate.bind(this);
        this.ChangeMustChangePassword = this.ChangeMustChangePassword.bind(this);
        this.ChangeDistanceToStart = this.ChangeDistanceToStart.bind(this);

        this.state = {
            displayName: undefined,
            displayNameErrorMessage: this.displayNameEmptyMessage,
            isIntermediate: false,
            login: undefined,
            loginErrorMessage: this.loginEmptyMessage,
            distanceToStart: undefined,
            unparsedDistanceToStart: undefined,
            password: undefined,
            passwordErrorMessage: this.passwordEmptyMessage,
            valueChanged: false,
            saveError: undefined,
            distanceErrorMessage: undefined,
            mustChangePassword: false
        }
    }
    render() {
        return (
            <div>
                <h2>Lesepunkt erstellen</h2>
                <p>
                    <span>Hier k&ouml;nnen Sie einen neuen Lesepunkt anlegen. Bitte beachten Sie, dass Sie hier auch ein Passwort vergeben m&uuml;ssen!</span>
                </p>
                {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
                <div className="divTable">
                    <div className="divTableBody">
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Name" onChange={this.ChangeDisplayName} errorMessage={this.state.displayNameErrorMessage} value={this.state.displayName} /></div>
                            <div></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><Toggle label="Ist Zwischenmessung?" onChange={this.ChangeIsIntermediate} checked={this.state.isIntermediate} /></div>
                            <div>&nbsp;</div>
                        </div>
                        <div className="divTableRow" style={{ visibility: this.state.isIntermediate ? "visible" : "collapse" }}>
                            <div className="divTableCell"><TextField label="Abstand zum Startpunkt in m" onChange={this.ChangeDistanceToStart} errorMessage={this.state.distanceErrorMessage} value={this.state.unparsedDistanceToStart} /></div>
                            <div></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Login" onChange={this.ChangeLogin} errorMessage={this.state.loginErrorMessage} value={this.state.login} /></div>
                            <div className="divTableCell"><TextField required label="Passwort (min. 6 Zeichen)" type="password" canRevealPassword revealPasswordAriaLabel="Show password" onChange={this.ChangePassword} errorMessage={this.state.passwordErrorMessage} value={this.state.password} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                            <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.CreateReadPoint(this.props.userStore.accessToken,
            this.state.displayName!,
            this.state.login!,
            this.state.password!,
            this.state.isIntermediate,
            this.state.distanceToStart)
            .then(result => {
                // The read point was successfully created, close the create popup and refresh. This works only as a callback.
                if (this.props.onCreated !== undefined) {
                    this.props.onCreated();
                }
            })
            .catch(apiError => {
                if (apiError.body as ErrorInfo !== null) {
                    var errorInfo = apiError.body as ErrorInfo;
                    var error = errorInfo.errors !== null && errorInfo.errors !== undefined
                        ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    this.setState({
                        ...this.state,
                        saveError: error
                    });
                }
            })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich verwerfen?")) {
            this.setState({
                // ...this.state,
                displayName: undefined,
                valueChanged: false,
                login: undefined,
                password: undefined,
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private HasErrors(): boolean {
        return this.state.displayNameErrorMessage !== undefined
            || this.state.loginErrorMessage !== undefined
            || this.state.passwordErrorMessage !== undefined
            || (this.state.isIntermediate && this.state.distanceErrorMessage !== undefined)
    }

    private ChangeLogin(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.loginEmptyMessage : undefined;
        if (this.state.login !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                login: newValue,
                loginErrorMessage: errorMessage,
            })
        };
    }

    private ChangePassword(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.passwordEmptyMessage : undefined;

        if (errorMessage === undefined) {
            if (newValue !== null && newValue!.length < 6) {
                errorMessage = "Die Länge des Passwortes muss mindestens 6 Zeichen sein.";
            }
        }
        if (this.state.password !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                password: newValue,
                passwordErrorMessage: errorMessage,
            })
        };
    }

    private ChangeDisplayName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Anzeigename muss gefüllt sein" : undefined;
        if (this.state.displayName !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                displayName: newValue,
                displayNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeDistanceToStart(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = undefined;
        var numericalValue: number | undefined = 0;

        // Define a regular expression to match a valid number
        const numberPattern = /^[-+]?\d*\.?\d*$/;

        // Check if the entire string matches the number pattern
        if (!numberPattern.test(newValue!)) {
            errorMessage = "Die eingegebene Zahl ist ungültig";
            numericalValue = undefined;
        } else {
            numericalValue = parseFloat(newValue!);

            if (numericalValue < 0) {
                errorMessage = "Die eingegebene Zahl liegt außerhalb des erlaubten Bereichs (größer oder gleich 0)";
                numericalValue = undefined;
            }
        }

        this.setState({
            ...this.state,
            distanceErrorMessage: errorMessage,
            distanceToStart: numericalValue,
            unparsedDistanceToStart: newValue,
        });
    }

    private ChangeIsIntermediate(event: any, checked?: boolean | undefined): void {
        // TODO: Change the parameter of event from any to the correct type.
        console.log("Check changed?");
        if (checked !== undefined) {
            this.setState({
                ...this.state,
                isIntermediate: checked,
                distanceToStart: 0,
                unparsedDistanceToStart: "",
                distanceErrorMessage: undefined,
            })
        }
    }

    private ChangeMustChangePassword(event: any, checked?: boolean | undefined): void {
        console.log("check changes.");
        if (checked !== undefined) {
            this.setState({
                ...this.state,
                mustChangePassword: checked
            })
        }
    }
}
