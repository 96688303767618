import { DefaultButton, IStackProps, IStackStyles, Link, MessageBar, MessageBarButton, MessageBarType, Panel, PanelType, PrimaryButton, TextField, Toggle } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent, ReactNode } from "react";
import { toast } from "react-toastify";
import { ModificationType } from "../../Enumerations/ModificationType";
import { ApiError, ErrorInfo, UserDto } from "../../generated";
import { EntityChangedEventArgs } from "../../Services/ISignalRClient";
import { InjectedProps } from "../InjectedProps";
import ChangePassword from "./ChangePassword";

interface EditUserProps extends InjectedProps {
    userName: string | undefined,
    showChangePasswordLink: boolean,
    onUpdated: () => void | undefined;
    onCancel: () => void | undefined;
}

interface EditUserState {
    activeUser: UserDto | undefined;
    displayName: string | undefined;
    givenName: string | undefined;
    surName: string | undefined;
    eMail: string | undefined;
    valueChanged: boolean;
    editsSelf: boolean;
    hasAdminRights: boolean;
    saveError: string | undefined;
    externalChange: boolean;
    modificationType: ModificationType;

    displayNameErrorMessage: string | undefined;
    givenNameErrorMessage: string | undefined,
    surNameErrorMessage: string | undefined,
    eMailErrorMessage: string | undefined,

    isChangePasswordPanelOpen: boolean,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class EditUser extends Component<EditUserProps, EditUserState>{
    static defaultProps = {} as EditUserProps;

    constructor(props: EditUserProps, state: EditUserState) {
        super(props);

        this.state = {
            activeUser: undefined,
            eMail: undefined,
            givenName: undefined,
            surName: undefined,
            valueChanged: false,
            editsSelf: true,
            displayName: undefined,
            hasAdminRights: false,
            saveError: undefined,
            displayNameErrorMessage: undefined,
            givenNameErrorMessage: undefined,
            surNameErrorMessage: undefined,
            eMailErrorMessage: undefined,
            externalChange: false,
            modificationType: ModificationType.None,
            isChangePasswordPanelOpen: false
        }

        this.ChangeGivenName = this.ChangeGivenName.bind(this);
        this.ChangeSurName = this.ChangeSurName.bind(this);
        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.ChangeEMail = this.ChangeEMail.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);
        this.ChangeDisplayName = this.ChangeDisplayName.bind(this);
        this.HasErrors = this.HasErrors.bind(this);
        this.UserModified = this.UserModified.bind(this);
        this.RenderDeletionError = this.RenderDeletionError.bind(this);
        this.ShowChangePassword = this.ShowChangePassword.bind(this);
        this.UsersChanged = this.UsersChanged.bind(this);
        this.UserChangeCanceled = this.UserChangeCanceled.bind(this);
    }

    componentDidMount(): void {
        if (this.props.userName === undefined) {
            this.GetUserInfoAsync(this.props.userStore.userName);
        }
        else {
            this.setState({
                ...this.state,
                editsSelf: this.props.userName === this.props.userStore.userName,
            });
            this.GetUserInfoAsync(this.props.userName);
        }

        this.props.signalRClient.onUserModified.subscribe(this.UserModified);
    }

    componentWillUnmount(): void {
        this.props.signalRClient.onUserModified.unsubscribe(this.UserModified);
    }

    stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
    stackTokens = { childrenGap: 50 };
    columnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 300 } },
    };

    render(): ReactNode {

        return (<div>
            <h2>Profil bearbeiten</h2>
            <p>
                <span>Hier können Sie das Profil des Benutzers &quot;{this.state.givenName} {this.state.surName}&quot; bearbeiten.</span>
            </p>
            <span>{this.state.externalChange ? this.state.modificationType === ModificationType.Updated ? this.RenderModificationWarning() : this.RenderDeletionError() : <div />}</span>
            {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
            <div className="divTable">
                <div className="divTableBody">
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="Anzeigename" onChange={this.ChangeDisplayName} errorMessage={this.state.displayNameErrorMessage} value={this.state.displayName} /></div>
                        <div></div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="Vorname" onChange={this.ChangeGivenName} errorMessage={this.state.givenNameErrorMessage} value={this.state.givenName} /></div>
                        <div className="divTableCell"><TextField required label="Nachname" onChange={this.ChangeSurName} errorMessage={this.state.surNameErrorMessage} value={this.state.surName} /></div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="E-Mail" onChange={this.ChangeEMail} errorMessage={this.state.eMailErrorMessage} value={this.state.eMail} /></div>
                        <div className="divTableCell">{this.state.editsSelf ? <div /> : <Toggle label="Hat Administratorrechte?" onChange={this.ChangeHasAdminRights.bind(this)} checked={this.state.hasAdminRights} />}</div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                        <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                    </div>
                </div>
            </div>
            {this.RenderPasswordChangePanel()}
            {this.props.showChangePasswordLink !== undefined && this.props.showChangePasswordLink && (
                <div style={{marginLeft: "10px"}}>
                <Link onClick={this.ShowChangePassword}>Passwort ändern</Link>
                </div>
            )}
        </div >)
    }

    private RenderModificationWarning(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.severeWarning}
        >
            Dieser Benutzer wurde von jemand anderem bearbeitet. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen und die geänderten Daten zu benutzen.
        </MessageBar>;
    }

    private ShowChangePassword(): void {
        this.setState({
            ...this.state,
            isChangePasswordPanelOpen: true,
        })
    }

    private RenderPasswordChangePanel(): ReactNode {
        if (this.state.isChangePasswordPanelOpen && this.state.activeUser?.account?.id !== undefined) {
            return (<Panel
                type={PanelType.custom}
                customWidth="40vw"
                isBlocking={true}
                isOpen={this.state.isChangePasswordPanelOpen}
                hasCloseButton={false}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close">
                <ChangePassword onCancel={this.UserChangeCanceled} onUpdated={this.UsersChanged} accountId={this.state.activeUser?.account?.id} />
            </Panel>)
        }
    }

    private UsersChanged(): void {
        this.setState({
            ...this.state,
            isChangePasswordPanelOpen: false,
        });
    }

    private UserChangeCanceled(): void {
        this.setState({
            ...this.state,
            isChangePasswordPanelOpen: false,
        })
    }

    private RenderDeletionError(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.error}
            actions={<div>
                <MessageBarButton onClick={this.DiscardChanges}>Bearbeitung abbrechen</MessageBarButton>
            </div>}
        >
            <p>Dieser Benutzer wurde serverseitig von jemand anderem gelöscht. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen!</p>
        </MessageBar>;
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.UpdateUserAsync(this.props.userStore.accessToken, this.state.activeUser!.id!, this.state.eMail!, this.state.givenName!, this.state.surName!, this.state.displayName!, this.state.hasAdminRights)
            .then(() => {
                toast.success('Der Benutzer wurde erfolgreich aktualisiert.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                this.setState({
                    ...this.state,
                    saveError: undefined
                });
                if (this.props.onUpdated !== undefined) {
                    this.props.onUpdated();
                }
            })
            .catch((reason: ApiError) => {
                console.error(reason);
                var error = "Ein unbekannter Fehler ist aufgetreten.";

                if (reason.body as ErrorInfo !== null) {
                    var errorInfo = reason.body as ErrorInfo;
                    error = errorInfo.errors !== null && errorInfo.errors !== undefined
                        ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                }
                this.setState({
                    ...this.state,
                    saveError: error
                })
            })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich rückgängig machen?")) {
            this.setState({
                ...this.state,
                eMail: this.state.activeUser!.email ?? undefined,
                givenName: this.state.activeUser!.givenName ?? undefined,
                surName: this.state.activeUser!.surName ?? undefined,
                displayName: this.state.activeUser!.displayName ?? undefined,
                hasAdminRights: this.state.activeUser!.account?.isSuperUser ?? false,
                valueChanged: false
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private HasErrors(): boolean {
        return this.state.displayNameErrorMessage !== undefined
            || this.state.givenNameErrorMessage !== undefined
            || this.state.surNameErrorMessage !== undefined
            || this.state.eMailErrorMessage !== undefined
    }

    private ChangeGivenName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Vorname muss gefüllt sein" : undefined;
        if (this.state.givenName !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                givenName: newValue,
                givenNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeDisplayName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Anzeigename muss gefüllt sein" : undefined;
        if (this.state.displayName !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                displayName: newValue,
                displayNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeSurName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Nachname muss gefüllt sein" : undefined;
        if (this.state.surName !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                surName: newValue,
                surNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeEMail(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Die E-Mail-Adresse muss gefüllt sein" : undefined;
        if (errorMessage === undefined && newValue !== undefined) {
            var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            var serchfind = regexp.test(newValue);

            console.log(serchfind)
            if (!serchfind) {
                errorMessage = "Keine gültige Mailadresse";
            }
        }

        if (!this.state.eMail || this.state.eMail !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                eMail: newValue,
                eMailErrorMessage: errorMessage,
            })
        };
    }

    private ChangeHasAdminRights(event: any, checked?: boolean | undefined): void {
        // TODO: Change the parameter of event from any to the correct type.
        this.setState({
            ...this.state,
            hasAdminRights: checked ?? false,
        })
    }

    private async GetUserInfoAsync(userName: string): Promise<void> {
        var user = await this.props.webApiClient.GetUserByLoginAsync(this.props.userStore.accessToken, userName);
        this.setState({
            ...this.state,
            activeUser: user,
            hasAdminRights: user.account!.isSuperUser ?? false,
            givenName: user.givenName ?? undefined,
            displayName: user.displayName ?? undefined,
            surName: user.surName ?? undefined,
            eMail: user.email ?? undefined,
            valueChanged: false,
            displayNameErrorMessage: user.displayName === null ? "Der Anzeigename muss gefüllt sein" : undefined,
            eMailErrorMessage: user.email === null ? "Die E-Mail-Adresse muss gefüllt sein" : undefined,
            givenNameErrorMessage: user.givenName === null ? "Der Vorname muss gefüllt sein" : undefined,
            surNameErrorMessage: user.surName === null ? "Der Nachname muss gefüllt sein" : undefined,
        })
    }

    private UserModified(userModifiedArgs: EntityChangedEventArgs): void {
        if (userModifiedArgs.source !== this.props.userStore.userName && userModifiedArgs.id === this.state.activeUser?.id) {
            this.setState({
                ... this.state,
                externalChange: true,
                modificationType: userModifiedArgs.modificationType,
            })
        }

        console.log("UserModified handled." + this.state);
    }
}