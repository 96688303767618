
/** This enumeration defines the main role of the currently logged in user and therefore determines the layout of the page. */
export enum AccountRole {
    /** The role is undefined. */
    Undefined,

    /** The role is a normal (interactive) user */
    User,

    /** The role describes a public display (kiosk) */
    PublicDisplay
}
