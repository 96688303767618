import { CSSProperties } from 'react';
import './App.css';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import { Provider } from "mobx-react";
import { UserStore } from './Stores/UserStore';
import Layout from './Pages/Layout';
import "bootstrap/dist/css/bootstrap.min.css";
import SignalRClient from './Services/SignalRClient';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { DefaultPalette, getTheme } from '@fluentui/react';
import { ThemeProvider, PartialTheme } from '@fluentui/react';
import { GerbilWebApiClient } from './Services/GerbilWebApiClient';
import TagItronLogo from './images/logo';
import Clock from './Components/Clock';

const theme = getTheme();
const appTheme: PartialTheme = {
    palette: {
        // themePrimary: '#56cde8',
        themeLighterAlt: '#030809',
        themeLighter: '#0e2125',
        themeLight: '#1a3e46',
        themeTertiary: '#347b8b',
        themeSecondary: '#4cb5cc',
        themeDarkAlt: '#65d2ea',
        themeDark: '#7cd9ee',
        themeDarker: '#9de2f2',
        neutralLighterAlt: '#3c3c3c',
        neutralLighter: '#444444',
        neutralLight: '#515151',
        neutralQuaternaryAlt: '#595959',
        neutralQuaternary: '#5f5f5f',
        neutralTertiaryAlt: '#7a7a7a',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#333333',                        
    },
    semanticColors: {
        errorText: "#FF5555",
        warningText: "#FFCC00",
        bodyBackground: '#333333'
    }
};

initializeIcons();
function App() {

    const mainLayoutStyle: CSSProperties = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        minWidth: "100vw",
        marginLeft: "5px",
        padding: "5px",
        boxSizing: "border-box"
    }

    // Add CSS to reset margin and padding of body element
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.backgroundColor = appTheme.semanticColors!.bodyBackground!;

    return (
        <ThemeProvider theme={appTheme} >
            <Provider userStore={UserStore.Instance} signalRClient={SignalRClient.Instance} webApiClient={GerbilWebApiClient.Instance}>
                {/* <div style={mainLayoutStyle}> */}
                <Clock />
                <ToastContainer theme="colored" />
                <Layout />
                <TagItronLogo style={{
                    position: "fixed",
                    right: "2%",
                    width: "20%",
                    bottom: "2%"                    
                }} />
                {/* </div> */}
            </Provider>
        </ThemeProvider>
    );
}

export default App;