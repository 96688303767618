import { DefaultButton, IStackProps, IStackStyles, MessageBar, MessageBarButton, MessageBarType, PrimaryButton, TextField, Toggle, TooltipHostBase } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent, ReactNode } from "react";
import { toast } from "react-toastify";
import { ModificationType } from "../../Enumerations/ModificationType";
import { ApiError, ErrorInfo, RaceTrackDto, UserDto } from "../../generated";
import { EntityChangedEventArgs } from "../../Services/ISignalRClient";
import { InjectedProps } from "../InjectedProps";

interface EditPublicDisplayProps extends InjectedProps {
    publicDisplayId: string,
    onUpdated: () => void | undefined;
    onCancel: () => void | undefined;
}

interface EditPublicDisplayState {
    activePublicDisplay: RaceTrackDto | undefined;
    name: string | undefined;
    horizontalResolution: number | undefined;
    verticalResolution: number | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    idleTime: number | undefined;
    idleTarget: string | undefined;
    valueChanged: boolean;
    saveError: string | undefined;
    externalChange: boolean;
    modificationType: ModificationType;

    readPointNameErrorMessage: string | undefined;
    horizontalResolutionErrorMessage: string | undefined;
    verticalResolutionErrorMessage: string | undefined;
    idleTargetErrorMessage: string | undefined;
    idleTimeErrorMessage: string | undefined;
    latitudeErrorMessage: string | undefined;
    longitudeErrorMessage: string | undefined;
    unparsedLatitude: string | undefined;
    unparsedLongitude: string | undefined;
    unparsedIdleTime: string | undefined;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class EditPublicDisplay extends Component<EditPublicDisplayProps, EditPublicDisplayState>{
    static defaultProps = {} as EditPublicDisplayProps;
    readonly horizontalResolutionEmptyMessage: string = "Die horizontale Auflösung muss gefüllt sein";
    readonly verticalResolutionEmptyMessage: string = "Die vertikale Auflösung muss gefüllt sein";
    readonly idleTimeEmptyMessage: string = "Die Inaktivitätszeit muss gefüllt sein";
    readonly idleTargetEmptyMessage: string = "Die Zieladresse muss gefüllt sein";

    /**
     * Initializes a new instance of EditRaceTrack
     */
    constructor(props: EditPublicDisplayProps) {
        super(props);

        this.ChangeReadPointName = this.ChangeReadPointName.bind(this);
        this.ChangeHorizontalResolution = this.ChangeHorizontalResolution.bind(this);
        this.ChangeVerticalResolution = this.ChangeVerticalResolution.bind(this);
        this.ChangeLatitude = this.ChangeLatitude.bind(this);
        this.ChangeLongitude = this.ChangeLongitude.bind(this);
        this.ChangeIdleTime = this.ChangeIdleTime.bind(this);
        this.IdleTargetChanged = this.IdleTargetChanged.bind(this);
        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);

        this.HasErrors = this.HasErrors.bind(this);

        this.state = {
            saveError: undefined,
            activePublicDisplay: undefined,
            horizontalResolution: undefined,
            horizontalResolutionErrorMessage: this.horizontalResolutionEmptyMessage,
            verticalResolution: undefined,
            idleTarget: undefined,
            idleTargetErrorMessage: undefined,
            idleTime: undefined,
            idleTimeErrorMessage: this.idleTimeEmptyMessage,
            verticalResolutionErrorMessage: this.verticalResolutionEmptyMessage,
            latitude: undefined,
            latitudeErrorMessage: undefined,
            longitude: undefined,
            unparsedLatitude: undefined,
            unparsedLongitude: undefined,
            unparsedIdleTime: undefined,
            name: undefined,
            valueChanged: false,
            modificationType: ModificationType.None,
            externalChange: false,
            longitudeErrorMessage: undefined,
            readPointNameErrorMessage: undefined,

        }

        this.PublicDisplayModified = this.PublicDisplayModified.bind(this);
    }

    componentDidMount(): void {
        this.GetPublicDisplayInfoAsync(this.props.publicDisplayId);

        this.props.signalRClient.onPublicDisplayModified.subscribe(this.PublicDisplayModified);
    }

    componentWillUnmount(): void {
        this.props.signalRClient.onReadPointModified.subscribe(this.PublicDisplayModified);
    }

    render(): ReactNode {

        return (<div>
            <h2>&Ouml;ffentliche Anzeige bearbeiten</h2>
            <p>
                <span>Hier können Sie die &ouml;ffentliche Anzeige &quot;{this.state?.name}&quot; bearbeiten.</span>
            </p>
            <span>{this.state.externalChange ? this.state.modificationType === ModificationType.Updated ? this.RenderModificationWarning() : this.RenderDeletionError() : <div />}</span>
            {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
            <div className="divTable">
                <div className="divTableBody">
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="Name" onChange={this.ChangeReadPointName} errorMessage={this.state.readPointNameErrorMessage} value={this.state.name} /></div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="Inaktivität in Sekunden (0 zum Deaktivieren)" onChange={this.ChangeIdleTime} errorMessage={this.state.idleTimeErrorMessage} value={this.state.idleTime?.toString()} /></div>
                        <div className="divTableCell"><TextField required label="Zieladresse" onChange={this.IdleTargetChanged} errorMessage={this.state.idleTargetErrorMessage} value={this.state.idleTarget} /></div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="Horizontale Auflösung" onChange={this.ChangeHorizontalResolution} errorMessage={this.state.horizontalResolutionErrorMessage} value={this.state.horizontalResolution?.toString()} /></div>
                        <div className="divTableCell"><TextField required label="Vertikal Auflösung" onChange={this.ChangeVerticalResolution} errorMessage={this.state.verticalResolutionErrorMessage} value={this.state.verticalResolution?.toString()} /></div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField step={0.1} min={-180.0} max={180.0} label="Breitengrad" onChange={this.ChangeLatitude} errorMessage={this.state.latitudeErrorMessage} value={this.state.unparsedLatitude} /></div>
                        <div className="divTableCell"><TextField label="Längengrad" onChange={this.ChangeLongitude} errorMessage={this.state.longitudeErrorMessage} value={this.state.unparsedLongitude} /></div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                        <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                    </div>
                </div>
            </div>
        </div >)
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.UpdatePublicDisplayAsync(this.props.userStore.accessToken, this.state.activePublicDisplay?.id!, this.state.name!, this.state.idleTime!, this.state.idleTarget!, this.state.horizontalResolution!, this.state.verticalResolution!, this.state.latitude, this.state.longitude)
            .then(() => {
                toast.success('Die öffentliche Anzeige wurde erfolgreich aktualisiert.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                this.setState({
                    ...this.state,
                    saveError: undefined
                });
                if (this.props.onUpdated !== undefined) {
                    this.props.onUpdated();
                }
            })
            .catch((reason: ApiError) => {
                console.error(reason);
                var error = "Ein unbekannter Fehler ist aufgetreten.";

                if (reason.body as ErrorInfo !== null) {
                    var errorInfo = reason.body as ErrorInfo;
                    error = errorInfo.errors !== null && errorInfo.errors !== undefined
                        ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                }
                this.setState({
                    ...this.state,
                    saveError: error
                })
            })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich rückgängig machen?")) {
            this.setState({
                ...this.state,
                name: this.state.activePublicDisplay?.trackName ?? undefined,
                valueChanged: false
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }


    public static isValidURI(uri: string): boolean {
        try {
            new URL(uri);
            return true;
        } catch (error) {
            return false;
        }
    }

    private ChangeReadPointName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Name muss gefüllt sein" : undefined;
        if (this.state.name !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                name: newValue,
                readPointNameErrorMessage: errorMessage,
            })
        };
    }

    private IdleTargetChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Das Ziel muss gefüllt sein" : undefined;
        
        if (this.state.idleTarget !== newValue) {
            if (newValue !== undefined)
            if (!EditPublicDisplay.isValidURI(newValue)){
                errorMessage = "Die angegebene Adresse ist keine gültige URI";
            }

            this.setState({
                ...this.state,
                valueChanged: true,
                idleTarget: newValue,
                idleTargetErrorMessage: errorMessage,
            })
        };
    }

    private ChangeHorizontalResolution(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.horizontalResolutionEmptyMessage : undefined;

        var numericalValue = 0;
        if (errorMessage === undefined) {
            var numericalValue = parseInt(newValue!);
            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            horizontalResolution: numericalValue,
            horizontalResolutionErrorMessage: errorMessage,
        })
    }

    private ChangeVerticalResolution(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.verticalResolutionEmptyMessage : undefined;

        var numericalValue = 0;
        if (errorMessage === undefined) {
            var numericalValue = parseInt(newValue!);
            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            verticalResolution: numericalValue,
            verticalResolutionErrorMessage: errorMessage,
        })
    }

    private localeParseFloat(s: string, locale?: string | undefined): number {
        if (locale === null) {
            locale = undefined;
        }

        // Get the thousands and decimal separator characters used in the locale.
        let [, thousandsSeparator, , , , decimalSeparator] = 1111.1.toLocaleString(locale);
        // Remove thousand separators, and put a point where the decimal separator occurs
        s = Array.from(s, c => c === thousandsSeparator ? ""
            : c === decimalSeparator ? "." : c).join("");
        // Now it can be parsed
        return parseFloat(s);
    }

    private ChangeLatitude(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = undefined;

        var numericalValue: number | undefined = 0;
        if (newValue !== null && newValue !== undefined && newValue !== "") {
            numericalValue = this.localeParseFloat(newValue, "de");

            if (Number.isNaN(numericalValue)) {
                errorMessage = "Die eingegebene Zahl ist ungültig";
                numericalValue = undefined;
            }
            else if (numericalValue < -180.0 || numericalValue > 180) {
                errorMessage = "Die eingegebene Zahl liegt außerhalb des erlaubten Bereichs";
                numericalValue = undefined;
            }

            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            latitude: numericalValue,
            unparsedLatitude: newValue,
            latitudeErrorMessage: errorMessage,
        })
    }

    private ChangeLongitude(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = undefined;

        var numericalValue: number | undefined = 0;
        if (newValue !== null && newValue !== undefined && newValue !== "") {
            numericalValue = this.localeParseFloat(newValue, "de");

            if (Number.isNaN(numericalValue)) {
                errorMessage = "Die eingegebene Zahl ist ungültig";
                numericalValue = undefined;
            }
            else if (numericalValue < -180.0 || numericalValue > 180) {
                errorMessage = "Die eingegebene Zahl liegt außerhalb des erlaubten Bereichs";
                numericalValue = undefined;
            }

            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            longitude: numericalValue,
            unparsedLongitude: newValue,
            longitudeErrorMessage: errorMessage,
        })
    }

    private ChangeIdleTime(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = undefined;

        var numericalValue: number | undefined = 0;
        if (newValue !== null && newValue !== undefined && newValue !== "") {
            numericalValue = this.localeParseFloat(newValue, "de");

            if (Number.isNaN(numericalValue)) {
                errorMessage = "Die eingegebene Zahl ist ungültig";
                numericalValue = undefined;
            }
            else if (numericalValue < 0) {
                errorMessage = "Die eingegebene Zahl liegt außerhalb des erlaubten Bereichs (größer oder gleich 0)";
                numericalValue = undefined;
            }

            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            idleTime: numericalValue,
            unparsedIdleTime: newValue,
            idleTimeErrorMessage: errorMessage,
        })
    }

    private HasErrors(): boolean {
        return this.state.readPointNameErrorMessage !== undefined
        || this.state.horizontalResolutionErrorMessage !== undefined
        || this.state.verticalResolutionErrorMessage !== undefined
        || this.state.idleTargetErrorMessage !== undefined
        || this.state.idleTimeErrorMessage !== undefined;
    }

    private async GetPublicDisplayInfoAsync(displayId: string): Promise<void> {
        var publicDisplay = await this.props.webApiClient.GetPublicDisplayInfo(this.props.userStore.accessToken, displayId);

        this.setState({
            ...this.state,
            name: publicDisplay.displayName!,
            horizontalResolution: publicDisplay.horizontalResolution!,
            verticalResolution: publicDisplay.verticalResolution!,
            latitude: publicDisplay.latitude ?? undefined,
            longitude: publicDisplay.longitude ?? undefined,
            horizontalResolutionErrorMessage: undefined,
            verticalResolutionErrorMessage: undefined,
            unparsedLatitude: publicDisplay.latitude?.toString(),
            unparsedLongitude: publicDisplay.longitude?.toString(),
            activePublicDisplay: publicDisplay,
            idleTarget: publicDisplay.idleDisplayTarget ?? undefined,
            idleTargetErrorMessage: publicDisplay.idleDisplayTarget === null || publicDisplay.idleDisplayTarget === undefined ? this.idleTargetEmptyMessage : undefined,
            idleTime: publicDisplay.displayIdleTimeSeconds,
            idleTimeErrorMessage: publicDisplay.displayIdleTimeSeconds === null || publicDisplay.displayIdleTimeSeconds === undefined ? this.idleTimeEmptyMessage : undefined,
            valueChanged: false
        })
    }

    private RenderModificationWarning(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.severeWarning}
        >
            Diese Laufstrecke wurde von jemand anderem bearbeitet. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen und die geänderten Daten zu benutzen.
        </MessageBar>;
    }

    private RenderDeletionError(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.error}
            actions={<div>
                <MessageBarButton onClick={this.DiscardChanges}>Bearbeitung abbrechen</MessageBarButton>
            </div>}
        >
            <p>Diese Laufstrecke wurde serverseitig von jemand anderem gelöscht. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen!</p>
        </MessageBar>;
    }

    private PublicDisplayModified(raceTrackModifiedArgs: EntityChangedEventArgs): void {
        if (raceTrackModifiedArgs.source !== this.props.userStore.userName && raceTrackModifiedArgs.id === this.state.activePublicDisplay?.id) {
            this.setState({
                ... this.state,
                externalChange: true,
                modificationType: raceTrackModifiedArgs.modificationType,
            })
        }

        console.log("ReadPointModified handled." + this.state);
    }
}