import { inject, observer } from "mobx-react";
import { Component, FormEvent } from "react";
import { InjectedProps } from "../InjectedProps";
import { PrimaryButton, DefaultButton, TextField, MessageBar, MessageBarType } from "@fluentui/react";
import { ErrorInfo } from "../../generated/models/ErrorInfo";
import { ApiError } from "../../generated";

interface ChangePasswordProps extends InjectedProps {
    accountId: string | number | undefined,
    onUpdated: () => void | undefined;
    onCancel: () => void | undefined;
}

interface ChangePasswordState {
    valueChanged: boolean;
    password: string | undefined,
    passwordConfirm: string | undefined,
    passwordErrorMessage: string | undefined,
    passwordConfirmErrorMessage: string | undefined,
    changesSaved: boolean,
    saveError: string | undefined,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class ChangePassword extends Component<ChangePasswordProps, ChangePasswordState> {
    static defaultProps = {} as ChangePasswordProps;

    readonly passwordEmptyMessage: string = "Das Passwort darf nicht leer sein";
    readonly passwordsDoNotMatch: string = "Die Passwörter stimmen nicht überein";
    readonly passwordTooShort: string = "Die Länge des Passwortes muss mindestens 6 Zeichen sein.";

    /**
     *
     */
    constructor(props: ChangePasswordProps) {
        super(props);
        this.HasErrors = this.HasErrors.bind(this);
        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);
        this.ResetErrorMessage = this.ResetErrorMessage.bind(this);

        this.ChangePasswordConfirm = this.ChangePasswordConfirm.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);

        this.state = {
            valueChanged: false,
            password: undefined,
            passwordConfirm: undefined,
            passwordErrorMessage: this.passwordEmptyMessage,
            passwordConfirmErrorMessage: this.passwordEmptyMessage,
            changesSaved: false,
            saveError: undefined,
        }
    }

    private HasErrors(): boolean {
        return this.state.passwordErrorMessage !== undefined
            || this.state.passwordConfirmErrorMessage !== undefined;
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.ChangePassword(this.props.userStore.accessToken, this.props.accountId!.toString(), this.state.password!.toString())
            .then(result => {
                // The user was successfully created, close the create popup and refresh. This works only as a callback.
                this.setState({
                    ...this.state,
                    changesSaved: true
                })

                if (this.props.onUpdated !== undefined) {
                    this.props.onUpdated();
                }
            })
            .catch(apiError => {
                if (apiError.body as ErrorInfo !== null) {
                    var errorInfo = apiError.body as ErrorInfo;
                    if (errorInfo !== null && errorInfo !== undefined) {
                        var error = errorInfo.errors !== null && errorInfo.errors !== undefined
                            ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten"
                        this.setState({
                            ...this.state,
                            saveError: error
                        });
                    }
                }
            })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich rückgängig machen?")) {
            this.setState({
                ...this.state,
                valueChanged: false
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private ChangePassword(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.passwordEmptyMessage : undefined;

        if (errorMessage === undefined) {
            if (newValue !== null && newValue!.length < 6) {
                errorMessage = this.passwordTooShort;
            }
        }

        if (errorMessage === undefined && newValue !== this.state.passwordConfirm) {
            errorMessage = this.passwordsDoNotMatch;
        }

        this.setState({
            ...this.state,
            valueChanged: true,
            password: newValue,
            passwordErrorMessage: errorMessage,
        });
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })

    }

    private ChangePasswordConfirm(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.passwordEmptyMessage : undefined;

        if (errorMessage === undefined && newValue !== null && newValue!.length < 6) {
            errorMessage = this.passwordTooShort;
        }

        var otherError: string | undefined = this.state.passwordErrorMessage;

        if (otherError === undefined || otherError === this.passwordsDoNotMatch) {
            if (newValue !== this.state.password) {
                otherError = this.passwordsDoNotMatch;
            }
            else {
                otherError = undefined;
            }
        }

        this.setState({
            ...this.state,
            valueChanged: true,
            passwordConfirm: newValue,
            passwordErrorMessage: otherError,
            passwordConfirmErrorMessage: errorMessage,
        });
    }

    render() {
        return <div>
            <h2>Passwort ändern</h2>
            <p>
                <span>Bitte vergeben Sie hier ein neues Passwort für den Account.</span>
            </p>
            {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
            <div className="divTable">
                <div className="divTableRow">
                    <div className="divTableCell"><TextField required type="password" label="Passwort" onChange={this.ChangePassword} revealPasswordAriaLabel="Show password" canRevealPassword errorMessage={this.state.passwordErrorMessage} value={this.state.password} /></div>
                </div>
                <div className="divTableRow">
                    <div className="divTableCell"><TextField required type="password" label="Passwort wiederholen" revealPasswordAriaLabel="Show password" canRevealPassword onChange={this.ChangePasswordConfirm} errorMessage={this.state.passwordConfirmErrorMessage} value={this.state.passwordConfirm} /></div>
                </div>
                <div className="divTableRow">
                    <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                    <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                </div>
            </div>
        </div>        
    }
}