import { Component, CSSProperties, FormEvent, MouseEvent, MouseEventHandler, ReactNode } from "react";
import { inject, observer } from "mobx-react";
import { InjectedProps } from "../Pages/InjectedProps";
import { Link } from "react-router-dom";
import { FontSizes } from "@fluentui/react";

interface ClockState {
    currentTime: Date;
}

/**
 * This component represents a 
 */
@inject("signalRClient")
@observer
export default class Clock extends Component<InjectedProps, ClockState> {
    static defaultProps = {} as InjectedProps;
    
    /**
     *
     */
    constructor(props: InjectedProps, state: ClockState) {
        super(props);

        this.ServerTimeReceived = this.ServerTimeReceived.bind(this);
        this.state = {
            currentTime: new Date()
        }
    }
    override componentDidMount(): void {

        this.props.signalRClient.onServerTimeReceivedEvent.subscribe(this.ServerTimeReceived);
    }
    
    override componentWillUnmount(): void {
        this.props.signalRClient.onServerTimeReceivedEvent.unsubscribe(this.ServerTimeReceived);
    }
    
    private ServerTimeReceived(date: Date): void{
        this.setState({
            currentTime: date
        });
    }

    static DateToYYYYMMDD_HHMM(Date: Date): string {
        return `${('0' + Date.getDate()).slice(-2)}.${('0' + (Date.getMonth() + 1)).slice(-2)}.${Date.getFullYear()} ${('0' + Date.getHours()).slice(-2)}:${('0' + Date.getMinutes()).slice(-2)}:${('0' +Date.getSeconds()).slice(-2)}`;
    }

    render(): ReactNode{
        var timeString = Clock.DateToYYYYMMDD_HHMM(this.state.currentTime);
        const clockStyle : CSSProperties = {
            display: "flex",
            position: 'fixed',
            right: '0px',
            top: '0px',
            marginTop: '5px',
            marginRight: '5px',
            fontSize: FontSizes.medium
        }
    
        return (
            <div style={clockStyle}>{timeString}</div>
        );
    }   
}