import { Selection, CheckboxVisibility, CommandBar, Label, Link, MarqueeSelection, SelectionMode, ShimmeredDetailsList, mergeStyleSets, ColumnActionsMode, Panel, PanelType, Toggle, Checkbox, ConstrainMode, DetailsListLayoutMode } from "@fluentui/react";
import { read } from "fs";
import { inject, observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { toast } from "react-toastify";
import { isNoSubstitutionTemplateLiteral } from "typescript";
import { ErrorInfo } from "../../generated";
import { InjectedProps } from "../InjectedProps";
import ChangePublicDisplayTrackAssignment from "../PublicDisplays/ChangePublicDisplayTrackAssignment";
import CreatePublicDisplay from "../PublicDisplays/CreatePublicDisplay";
import EditPublicDisplay from "../PublicDisplays/EditPublicDisplay";
import EditRaceTrack from "../RaceTracks/EditRaceTrack";
import ChangeReadPointAssignment from "../ReadPoints/ChangeReadPointAssignment";
import CreateReadPoint from "../ReadPoints/CreateReadPoint";
import EditReadPoint from "../ReadPoints/EditReadPoint";
import { IRaceTrackInfo } from "./RaceTrackList";
import PasswordIcon from '@mui/icons-material/Password';
import ChangePassword from "../Users/ChangePassword";

interface IPublicDisplayInfo {
    key: string | number,
    name: string,
    horizontalResolution: number,
    verticalResolution: number,
    latitude: number|undefined,
    longitude:number|undefined,
    assignedRaceTracks: IRaceTrackInfo[] | undefined,
    account: string | undefined,
    accountId: string | number | undefined,
    idleTime: number | undefined,
    idleTarget: string | null | undefined,
}

interface PublicDisplaysListState {
    knownReadPoints: IPublicDisplayInfo[],
    selectionDetails: string,
    isEditPanelOpen: boolean,
    isNewPanelOpen: boolean,
    isEditAssignmentPanelOpen: boolean,
    publicDisplayId: string | undefined,
    isChangePasswordPanelOpen: boolean,
    changePasswordAccountKey: string | number|undefined,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class PublicDisplaysList extends Component<InjectedProps, PublicDisplaysListState> {
    static defaultProps = {} as InjectedProps;

    private listSelection: Selection;
    classNames = mergeStyleSets({
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
    });
    controlStyles = {
        root: {
            margin: '0 30px 20px 0',
            maxWidth: '300px',
        },
    };

    /**
     *
     */
    constructor(props: InjectedProps) {
        super(props);

        this.ListItemInvoked = this.ListItemInvoked.bind(this);
        this.PublicDisplayChanged = this.PublicDisplayChanged.bind(this);
        this.PublicDisplayChangeCanceled = this.PublicDisplayChangeCanceled.bind(this);
        this.DownloadPublicDisplays = this.DownloadPublicDisplays.bind(this);
        this.UpdateCurrentPublicDisplaysAsync = this.UpdateCurrentPublicDisplaysAsync.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);

        this.listSelection = new Selection({
            onSelectionChanged: () => this.setState({ ...this.state, selectionDetails: this.GetSelectionDetails() })
        });

        this.state = {
            knownReadPoints: [],
            selectionDetails: this.GetSelectionDetails(),
            isEditPanelOpen: false,
            publicDisplayId: undefined,
            isNewPanelOpen: false,
            isEditAssignmentPanelOpen: false,
            isChangePasswordPanelOpen: false,
            changePasswordAccountKey: undefined,
        }

        this.ToggleNewPanel = this.ToggleNewPanel.bind(this);
    }

    componentDidMount(): void {
        this.UpdateCurrentPublicDisplaysAsync();
    }

    render(): ReactNode {
        return (<div style={{ marginTop: "10px", marginLeft: "30px" }} data-is-scrollable="true">
            {this.RenderHeader()}
            {this.RenderToolbar()}
            {this.RenderTable()}
            {this.RenderEditPanel()}
            {this.RenderPasswordChangePanel()}
            {this.RenderNewPanel()}
            {this.RenderAssignmentPanel()}
        </div>);
    }

    
    private RenderPasswordChangePanel(): ReactNode {
        if (this.state.isChangePasswordPanelOpen && this.state.changePasswordAccountKey !== undefined) {
            return (<Panel
                type={PanelType.custom}
                customWidth="40vw"
                isBlocking={true}
                isOpen={this.state.isChangePasswordPanelOpen}
                hasCloseButton={false}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close">
                <ChangePassword onCancel={this.PublicDisplayChangeCanceled} onUpdated={this.PublicDisplayChanged} accountId={this.state.changePasswordAccountKey} />
            </Panel>)
        }
    }

    private RenderNewPanel(): ReactNode {
        if (this.state.isNewPanelOpen) {
            return (<Panel
                type={PanelType.medium}
                isBlocking={true}
                isOpen={this.state.isNewPanelOpen}
                hasCloseButton={false}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close">
                <CreatePublicDisplay onCreated={this.PublicDisplayChanged} onCancel={this.PublicDisplayChangeCanceled} />
            </Panel>)
        }
        else {
            <div />
        }
    }

    private RenderAssignmentPanel(): ReactNode {
        if (this.state.isEditAssignmentPanelOpen) {
            return (
                <Panel
                    type={PanelType.medium}
                    isBlocking={true}
                    isOpen={this.state.isEditAssignmentPanelOpen}
                    hasCloseButton={false}
                    closeButtonAriaLabel="Close">
                    <ChangePublicDisplayTrackAssignment onUpdated={this.PublicDisplayChanged} onCancel={this.PublicDisplayChangeCanceled} publicDisplayId={this.state.publicDisplayId} />
                </Panel>
            )
        }
        else {
            return null;
        }
    }

    private GetSelectionDetails(): string {
        const selectionCount = this.listSelection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'Kein Eintrag ausgewählt';
            case 1:
                return (
                    'Ein Eintrag gewählt: ' +
                    (this.listSelection.getSelection()[0] as IPublicDisplayInfo).name
                );
            default:
                return `${selectionCount} Einträge ausgewählt`;
        }
    }

    private RenderHeader(): ReactNode {
        return (
            <div>
                <h2>&Ouml;ffentliche Anzeigen</h2>
                <p>Hier k&ouml;nnen Sie die &ouml;ffentlichen Anzeigen (imoled-Displays) bearbeiten.</p>
                <p>Das bedeutet neue Anzeigen anzulegen, vorhandene zu bearbeiten und auch alte zu l&ouml;schen.<br />
                    Au&szlig;erdem k&ouml;nnen Sie hier zu Zuordnung zwischen einer Anzeige und den Laufstrecken vornehmen.</p>                
            </div>);
    }

    private RenderToolbar(): ReactNode {
        return (
            // <FocusTrapZone>
            <CommandBar
                items={[
                    {
                        key: 'newItem',
                        text: 'Neu',
                        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                        iconProps: { iconName: 'Add' },
                        onClick: () => this.ToggleNewPanel(),
                    },
                    {
                        key: 'download',
                        text: 'Liste herunterladen',
                        iconProps: { iconName: 'Download' },
                        onClick: () => this.DownloadPublicDisplays(),
                    },
                    {
                        key: 'delete',
                        text: 'Löschen',
                        iconProps: { iconName: 'Delete' },
                        onClick: () => { this.DeletePublicDisplaysAsync() },
                    },
                    {
                        key: 'refresh',
                        ariaLabel: "Refresh",
                        iconProps: { iconName: 'Refresh' },
                        onClick: () => { this.UpdateCurrentPublicDisplaysAsync() },
                    }
                ]}
                farItems={[
                    // {
                    //     key: 'info',
                    //     text: 'Info',
                    //     // This needs an ariaLabel since it's icon-only
                    //     ariaLabel: 'Info',
                    //     iconOnly: true,
                    //     iconProps: { iconName: 'Info' },
                    //     onClick: () => console.log('Info'),
                    // },
                ]}
                ariaLabel="Aktionen"
            />

            // </FocusTrapZone>
        );
    }

    private RenderTable(): ReactNode {
        return (
            <div className={this.classNames.selectionDetails}>
                <Label>{this.state.selectionDetails}</Label>
                <MarqueeSelection selection={this.listSelection}>
                    <ShimmeredDetailsList
                        checkboxVisibility={CheckboxVisibility.always}
                        setKey="knownUsers"
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(headerProps, defaultRender)=> {
                            if (!headerProps || !defaultRender) {
                                //technically these may be undefined...
                                return null;
                            }
                            return defaultRender({
                                ...headerProps,
                                styles: {
                                    root: {
                                        selectors: {
                                            '.ms-DetailsHeader-cell': {
                                                whiteSpace: 'normal',
                                                textOverflow: 'clip',
                                                lineHeight: 'normal',
                                            },
                                            '.ms-DetailsHeader-cellTitle': {
                                                height: '100%',
                                                alignItems: 'center',
                                            },
                                        },
                                    },
                                },
                            }) 
                        }}
                        items={this.listSelection.getItems()}
                        columns={[
                            {
                                key: "keyId",
                                name: "Id",
                                ariaLabel: "Id",
                                fieldName: "key",
                                minWidth: 250,
                                isCollapsible: true,
                                isResizable: true,
                                columnActionsMode: ColumnActionsMode.clickable,
                                onRender: (item) => {
                                    { var userItem = item as IPublicDisplayInfo; }
                                    return (<Link data-selection-invoke={true} >{userItem.key}</Link>)
                                }
                            }, {
                                key: "keyAccount",
                                name: "Login",
                                ariaLabel: "Login",
                                fieldName: "account",
                                minWidth: 100,
                                isResizable: true
                            },
                            {
                                key: "keyName",
                                name: "Name",
                                ariaLabel: "Name",
                                fieldName: "name",
                                minWidth: 100,
                                isResizable: true
                            }, 
                            {
                                key: "keyIdleTime",
                                name: "Inaktivitätszeit in Sekunden",
                                ariaLabel: "Inaktivitätszeit in Sekunden",
                                fieldName: "idleTime",
                                minWidth: 100,
                                isResizable: true
                            },
                            {
                                key: "keyIdleTarget",
                                name: "Anzeigeseite",
                                ariaLabel: "Anzeigeseite",
                                fieldName: "idleTarget",
                                minWidth: 100,
                                isResizable: true,
                                onRender: (item => <Link data-selection-invoke={false} target="_blank" href={(item as IPublicDisplayInfo).idleTarget ?? "N/A"} >{(item as IPublicDisplayInfo).idleTarget}</Link>)
                            },
                            {
                                key: "keyHorizontalResolution",
                                name: "Horizontale Auflösung",
                                ariaLabel: "Horizontale Auflösung",
                                fieldName: "horizontalResolution",
                                minWidth: 80,
                                isResizable: true
                            }, 
                            {
                                key: "keyVerticalResolution",
                                name: "Vertikale Auflösung",
                                ariaLabel: "Vertikale Auflösung",
                                fieldName: "verticalResolution",
                                minWidth: 80,
                                isResizable: true
                            },                             
                            {
                                key: "keyLatitude",
                                name: "Breitengrad",
                                ariaLabel: "Breitengrad",
                                fieldName: "latitude",
                                minWidth: 80,
                                isResizable: true
                            }, 
                            {
                                key: "keyLongitude",
                                name: "Längengrad",
                                ariaLabel: "Längengrad",
                                fieldName: "longitude",
                                minWidth: 80,
                                isResizable: true
                            }, {
                                key: "keyAssignedTrackCount",
                                name: "Anzahl zugeordneter Laufstrecken?",
                                ariaLabel: "Anzahl zugeordneter Laufstrecken?",
                                minWidth: 150,
                                isResizable: true,
                                onRender: (item => <Link data-selection-invoke={false} onClick={() => this.EditAssignment((item as IPublicDisplayInfo).key)} >{(item as IPublicDisplayInfo).assignedRaceTracks?.length}</Link>)
                            }, {
                                key: "keyChangePassword",
                                name: "Passwort ändern",
                                ariaLabel: "Passwort ändern",
                                minWidth: 150,
                                isResizable: false,
                                columnActionsMode: ColumnActionsMode.clickable,
                                onRender: (item) => {
                                    return (<Link data-selection-invoke={false} onClick={() => this.ChangePassword((item as IPublicDisplayInfo).accountId)} ><PasswordIcon /></Link>)
                                }
                            }
                        ]}
                        selection={this.listSelection}
                        selectionMode={SelectionMode.multiple}
                        enterModalSelectionOnTouch={true}
                        enableShimmer={!this.state.knownReadPoints}
                        ariaLabelForShimmer="Content is being fetched"
                        ariaLabelForGrid="Item details"
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={this.ListItemInvoked}
                        listProps={{
                            renderedWindowsAhead: 0,
                            renderedWindowsBehind: 0,
                        }}
                    />
                </MarqueeSelection>
            </div>
        );
    }

    EditAssignment(key: string | number): void {
        this.setState({
            ...this.state,
            isEditAssignmentPanelOpen: true,
            publicDisplayId: key as string,
        })
    }

    private ChangePassword(accountId: string | number | undefined): void {
        this.setState({
            ... this.state,
            isChangePasswordPanelOpen: true,
            changePasswordAccountKey: accountId,
        })
    }

    private RenderEditPanel(): ReactNode {
        if (this.state.isEditPanelOpen && this.state.publicDisplayId !== undefined) {
            return (
                <Panel
                    type={PanelType.medium}
                    isBlocking={true}
                    isOpen={this.state.isEditPanelOpen}
                    hasCloseButton={false}
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel="Close">
                    <EditPublicDisplay onUpdated={this.PublicDisplayChanged} onCancel={this.PublicDisplayChangeCanceled} publicDisplayId={this.state.publicDisplayId} />
                </Panel>)
        }
        else {
            <div />
        }
    }

    private PublicDisplayChanged(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: false,
            isEditPanelOpen: false,
            isEditAssignmentPanelOpen: false,
            isChangePasswordPanelOpen: false,
        });
        this.UpdateCurrentPublicDisplaysAsync();
    }

    private PublicDisplayChangeCanceled(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: false,
            isEditPanelOpen: false,
            isEditAssignmentPanelOpen: false,
            isChangePasswordPanelOpen: false,
        })
    }

    private ListItemInvoked(item: IPublicDisplayInfo): void {
        console.log(`Item invoked: ${item.key}`);
        this.setState({
            ... this.state,
            isEditPanelOpen: true,
            publicDisplayId: String(item.key)
        })
    };

    private async DeletePublicDisplaysAsync(): Promise<void> {
        try {
            var selectedItems = this.listSelection.getSelection();
            for (var i = 0; i < selectedItems.length; i++) {
                await this.props.webApiClient.DeletePublicDisplayAsync(this.props.userStore.accessToken, selectedItems[i].key as string)
            }
            // this.listSelection.getSelection().forEach(async element => await this.props.webApiClient.DeleteUserAsync(this.props.userStore.accessToken, element.key as string));
        }
        catch (reason) {
            var error = "Ein unbekannter Fehler ist aufgetreten.";

            if (reason.body as ErrorInfo !== null) {
                var errorInfo = reason.body as ErrorInfo;
                error = errorInfo.errors !== null && errorInfo.errors !== undefined
                ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
            }

            toast.error(`Konnte die ausgewählten Daten nicht löschen: ${error}`);
        }

        await this.UpdateCurrentPublicDisplaysAsync();
    }

    private ToggleNewPanel(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: true,
        });
    }

    private DownloadPublicDisplays(): void {
        var lines = [];
        lines.push("ID;Login;Name;Horizontal Auflösung;Vertikale Auflösung;Breitengrad;Längengrad;Anzahl zugeordneter Laufstrecken\r\n");

        if (this.state.knownReadPoints !== undefined) {
            this.state.knownReadPoints!.forEach(element => {
                lines.push(`${element.key};${element.account};${element.name};${element.horizontalResolution};${element.verticalResolution};${element.latitude ?? ""};${element.longitude ?? ""};${element.assignedRaceTracks?.length}\r\n`);
            });
        }

        var blob = new Blob(lines, { type: 'text/csv' })

        const a = document.createElement('a')
        a.download = "PublicDisplays.csv"
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    private async UpdateCurrentPublicDisplaysAsync(): Promise<void> {
        console.log("Refreshing the current known public displays.");
        var publicDisplays = await this.props.webApiClient.GetAllPublicDisplaysAsync(this.props.userStore.accessToken);

        var publicDisplayInfo: IPublicDisplayInfo[] = [];
        publicDisplays.forEach(element => {
            var trackInfo: IRaceTrackInfo[] = [];

            element.onTracks?.forEach(track => {
                trackInfo.push(
                    {
                        key: track.id!,
                        name: track.trackName!,
                        length: track.lengthInM!,
                        lowerBounds: track.lowerTimeBoundMs!,
                        upperBounds: track.upperTimeBoundMs!,
                        // TODO: Must retrieve the assigned read points.
                        assignedReadPoints: undefined,
                        conflictingTrackIds: undefined
                    }
                )
            })
            
            publicDisplayInfo.push({
                key: element.id!,
                name: element.displayName!,
                horizontalResolution: element.horizontalResolution!,
                verticalResolution: element.verticalResolution!,
                latitude: element.latitude ?? undefined,
                longitude: element.longitude ?? undefined,
                assignedRaceTracks: trackInfo,
                account: element.account?.login!,
                accountId: element.account?.id,
                idleTime: element.displayIdleTimeSeconds,
                idleTarget: element.idleDisplayTarget
            });
        });

        this.listSelection.setItems(publicDisplayInfo);
        this.setState({
            ...this.state,
            knownReadPoints: publicDisplayInfo,
        })        
    }
}