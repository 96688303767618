/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResetPasswordCommand } from '../models/ResetPasswordCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Requests the password reset.
     * @param eMailAddress The e mail address.
     * @returns any Accepted
     * @throws ApiError
     */
    public accountRequestPasswordReset(
        eMailAddress?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/account/passwordReset',
            query: {
                'eMailAddress': eMailAddress,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Resets the password.
     * @param requestBody The reset password command.
     * @returns any Success
     * @throws ApiError
     */
    public accountResetPassword(
        requestBody?: ResetPasswordCommand,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/account/resetPassword',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                419: `Client Error`,
            },
        });
    }

    /**
     * @param accountId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public accountChangePassword(
        accountId?: string,
        requestBody?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/account/changePassword',
            query: {
                'accountId': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
