import { DefaultButton, DocumentCard, DocumentCardActivity, DocumentCardDetails, DocumentCardTitle, IIconProps, IStackProps, IStackStyles, IconButton, MessageBar, MessageBarButton, MessageBarType, PrimaryButton, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent, MouseEventHandler, ReactNode } from "react";
import { toast } from "react-toastify";
import { ModificationType } from "../../Enumerations/ModificationType";
import { ApiError, ErrorInfo, RaceTrackDto, UserDto } from "../../generated";
import { EntityChangedEventArgs } from "../../Services/ISignalRClient";
import { InjectedProps } from "../InjectedProps";
import { IReadPointInfo } from "../Admin/ReadPointsList";

interface ChangeReadPointOrderProps extends InjectedProps {
    raceTrackId: string,
    onUpdated: () => void | undefined;
    onCancel: () => void | undefined;
}

interface IIndexedReadPointInfo extends IReadPointInfo {
    index: number
}

interface ChangeReadPointOrderState {
    name: string | undefined;
    valueChanged: boolean;
    saveError: string | undefined;
    externalChange: boolean;
    modificationType: ModificationType;

    readPoints: IIndexedReadPointInfo[];
    errorMessage: string | undefined;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class ChangeReadPointOrder extends Component<ChangeReadPointOrderProps, ChangeReadPointOrderState>{
    static defaultProps = {} as ChangeReadPointOrderProps;

    readonly upIcon: IIconProps = { iconName: 'ChevronUpSmall' };
    readonly downIcon: IIconProps = { iconName: 'ChevronDownSmall' };
    
    private MoveItemUp(event: any, index: number ){
        var activeReadPoint = this.state.readPoints.find(p => p.index == index);
        var arrayIndex = this.state.readPoints.indexOf(activeReadPoint!);
        var previousElement = this.state.readPoints[arrayIndex-1];
        this.props.webApiClient.SwapReadPoints(this.props.userStore.accessToken, this.props.raceTrackId, activeReadPoint!.key, previousElement.key);
    }

    private MoveItemDown(event: any, index: number ){
        var activeReadPoint = this.state.readPoints.find(p => p.index == index);
        var arrayIndex = this.state.readPoints.indexOf(activeReadPoint!);
        var nextElement = this.state.readPoints[arrayIndex + 1];
        this.props.webApiClient.SwapReadPoints(this.props.userStore.accessToken, this.props.raceTrackId, activeReadPoint!.key, nextElement.key);
    }
    /**
     * Initializes a new instance of EditRaceTrack
     */
    constructor(props: ChangeReadPointOrderProps) {
        super(props);

        this.CloseEditPanel = this.CloseEditPanel.bind(this);
        this.ReadPointModified = this.ReadPointModified.bind(this);

        this.HasErrors = this.HasErrors.bind(this);

        this.state = {
            saveError: undefined,
            readPoints: [],
            name: undefined,
            valueChanged: false,
            modificationType: ModificationType.None,
            externalChange: false,
            errorMessage: undefined
        }
    }

    componentDidMount(): void {
        this.GetRaceTrackReaderOrderAsync(this.props.raceTrackId);

        this.props.signalRClient.onReadPointModified.subscribe(this.ReadPointModified);
    }
    ReadPointModified(ReadPointModified: any) {
        this.GetRaceTrackReaderOrderAsync(this.props.raceTrackId);
    }

    componentWillUnmount(): void {
        this.props.signalRClient.onReadPointModified.unsubscribe(this.ReadPointModified);
    }

    render(): ReactNode {
        return (<div>
            <h2>Reihenfolge der Lesepunkte bearbeiten</h2>
            <span>
                <p>
                    <span>Hier können Sie die Reihenfolge der Lesepunkte auf der Laufstrecke {this.state.name} bearbeiten.</span>
                </p>
                <em>Bitte beachten Sie:&nbsp;</em>die Reihenfolge der Lesepunkte auf den jeweiligen Laufstrecken muss eineindeutig sein,
                sonst können die Laufwege möglicherweise nicht korrekt ermittelt werden!
            </span>
            <span>{this.state.externalChange ? this.state.modificationType === ModificationType.Updated ? this.RenderModificationWarning() : this.RenderDeletionError() : <div />}</span>
            {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
            <div className="divTable">
                <div className="divTableBody">
                    {this.RenderSections()}
                    <div className="divTableRow">
                        <div className="divTableCell"><PrimaryButton text="Schließen" onClick={this.CloseEditPanel} /></div>
                    </div>
                </div>
            </div>
        </div >)
    }
    RenderSections(): ReactNode[] {
        const renderedSections: ReactNode[] = [];

        var sortedList = this.state.readPoints.sort((a, b) => {
            if (a.index > b.index) {
                return 1;
            } else {
                return -1;
            }
        });

        const first = sortedList[0];
        const last = sortedList[sortedList.length - 1]

        sortedList.forEach(element => {
            renderedSections.push(<div className="divTableRow">
                <DocumentCard key={element.index} style={{ marginBottom: 10}}>
                    <DocumentCardDetails>
                        <DocumentCardTitle title={`Lesername: ${element.name}`} shouldTruncate />
                        <DocumentCardDetails>
                            {element !== first && (
                                <TooltipHost
                                    content="Diesen Lesepunkt um eines nach oben bewegen"
                                    // This id is used on the tooltip itself, not the host
                                    // (so an element with this id only exists when the tooltip is shown)
                                    id="UpToolTip"
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{ root: { display: 'inline-block' } }}
                                >
                                    <IconButton iconProps={this.upIcon} aria-label="Nach Oben" onClick={(e) => this.MoveItemUp(e, element.index)} />
                                </TooltipHost>
                            )}

                            {element !== last && (
                                <TooltipHost
                                    content="Diesen Lesepunkt um eines nach oben bewegen"
                                    // This id is used on the tooltip itself, not the host
                                    // (so an element with this id only exists when the tooltip is shown)
                                    id="UpToolTip"
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{ root: { display: 'inline-block' } }}
                                >
                                    <IconButton iconProps={this.downIcon} aria-label="Nach unten" onClick={(e) => this.MoveItemDown(e, element.index)}/>
                                </TooltipHost>)}
                        </DocumentCardDetails>
                    </DocumentCardDetails>
                </DocumentCard>
            </div>);
        });
        return renderedSections;
    }

    private CloseEditPanel(event: any): void {
        if (this.props.onCancel !== undefined) {
            this.props.onCancel();
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private HasErrors(): boolean {
        return this.state.errorMessage !== undefined;
    }

    private async GetRaceTrackReaderOrderAsync(raceTrackId: string): Promise<void> {
        var raceTrack = await this.props.webApiClient.GetTrackInfo(this.props.userStore.accessToken, raceTrackId);

        var readPointInfo: IIndexedReadPointInfo[] = [];

        if (raceTrack.assignedReadPoints !== undefined){
        raceTrack.assignedReadPoints?.sort((itemA, itemB) => {
            if (itemA.index! > itemB.index!){
                return -1
            }else if(itemA.index == itemB.index){
                return 0;
            }
            else{
                return 1
            }
        }).forEach(element => {
            readPointInfo.push({
                index: element.index!,
                key: element.id!,
                name: element.pointIdentifier!,
                isIntermediate: element.isIntermediate!,
                account: undefined,
                assignedRaceTracks: undefined,
                accountId: undefined,
                distanceToStart: undefined,
            })
        });
    }

        this.setState({
            ...this.state,
            readPoints: readPointInfo,
            valueChanged: false
        })
    }

    private RenderModificationWarning(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.severeWarning}
        >
            Diese Laufstrecke wurde von jemand anderem bearbeitet. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen und die geänderten Daten zu benutzen.
        </MessageBar>;
    }

    private RenderDeletionError(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.error}
            actions={<div>
                <MessageBarButton onClick={this.CloseEditPanel}>Bearbeitung abbrechen</MessageBarButton>
            </div>}
        >
            <p>Diese Laufstrecke wurde serverseitig von jemand anderem gelöscht. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen!</p>
        </MessageBar>;
    }
}