export class ResettableTimer {
    private timerId: NodeJS.Timeout | null = null;
    private duration: number;
    private onElapsed: () => void;
    private onElapsedCallback: (() => void) | null = null;
  
    constructor(duration: number, onElapsed: () => void) {
      this.duration = duration;
      this.onElapsed = onElapsed;
    }
  
    private startTimer() {
      this.timerId = setTimeout(() => {
        this.onElapsed();
        if (this.onElapsedCallback) {
          this.onElapsedCallback();
        }
      }, this.duration);
    }
  
    start(callback?: () => void) {
      // Cancel the existing timer, if any
      this.reset();
  
      if (callback) {
        this.onElapsedCallback = callback;
      }
  
      // Start a new timer
      this.startTimer();
    }
  
    reset() {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.onElapsedCallback = null;
    }
  }
  
  // Later, when a certain event occurs (e.g., a button click), restart the timer with a new callback:
  // timer.start(() => {
  //   console.log('Custom callback invoked on timer elapsed');
  // });
  