/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticateCommand } from '../models/AuthenticateCommand';
import type { AuthTokenInfo } from '../models/AuthTokenInfo';
import type { RefreshTokenInfo } from '../models/RefreshTokenInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Performs a login for the given account.
     * This endpoint creates JWTs on successful login. These are used for any operation outside of tag reporting<br />
     * As such it is used by Users (natural persons) and Public Displays.<br />
     * ***Note*** Trying to login a read point using this method provokes a 403-Status.
     * @param requestBody The command.
     * @returns AuthTokenInfo Success
     * @throws ApiError
     */
    public authenticationLogin(
        requestBody?: AuthenticateCommand,
    ): CancelablePromise<AuthTokenInfo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/authentication/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Refreshes the given access token.
     * @param requestBody The refresh token model.
     * @returns AuthTokenInfo Success
     * @throws ApiError
     */
    public authenticationRefreshToken(
        requestBody?: RefreshTokenInfo,
    ): CancelablePromise<AuthTokenInfo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/authentication/refresh-token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
