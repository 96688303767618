import { DocumentCard, TextField, PrimaryButton } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { CSSProperties, Component, FormEvent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiError } from "../generated";
import { InjectedProps } from "./InjectedProps";

interface ForgotPasswordState {
    eMailAddress: string | undefined,
    eMailAddressError: string | undefined,
    resetRequested: boolean,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class ForgotPassword extends Component<InjectedProps, ForgotPasswordState> {
    static defaultProps = {} as InjectedProps;
    private readonly eMailEmptyErrorMessage: string = "Die E-Mail-Adresse muss gefüllt sein";
    private readonly eMailMalformedErrorMessage: string = "Die E-Mail-Adresse ist ungültig";

    /**
     *
     */
    constructor(props: InjectedProps) {
        super(props);

        this.EMailAddressChanged = this.EMailAddressChanged.bind(this);
        this.SubmitPasswordResetRequest = this.SubmitPasswordResetRequest.bind(this);
        this.CannotSendRequest = this.CannotSendRequest.bind(this);

        this.state = {
            eMailAddress: undefined,
            eMailAddressError: this.eMailEmptyErrorMessage,
            resetRequested: false
        }
    }
    render(): ReactNode {
        const documentStyle: CSSProperties = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1em",
            verticalAlign: "middle",
            height: "100vh"
        }
        
        return this.state.resetRequested ? <div style={documentStyle}>
            Die Anfrage zum Zurücksetzen des Passwortes wurde versendet.<br />
            Sie werden in Kürze eine E-Mail mit einem R&uuml;cksetztoken von uns erhalten. Geben Sie dieses dann <Link to="/resetPassword">auf dieser Seite ein.</Link>
        </div>
            :
            <div style={documentStyle}>
                <DocumentCard aria-label="Login" className="cardStyle">
                    <div>
                        <div className="input-container">
                            <TextField label='E-Mail-Adresse:' type="text" name="uname" required about="Die E-Mail-Adresse" id="eMailTextField" errorMessage={this.state.eMailAddressError} onChange={this.EMailAddressChanged} />
                        </div>
                        <div className="button-container" style={{ marginTop: "10pt" }}>
                            <PrimaryButton text="Passwort zurücksetzen" onClick={this.SubmitPasswordResetRequest} disabled={this.CannotSendRequest()} />
                        </div>
                    </div>
                </DocumentCard>
            </div>
    }

    private SubmitPasswordResetRequest(event: any): void {
        console.log("Submitting password reset request.")

        try {
            this.props.webApiClient.RequestPasswordReset(this.state.eMailAddress!);
            this.setState({
                ...this.state,
                resetRequested: true,
            })
        }
        catch (error) {
            if (error instanceof ApiError) {
                var apiError = error as ApiError;
                toast.error("Konnte den Request zum Zurücksetzen des Passwortes nicht übermitteln. Bitte versuchen Sie es später noch einmal.", {
                    autoClose: 10000,
                    position: "top-center"
                })
            }
        }
    }

    private CannotSendRequest(): boolean {
        var result: boolean = this.state.eMailAddressError !== undefined;
        console.log(`Checking whether the button must be enabled: ${this.state.eMailAddressError} =  ${result}`);
        return result;
    }

    private EMailAddressChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        var errorMessage: string | undefined = undefined;
        if (newValue === null || newValue === undefined || newValue === "") {
            errorMessage = this.eMailEmptyErrorMessage;
        }

        if (errorMessage === undefined && newValue !== undefined) {
            var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            var serchfind = regexp.test(newValue);

            if (!serchfind) {
                errorMessage = "Keine gültige Mailadresse";
            }
        }

        this.setState({
            ...this.state,
            eMailAddress: newValue,
            eMailAddressError: errorMessage,
        })
    }
}