/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccountService } from './services/AccountService';
import { AuthenticationService } from './services/AuthenticationService';
import { CommonService } from './services/CommonService';
import { PublicDisplaysService } from './services/PublicDisplaysService';
import { RaceService } from './services/RaceService';
import { RaceTracksService } from './services/RaceTracksService';
import { ReadPointsService } from './services/ReadPointsService';
import { RfidReaderService } from './services/RfidReaderService';
import { StatisticService } from './services/StatisticService';
import { SystemService } from './services/SystemService';
import { SystemLogService } from './services/SystemLogService';
import { TagsService } from './services/TagsService';
import { UsersService } from './services/UsersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class GerbilClient {

    public readonly account: AccountService;
    public readonly authentication: AuthenticationService;
    public readonly common: CommonService;
    public readonly publicDisplays: PublicDisplaysService;
    public readonly race: RaceService;
    public readonly raceTracks: RaceTracksService;
    public readonly readPoints: ReadPointsService;
    public readonly rfidReader: RfidReaderService;
    public readonly statistic: StatisticService;
    public readonly system: SystemService;
    public readonly systemLog: SystemLogService;
    public readonly tags: TagsService;
    public readonly users: UsersService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.account = new AccountService(this.request);
        this.authentication = new AuthenticationService(this.request);
        this.common = new CommonService(this.request);
        this.publicDisplays = new PublicDisplaysService(this.request);
        this.race = new RaceService(this.request);
        this.raceTracks = new RaceTracksService(this.request);
        this.readPoints = new ReadPointsService(this.request);
        this.rfidReader = new RfidReaderService(this.request);
        this.statistic = new StatisticService(this.request);
        this.system = new SystemService(this.request);
        this.systemLog = new SystemLogService(this.request);
        this.tags = new TagsService(this.request);
        this.users = new UsersService(this.request);
    }
}

