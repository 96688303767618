import { CancellablePromise } from "mobx/dist/internal";
import { emit } from "process";
import { AuthTokenInfo, GerbilClient, PublicDisplayDto, RaceTrackDto, ReadPointDto, UserDto } from "../generated";
import { IGerbilWebApiClient } from "./IGerbilWebApiClient";

export class GerbilWebApiClient implements IGerbilWebApiClient {
    host: string | undefined;
    private client: GerbilClient;
    private static instance: GerbilWebApiClient;

    /**
    * Returns the singleton instance.
    */
    public static get Instance(): IGerbilWebApiClient {
        if (GerbilWebApiClient.instance == null) {
            const isDev = process.env.NODE_ENV === 'development';
            console.log(`Using host ${window.location.origin}`);
            GerbilWebApiClient.instance = new GerbilWebApiClient(window.location.origin);
        }

        return GerbilWebApiClient.instance;
    }

    constructor(host: string) {
        this.host = host;

        // Try to log in the user on the server
        this.client = new GerbilClient({
            BASE: this.host
        });
    }

    /** @inheritdoc */
    public Login(login: string, passWord: string): CancellablePromise<AuthTokenInfo> {
        try {
            return this.client.authentication.authenticationLogin({
                login: login,
                password: passWord
            });
        }
        catch (error) {
            console.error(`Could not perform the login, an error occurred. ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetPublicDisplayInfoByLogin(userToken: string, displayLoginName: string): CancellablePromise<PublicDisplayDto> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.publicDisplays.publicDisplaysGetByLogin(displayLoginName);
        }
        catch (error) {
            console.error(`Could not retrieve the display information, an error occurred. $(error.message)`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetPublicDisplayInfo(userToken: string, displayId: string): CancellablePromise<PublicDisplayDto> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.publicDisplays.publicDisplaysGet(displayId);
        }
        catch (error) {
            console.error(`Could not retrieve the display information, an error occurred. ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetTrackInfo(userToken: string, trackId: string): CancellablePromise<RaceTrackDto> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.raceTracks.raceTracksGet(trackId);
        }
        catch (error) {
            console.error(`Could not retrieve the track information, an error occurred. $(error.message)`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetReadPointInfo(userToken: string, readPointId: string): CancellablePromise<ReadPointDto> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.readPoints.readPointsGet(readPointId);
        }
        catch (error) {
            console.error(`Could not retrieve the read point information: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public CreateUser(userToken: string, displayName: string, eMail: string, login: string, password: string, givenName: string | null, surName: string | null, isSuperUser: boolean, mustChangePassword: boolean): CancellablePromise<string> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.users.usersCreate({
                displayName: displayName,
                email: eMail,
                login: login,
                password: password,
                isSuperUser: isSuperUser,
                givenName: givenName,
                surName: surName,
                mustChangePassword: mustChangePassword
            });
        }
        catch (error) {
            console.error(`Could not create the new user: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public CreateReadPoint(userToken: string, displayName: string, login: string, password: string, isIntermediate: boolean, distanceToStart: number|undefined): CancellablePromise<string> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.readPoints.readPointscreate({
                pointIdentifier: displayName,
                login: login,
                password: password,
                isIntermediate: isIntermediate,
                distanceToStartInM: distanceToStart
            })
        }
        catch (error) {
            console.error(`Could not create a new read point: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public CreateRaceTrack(userToken: string, name: string, length: number, lowerBounds: number, upperBounds: number): CancellablePromise<string> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.raceTracks.raceTrackscreate({
                lengthInM: length,
                lowerTimeBoundMs: lowerBounds,
                upperTimeBoundMs: upperBounds,
                trackName: name
            })
        }
        catch (error) {
            console.error(`Could not create a new race track: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public CreatePublicDisplay(userToken: string, name: string, idleTime: number, idleTarget: string, horizontalResolution: number, verticalResolution: number, login: string, password: string, latitude?: number, longitude?: number): CancellablePromise<string> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.publicDisplays.publicDisplaysCreate({
                displayName: name,
                horizontalResolution: horizontalResolution,
                verticalResolution: verticalResolution,
                latitude: latitude,
                longitude: longitude,
                login: login,
                password: password,
                idleDisplayTarget: idleTarget,
                displayIdleTimeSeconds: idleTime
            });
        }
        catch (error) {
            console.error(`Could not create a new public display: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public UpdateUserAsync(userToken: string, userId: string, eMail: string, givenName: string, surName: string, displayName: string, isSuperUser: boolean): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.users.usersUpdate(userId, {
                displayName: displayName,
                email: eMail,
                givenName: givenName,
                surName: surName,
                isSuperUser: isSuperUser
            })
        }
        catch (error) {
            console.error(`The user could not be updated: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public UpdateRaceTrackAsync(userToken: string, id: string, name: string, length: number, lowerBounds: number, upperBounds: number): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.raceTracks.raceTracksUpdate(id, {
                trackName: name,
                lengthInM: length,
                lowerTimeBoundMs: lowerBounds,
                upperTimeBoundMs: upperBounds
            })
        }
        catch (error) {
            console.error(`The track could not be updated: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public UpdateReadPointAsync(userToken: string, id: string, name: string, isIntermediate: boolean, distanceToStart: number|undefined): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.readPoints.readPointsUpdate(id, {
                pointIdentifier: name,
                isIntermediate: isIntermediate,
                distanceToStartInM: distanceToStart,
            })
        }
        catch (error) {
            console.error(`The rad point could not be updated: ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public UpdatePublicDisplayAsync(userToken: string, id: string, name: string,  idleTime: number, idleTarget: string, horizontalResolution: number, verticalResolution: number, latitude?: number, longitude?: number): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.publicDisplays.publicDisplaysUpdate(id, {
                displayName: name,
                displayIdleTimeSeconds: idleTime == 0? undefined : idleTime,
                idleDisplayTarget: idleTarget,
                horizontalResolution: horizontalResolution,
                verticalResolution: verticalResolution,
                latitude: latitude,
                longitude: longitude,                
            })
        }
        catch (error) {
            console.error(`The public display could not be updated: ${error.message}`);
            throw error;
        }
    }
    /** @inheritdoc */
    public DeleteUserAsync(userToken: string, id: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.users.usersDelete(id);
        }
        catch (error) {
            console.error(`The user identified by the id ${id} could not be deleted`);
            throw error;
        }
    }

    /** @inheritdoc */
    public DeleteRaceTrackAsync(userToken: string, id: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.raceTracks.raceTracksDelete(id);
        }
        catch (error) {
            console.error(`The race track identified by the id ${id} could not be deleted`);
            throw error;
        }
    }

    /** @inheritdoc */
    public DeleteReadPointAsync(userToken: string, id: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.readPoints.readPointsDelete(id);
        }
        catch (error) {
            console.error(`The read point identified by the id ${id} could not be deleted`);
            throw error;
        }
    }

    /** @inheritdoc */
    public DeletePublicDisplayAsync(userToken: string, id: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.publicDisplays.publicDisplaysDelete(id);
        }
        catch (error) {
            console.error(`Could not delete the public display: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetAllUsersAsync(userToken: string): CancellablePromise<UserDto[]> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.users.usersGetAll();
        }
        catch (error) {
            console.error(`Could not retrieve all users from the service: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetAllRaceTracksAsync(userToken: string): CancellablePromise<RaceTrackDto[]> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.raceTracks.raceTracksGetAll();
        }
        catch (error) {
            console.error(`Could not retrieve all race tracks from the service: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetAllReadPointsAsync(userToken: string): CancellablePromise<RaceTrackDto[]> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.readPoints.readPointsGetAll();
        }
        catch (error) {
            console.error(`Could not retrieve all read points from the service: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetAllPublicDisplaysAsync(userToken: string): CancellablePromise<PublicDisplayDto[]> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.publicDisplays.publicDisplaysGetAll();
        }
        catch (error) {
            console.error(`Could not retrieve all public displays: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public GetUserByLoginAsync(userToken: string, login: string): CancellablePromise<UserDto> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.users.usersGetByLogin(login)
        }
        catch (error) {
            console.error(`Could not retrieve the user from the service: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public AssignTrackToReadpoint(userToken: string, readPointId: string, raceTrackId: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.readPoints.readPointsAssign({
                raceTrackId: raceTrackId,
                readPointId: readPointId,
            })
        }
        catch (error) {
            console.error(`Could not assign the reader to the race track: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public AssignTrackToDisplay(userToken: string, publicDisplayId: string, raceTrackId: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.publicDisplays.publicDisplaysAssign({
                publicDisplayId: publicDisplayId,
                raceTrackId: raceTrackId,
            })
        }
        catch (error) {
            console.error(`Could not assigne the public display to the race track: ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public UnassignTrackFromReadpoint(userToken: string, readPointId: string, raceTrackId: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.readPoints.readPointsUnassign({
                raceTrackId: raceTrackId,
                readPointId: readPointId,
            })
        }
        catch (error) {
            console.error(`Could not unassign the reader to the race track: ${error}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public UnassignTrackFromDisplay(userToken: string, publicDisplayId: string, raceTrackId: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.publicDisplays.publicDisplaysUnassign({
                publicDisplayId: publicDisplayId,
                raceTrackId: raceTrackId,
            })
        }
        catch (error) {
            console.error(`Could not unassign the publich display from the race track: ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public RequestPasswordReset(eMail: string): void {
        try {
            this.client.account.accountRequestPasswordReset(eMail);
        }
        catch (error) {
            console.error(`Could not request a password reset: ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public ResetPassword(eMail: string, token: string, newPassword: string): CancellablePromise<void> {
        try {
            return this.client.account.accountResetPassword({
                eMailAddress: eMail,
                newPassword: newPassword,
                resetToken: token,
            });
        }
        catch (error) {
            console.error(`Could not update the password: ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public SwapReadPoints(userToken: string, raceTrackId: string, readPointA: string, readPointB: string): CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.readPoints.readPointsSwapReadPointOrder({
                raceTrackId: raceTrackId,
                readPointIdA: readPointA,
                readPointIdB: readPointB
            });
        }
        catch (error) {
            console.error(`Could not swap the read points: ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public ChangePassword(userToken: string, accountIdToChange: string, newPassword: string) : CancellablePromise<void> {
        try {
            var authenticatedClient = this.AuthenticatedClient(userToken);
            return authenticatedClient.account.accountChangePassword(accountIdToChange, newPassword);
        }
        catch (error) {
            console.error(`Could not update the password: ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public RegisterAccount(tagId: string, firstName: string, lastName: string, eMail: string, login: string, password: string): CancellablePromise<void> {
        try{
            return this.client.users.usersRegister({
                eMailAddress: eMail,
                firstName: firstName,
                surName: lastName,
                login: login,
                password: password,
                tagEpc: tagId
            });
        }
        catch(error) {
            console.error(`Could not register the user: ${error.message}`);
            throw error;
        }
    }

    /** @inheritdoc */
    public ConfirmAccount(eMail: string, confirmationToken: string): CancellablePromise<void> {
        try {
            return this.client.users.usersActiveAccount({
                activationToken: confirmationToken,
                eMailAddress: eMail
            });
        }
        catch(error) {
            console.error(`Could not activate the user's account: ${error.message}`);
            throw error;
        }
    }

    private AuthenticatedClient(userToken: string) {
        return new GerbilClient({
            BASE: this.host,
            WITH_CREDENTIALS: true,
            CREDENTIALS: "include",
            TOKEN: userToken
        });
    }
}