import { Component, CSSProperties, ReactNode } from "react";
import { observer } from "mobx-react"

import { inject } from "mobx-react";
import { Login } from "./Login";
import { InjectedProps } from "./InjectedProps";
import Logout from "../Components/Logout";
import { AccountRole } from "../Stores/AccountRole";
import PublicDisplay from "./PublicDisplay";
import UserPage from "./Users/UserPage";
import { Route, Routes } from "react-router-dom";
import MainAdmin from "./Admin/MainAdmin";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import LiveLog from "./Admin/LiveLog";
import Register from "./Users/Register";
import ConfirmAccount from "./Users/ConfirmAccount";
import SampleMarketingContent from "./SampleMarketingContent";

@inject("userStore")
@observer
export default class Layout extends Component<InjectedProps> {
    static defaultProps = {} as InjectedProps;

    render() {
        return this.props.userStore.isLoggedIn ?
            this.renderLoggedInRoutes()
            : this.renderLogIn()
    }

    private containerStyle: CSSProperties = {
        height: "100vh"
    }

    private renderLogIn(): ReactNode {
        return (
        <Routes>
            <Route path="/" element={<Login />}/>
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />

            <Route path="/register" element={<Register/>} />
            <Route path="/confirmaccount" element={<ConfirmAccount/>} />
            <Route path="/samplemarketingcontent" element={<SampleMarketingContent/>}/>
        </Routes>);
    }

    private renderLoggedInRoutes() {
        if (this.props.userStore.role === AccountRole.PublicDisplay) {
            return this.RenderPublicDisplay()
        }
        else {
            return this.RenderInteractiveSites()
        }        
    }

    private RenderInteractiveSites() {
        return <div style={{ height: "100vh", width: "100vw"}}>
            <h1 style={{ display: "flex", marginLeft: "10px", fontFamily: "Roboto Slab" }}>tagItron Gerbil</h1>
            <div style={{ display: "flex", margin: "10px" }}>
                <Logout />
            </div>
            <div style={{ display: "flex", margin: "10px" }}>
                <Routes>
                    <Route path="/" element={<UserPage />} />
                    <Route index element={<UserPage />} />
                    <Route path="admin" element={this.props.userStore.hasAdminRole ? (<MainAdmin />) : (<UserPage />)} />
                    <Route path="livelog" element={this.props.userStore.hasAdminRole ? (<LiveLog />) : (<UserPage />)}/>
                </Routes>
            </div>
        </div>;
    }

    private RenderPublicDisplay() {
        return <div style={this.containerStyle}>
            <PublicDisplay />
        </div>;
    }
}