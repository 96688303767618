/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRaceTrackCommand } from '../models/CreateRaceTrackCommand';
import type { RaceTrackDto } from '../models/RaceTrackDto';
import type { UpdateRaceTrackCommand } from '../models/UpdateRaceTrackCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RaceTracksService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a new race track.
     * @param requestBody The race tack data.
     * @returns string Created
     * @throws ApiError
     */
    public raceTrackscreate(
        requestBody?: CreateRaceTrackCommand,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/racetracks/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Updates a race track.
     * @param id The id of the race track to update.
     * @param requestBody The data to update.
     * @returns void
     * @throws ApiError
     */
    public raceTracksUpdate(
        id: string,
        requestBody?: UpdateRaceTrackCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.0/racetracks/{id}/update',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Deletes a race track.
     * @param id The id of the race track to delete.
     * @returns void
     * @throws ApiError
     */
    public raceTracksDelete(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1.0/racetracks/{id}/delete',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets a race track by its id.
     * @param id The id of the race track to get.
     * @returns RaceTrackDto Success
     * @throws ApiError
     */
    public raceTracksGet(
        id: string,
    ): CancelablePromise<RaceTrackDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/racetracks/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Gets a list of all configured race tracks.
     * @returns RaceTrackDto Success
     * @throws ApiError
     */
    public raceTracksGetAll(): CancelablePromise<Array<RaceTrackDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/racetracks',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
