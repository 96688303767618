import { inject, observer } from "mobx-react";
import { CSSProperties, Component, FormEvent, ReactNode } from "react";
import { withRouter } from "../../Components/Utils";
import { InjectedPropsWithRouter } from "../InjectedProps";
import { DocumentCard, TextField, PrimaryButton, TooltipHostBase, Stack, IStackStyles, IStackProps, MessageBar, MessageBarType } from "@fluentui/react";
import { Link } from "react-router-dom";
import { HighlightSpanKind, textChangeRangeIsUnchanged } from "typescript";
import { ApiError, ErrorInfo, RegisterUserDto } from "../../generated";

interface RegisterState {
    eMailAddress: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    tagId: string | undefined,
    userName: string | undefined,
    password: string | undefined,
    passwordConfirm: string | undefined,

    eMailAddressError: string | undefined,
    firstNameError: string | undefined,
    lastNameError: string | undefined,
    tagIdError: string | undefined,
    userNameError: string | undefined,
    passwordError: string | undefined,
    passwordConfirmError: string | undefined,


    // passwordErrorMessage: string | undefined,
    // passwordConfirmErrorMessage: string | undefined,
    registrationRequested: boolean,
    //resetToken: string | undefined,
    //resetTokenError: string | undefined,
    //valueChanged: boolean,
    saveError: string | undefined,
    //changesSaved: boolean,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
class Register extends Component<InjectedPropsWithRouter, RegisterState> {
    static defaultProps = {} as InjectedPropsWithRouter;

    private readonly passWordEmptyError = "Das Passwort darf nicht leer sein";
    private readonly eMailEmptyError = "Die E-Mail-Adresse darf nicht leer sein";
    private readonly firstNameEmptyError = "Der Vorname darf nicht leer sein";
    private readonly lastNameEmptyError = "Der Nachname darf nicht leer sein";
    private readonly tagIdEmptyError = "Die ID des Lauftag darf nicht leer sein";
    private readonly userNameEmptyError = "Der gewünschte Benutzername darf nicht leer sein";
    private readonly passwordTooShortError = "Das Passwort muss mindestens sechs Zeichen lang sein";
    private readonly passwordsDoNotMatchError = "Die Passwörter stimmen nicht überein";

    /**
     *
     */
    constructor(props: InjectedPropsWithRouter) {
        super(props);

        this.UserNameChanged = this.UserNameChanged.bind(this);

        this.CannotSendRequest = this.CannotSendRequest.bind(this);
        this.SubmitRegistrationRequest = this.SubmitRegistrationRequest.bind(this);
        this.EMailAddressChanged = this.EMailAddressChanged.bind(this);
        this.FirstNameChanged = this.FirstNameChanged.bind(this);
        this.LastNameChanged = this.LastNameChanged.bind(this);
        this.PasswordChanged = this.PasswordChanged.bind(this);
        this.PasswordConfirmChanged = this.PasswordConfirmChanged.bind(this);
        this.TagIdChanged = this.TagIdChanged.bind(this)

        this.ValidatePasswortContent = this.ValidatePasswortContent.bind(this);

        this.state = {
            eMailAddress: undefined,
            firstName: undefined,
            lastName: undefined,
            tagId: undefined,
            userName: undefined,
            password: undefined,
            passwordConfirm: undefined,
            registrationRequested: false,

            eMailAddressError: this.eMailEmptyError,
            firstNameError: this.firstNameEmptyError,
            lastNameError: this.lastNameEmptyError,
            tagIdError: this.tagIdEmptyError,
            userNameError: this.userNameEmptyError,
            passwordError: this.passWordEmptyError,
            passwordConfirmError: this.passWordEmptyError,
            saveError: undefined,
        }
    }

    componentDidUpdate(prevProps: InjectedPropsWithRouter, prevState: RegisterState) {
        if (this.state.password !== prevState.password || this.state.passwordConfirm !== prevState.passwordConfirm) {
            this.ValidatePasswortContent();
        }
    }

    render(): ReactNode {
        const documentStyle: CSSProperties = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1em",
            verticalAlign: "middle",
            height: "100vh"
        }

        const columnProps: Partial<IStackProps> = {
            tokens: { childrenGap: 5 },
            styles: { root: { width: 300 } },
        };

        const stackTokens = { childrenGap: 50 };
        const stackStyles: Partial<IStackStyles> = { root: { width: "100%" } };

        return this.state.registrationRequested ? <div style={documentStyle}>
                <h1 style={{ fontFamily: "Roboto Slab" }}>tagItron Gerbil - Kontoregistrierung</h1>

            Die Anfrage zur Registrierung ist eingegangen.
            Sie werden in K&uuml;rze eine E-Mail mit einem Best&auml;tigungstoken von uns erhalten. Geben Sie dieses dann <Link to="/confirmaccount">auf dieser Seite ein.</Link>
            <Link to="/">Zurück zur Startseite</Link>
        </div>
            :
            <div style={documentStyle}>
                <h1 style={{ fontFamily: "Roboto Slab" }}>tagItron Gerbil - Kontoregistrierung</h1>
                <div>
                    <p>
                        Auf dieser Seite k&ouml;nnen Sie sich für den Zugriff auf die Inhalte von tagItron Gerbil registrieren.<br />
                        Dies ermöglich auch die Nutzung der Gerbil-App.<br />
                        Dazu ben&ouml;tigen Sie neben ihrem Vor- und Nachnamen lediglich die ID des Lauftokens, welches Ihnen bei der Anmeldung ausgeh&auml;ndigt wurde.<br />
                        <em><strong>Bitte beachten Sie, dass Sie Ihre Angaben genauso eintragen m&uuml;ssen wie bei der Anmeldung für die Tagausgabe!</strong></em><br />
                        Dies bezieht sich auch auf eventuelle Umlaute und Gro&szlig;- und Kleinschreibung.
                    </p>
                    Au&szlig;erdem m&uuml;ssen Sie in diesem Formular <em>alle</em> Felder ausf&uuml;llen.
                </div>
                <div >
                    <Stack tokens={stackTokens} styles={stackStyles}>
                    {this.state.saveError !== undefined && (
          <MessageBar
            delayedRender={false}
            isMultiline={true}
            // Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
            messageBarType={MessageBarType.error}
            // Or you could set the role manually, IF an alert role is appropriate for the message
            // role="alert"
          >
            {this.state.saveError}
          </MessageBar>
        )}
                        <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                            <Stack {...columnProps}>
                                <TextField label='ID des Lauftags:' placeholder="Die ID Ihres Lauftags" value={this.state.tagId} type="text" name="tagId" required about="Der Lauftag" id="tagIdField" errorMessage={this.state.tagIdError} onChange={this.TagIdChanged} />

                                <Stack horizontal {...columnProps}>
                                    <TextField label='Vorname:' placeholder="Ihr Vorname" value={this.state.firstName} type="text" name="firstName" required about="Ihr Vorname" id="firstNameTextField" errorMessage={this.state.firstNameError} onChange={this.FirstNameChanged} />
                                    <TextField label='Nachname:' placeholder="Ihr Nachname" value={this.state.lastName} type="text" name="givenName" required about="Ihr Nachname" id="lastNameTextField" errorMessage={this.state.lastNameError} onChange={this.LastNameChanged} />
                                </Stack>

                            </Stack>

                            <Stack {...columnProps}>
                                <TextField label='Gewünschter Benutzername:' placeholder="Benutzername, mit dem Sie sich anmelden" value={this.state.userName} type="text" name="uName" required about="Ihr Benutzername" id="userNameTextField" errorMessage={this.state.userNameError} onChange={this.UserNameChanged} />
                                <TextField placeholder="Ihre E-Mail-Adresse" label='E-Mail-Adresse:' type="text" name="eMail" required about="Die E-Mail-Adresse" id="eMailTextField" errorMessage={this.state.eMailAddressError} onChange={this.EMailAddressChanged} />

                                <Stack horizontal {...columnProps}>
                                    <TextField label='Passwort (mind. 6 Zeichen):' canRevealPassword revealPasswordAriaLabel="Passwort anzeigen" placeholder="Passwort" value={this.state.password} type="password" name="password" required about="Ihr Passwort" id="passwordTextField" errorMessage={this.state.passwordError} onChange={this.PasswordChanged} />
                                    <TextField label='Passwortwiederholung:' canRevealPassword revealPasswordAriaLabel="Passwort anzeigen" placeholder="Passwort wiederholen" value={this.state.passwordConfirm} type="password" name="passwordConfirm" required about="Erneut ihr Passwort" id="passwordConfirmTextField" errorMessage={this.state.passwordConfirmError} onChange={this.PasswordConfirmChanged} />

                                </Stack>
                            </Stack>
                        </Stack>
                        <PrimaryButton text="Konto registrieren" onClick={this.SubmitRegistrationRequest} disabled={this.CannotSendRequest()} />                        
                    </Stack>
                    <Link to="/">Zurück zur Startseite</Link>
                </div>
            </div>
    }

    private FirstNameChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        this.setState({
            ...this.state,
            firstName: newValue,
            firstNameError: newValue === undefined || newValue === "" ? this.firstNameEmptyError : undefined,
        })
    }

    private LastNameChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        this.setState({
            ...this.state,
            lastName: newValue,
            lastNameError: newValue === undefined || newValue === "" ? this.lastNameEmptyError : undefined,
        })
    }
    private UserNameChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        this.setState({
            ...this.state,
            userName: newValue,
            userNameError: newValue === undefined || newValue === "" ? this.userNameEmptyError : undefined,
        });
    }

    private PasswordChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        this.setState({
            ...this.state,
            password: newValue,
        });
    }

    private PasswordConfirmChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {

        this.setState({
            ...this.state,
            passwordConfirm: newValue,
        });
    }

    private TagIdChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        var error: string | undefined = undefined;

        if (newValue === undefined || newValue === "") {
            error = this.tagIdEmptyError
        }
        else {
            // Check if the string length is even and contains only hex characters
            const hexRegex = /^[0-9A-Fa-f]+$/;

            if (newValue.length % 2 !== 0 || !hexRegex.test(newValue)) {
                error = "Ungültiger TAG-Wert";
            }
        }

        this.setState({
            ...this.state,
            tagId: newValue,
            tagIdError: error,
        })
    }

    private ValidatePasswortContent(): void {
        var passwordError: string | undefined = undefined;
        var passwordConfirmError: string | undefined = undefined;

        if (this.state.password === undefined || this.state.password === "") {
            passwordError = this.passWordEmptyError;
        }
        else if (this.state.password.length < 6) {
            passwordError = this.passwordTooShortError;
        }

        if (this.state.passwordConfirm === undefined || this.state.passwordConfirm === "") {
            passwordConfirmError = this.passWordEmptyError;
        }
        else if (this.state.passwordConfirm.length < 6) {
            passwordConfirmError = this.passwordTooShortError;
        }

        if (this.state.password != this.state.passwordConfirm) {
            passwordError = this.passwordsDoNotMatchError;
            passwordConfirmError = this.passwordsDoNotMatchError;
        }

        this.setState({
            ...this.state,
            passwordError: passwordError,
            passwordConfirmError: passwordConfirmError,
        });
    }

    private EMailAddressChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        // Is the E-Mail-Address syntactically correct?
        var error: string | undefined = undefined;
        if (newValue === "" || newValue === undefined) {
            error = this.eMailEmptyError;
        }
        else {
            var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            if (!regex.test(newValue)) {
                error = "Ungültige E-Mail-Adresse";
            }
        }
        this.setState({
            ...this.state,
            eMailAddress: newValue,
            eMailAddressError: error,
        });
    }

    private CannotSendRequest(): boolean {
        var result: boolean =
            this.state.eMailAddressError !== undefined
            || this.state.firstNameError !== undefined
            || this.state.lastNameError !== undefined
            || this.state.passwordError !== undefined
            || this.state.tagIdError !== undefined
            || this.state.userNameError !== undefined;
        console.log(`Checking whether the button must be enabled; form has error =  ${result}`);
        return result;
    }

    private async SubmitRegistrationRequest(event: any) {
        console.log("Submitting registration request.")
        try {
            await this.props.webApiClient.RegisterAccount(this.state.tagId!, this.state.firstName!, this.state.lastName!, this.state.eMailAddress!, this.state.userName!, this.state.password!);
            this.setState({
                ...this.state,
                saveError: undefined,
                registrationRequested: true,
            })
        }
        catch (error: any) {
            if (error instanceof ApiError) {
                var apiError = error as ApiError;

                console.error(error);
                var error = "Ein Fehler ist aufgetreten:";

                if (apiError.status == 400) {
                    if (apiError.body as ErrorInfo !== null) {
                        var errorInfo = apiError.body as ErrorInfo;
                        error = errorInfo.errors !== null && errorInfo.errors !== undefined
                            ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    }

                    this.setState({
                        ...this.state,
                        saveError: "Die Anfrage war ungültig: " + error,
                    });
                }
                else if (apiError.status == 401) {
                    if (apiError.body as ErrorInfo !== null) {
                        var errorInfo = apiError.body as ErrorInfo;
                        error = errorInfo.errors !== null && errorInfo.errors !== undefined
                            ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    }

                    this.setState({
                        ...this.state,
                        saveError: "Die eingegebenen Registrierungsdaten sind ungültig."
                    });
                }
                else if (apiError.status == 403) {
                    if (apiError.body as ErrorInfo !== null) {
                        var errorInfo = apiError.body as ErrorInfo;
                        error = errorInfo.errors !== null && errorInfo.errors !== undefined
                            ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    }

                    this.setState({
                        ...this.state,
                        saveError: "Ein Account mit den angegebenen Daten wurde bereits aktiviert."
                    });
                }
                else if (apiError.status == 409) {
                    var detailedInfo = apiError.body as RegisterUserDto;
                    error = "Ein oder mehrere Fehler sind bei der Anlage des Kontos aufgetreten:\n";

                    if (!detailedInfo.eMailValid) {
                        error += "Die angegebene E-Mail-Adresse wurde bereits verwendet\n";
                    }
                    if (!detailedInfo.loginValid) {
                        error += "Der Tag ist unbekannt oder der Benutzername wird bereits verwendet\n";
                    }

                    if (!detailedInfo.passwordValid) {
                        error += "Das Passwort entspricht nicht den Richtlinien";
                    }
                    this.setState({
                        ...this.state,
                        saveError: error,
                    });
                }
            }
        }
    }
}

export default withRouter(Register);