import { Component, FormEvent, MouseEvent, MouseEventHandler, ReactNode } from "react";
import { inject, observer } from "mobx-react";
import { InjectedProps } from "../Pages/InjectedProps";
import { Link } from "react-router-dom";

interface ILogoutState {
    fullName: string | undefined
}

@inject("userStore")
@inject("webApiClient")
@observer
export default class Logout extends Component<InjectedProps, ILogoutState> {
    static defaultProps = {} as InjectedProps;

    /**
     *
     */
    constructor(props: InjectedProps) {
        super(props);

        this.state = {
            fullName: "Unbekannt"
        }
    }
    componentDidMount() {
        this.FetchUserNameForDisplayAsync();
    }

    render(): ReactNode {
        return (
            <div >
                <span>
                    Eingeloggt als {this.state.fullName}<br />
                    <Link to="#" onClick={this.PerformLogout.bind(this)}>Logout</Link>
                </span>
            </div>
        );
    }

    private PerformLogout(e: MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        this.props.userStore.PerformLogout();
    }

    private async FetchUserNameForDisplayAsync(): Promise<void> {
        try {
            var user = await this.props.webApiClient.GetUserByLoginAsync(this.props.userStore.accessToken, this.props.userStore.userName);
            this.setState({
                ...this.state,
                fullName: `${user.givenName} ${user.surName}`
            })
        }
        catch (error) {
            console.error(`Could not retrieve the current user: ${error}`);
        }
    }
}