/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReadPointCommand } from '../models/CreateReadPointCommand';
import type { ReadPointDto } from '../models/ReadPointDto';
import type { SwapTrackReadPointOrderCommand } from '../models/SwapTrackReadPointOrderCommand';
import type { TrackReadPointAssignmentCommand } from '../models/TrackReadPointAssignmentCommand';
import type { UpdateReadPointCommand } from '../models/UpdateReadPointCommand';
import type { UserDto } from '../models/UserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReadPointsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a new read point.
     * @param requestBody The user data.
     * @returns string Created
     * @throws ApiError
     */
    public readPointscreate(
        requestBody?: CreateReadPointCommand,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/readpoints/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Updates a read point.
     * @param id The id of the read point to update.
     * @param requestBody The data to update.
     * @returns void
     * @throws ApiError
     */
    public readPointsUpdate(
        id: string,
        requestBody?: UpdateReadPointCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.0/readpoints/{id}/update',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Deletes a read point.
     * @param id The id of the read point to delete.
     * @returns void
     * @throws ApiError
     */
    public readPointsDelete(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1.0/readpoints/{id}/delete',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets a user by its id.
     * @param id The id of the user to get.
     * @returns UserDto Success
     * @throws ApiError
     */
    public readPointsGet(
        id: string,
    ): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/readpoints/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Gets a list of all configured read points.
     * @returns ReadPointDto Success
     * @throws ApiError
     */
    public readPointsGetAll(): CancelablePromise<Array<ReadPointDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/readpoints',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Assigns a track to read point.
     * @param requestBody The command.
     * @returns void
     * @throws ApiError
     */
    public readPointsAssign(
        requestBody?: TrackReadPointAssignmentCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/readpoints/assign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Unassigns a track from read point.
     * @param requestBody The command.
     * @returns void
     * @throws ApiError
     */
    public readPointsUnassign(
        requestBody?: TrackReadPointAssignmentCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/readpoints/unassign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Swaps the read point order on a given track.
     * @param requestBody The command.
     * @returns void
     * @throws ApiError
     */
    public readPointsSwapReadPointOrder(
        requestBody?: SwapTrackReadPointOrderCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/readpoints/swap',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
