/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RunningStatisticDto } from '../models/RunningStatisticDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StatisticService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param userId
     * @param startDate
     * @param endDate
     * @returns RunningStatisticDto Success
     * @throws ApiError
     */
    public statisticGetByUserIdFiltered(
        userId: string,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<RunningStatisticDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/Statistic/ByUserId/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'StartDate': startDate,
                'EndDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param userName
     * @param startDate
     * @param endDate
     * @returns RunningStatisticDto Success
     * @throws ApiError
     */
    public statisticGetByUserNameFiltered(
        userName: string,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<RunningStatisticDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/Statistic/ByLogin/{userName}',
            path: {
                'userName': userName,
            },
            query: {
                'StartDate': startDate,
                'EndDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
