import { TextField, PrimaryButton, MessageBar, DefaultButton, MessageBarType, Link } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { CSSProperties, Component, FormEvent, ReactNode } from "react";
import { isValidMailAddress, withRouter } from "../Components/Utils";
import { ApiError, ErrorInfo } from "../generated";
import { InjectedPropsWithRouter } from "./InjectedProps";

interface ResetPasswordState {
    eMailAddress: string | undefined,
    eMailAddressError: string | undefined,
    password: string | undefined,
    passwordConfirm: string | undefined,
    passwordErrorMessage: string | undefined,
    passwordConfirmErrorMessage: string | undefined,
    resetRequested: boolean,
    resetToken: string | undefined,
    resetTokenError: string | undefined,
    valueChanged: boolean,
    saveError: string | undefined,
    changesSaved: boolean,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
class ResetPassword extends Component<InjectedPropsWithRouter, ResetPasswordState> {
    static defaultProps = {} as InjectedPropsWithRouter;
    readonly emailEmptyMessage: string = "Die E-Mail-Adresse muss gefüllt sein";
    readonly passwordEmptyMessage: string = "Das Passwort darf nicht leer sein";
    readonly passwordsDoNotMatch: string = "Die Passwörter stimmen nicht überein";
    readonly tokenEmptyMessage: string = "Das Rücksetztoken darf nicht leer sein";
    readonly passwordTooShort: string = "Die Länge des Passwortes muss mindestens 6 Zeichen sein.";

    /**
     * Initializes a new instance of ResetPassword
     */
    constructor(props: InjectedPropsWithRouter) {
        super(props);

        this.ChangeEMail = this.ChangeEMail.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);
        this.ChangePasswordConfirm = this.ChangePasswordConfirm.bind(this);
        this.ChangeToken = this.ChangeToken.bind(this);
        this.HasErrors = this.HasErrors.bind(this);
        this.ResetErrorMessage = this.ResetErrorMessage.bind(this);
        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);

        var searchParams = new URLSearchParams(props.location.search);
        this.state = {
            eMailAddress: searchParams.get('requester') ?? undefined,
            eMailAddressError: searchParams.get('requester') == null ? this.emailEmptyMessage : undefined,
            passwordErrorMessage: this.passwordEmptyMessage,
            passwordConfirmErrorMessage: this.passwordEmptyMessage,
            password: undefined,
            passwordConfirm: undefined,
            resetRequested: false,
            resetToken: searchParams.get('confirmationToken') ?? undefined,
            resetTokenError: searchParams.get('confirmationToken') == null ? this.tokenEmptyMessage : undefined,
            saveError: undefined,
            valueChanged: false,
            changesSaved: false,
        }
        console.log("ctor");
    }

    render(): ReactNode {
        const documentStyle: CSSProperties = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1em",
            verticalAlign: "middle",
            height: "100vh",
            width: "100%"
        }
                
        return this.state.changesSaved ? <div style={documentStyle}>
            Das Passwort wurde erfolgreich zurückgesetzt.<br />
            Sie können sich mit dem neuen Passwort jetzt <a href="/">anmelden.</a>
        </div>
            :
            <div style={{...documentStyle}}>
                <h2>Passwort zur&uuml;cksetzen</h2>
                <p>
                    <span>Hier k&ouml;nnen Sie Ihr Passwort zur&uuml;cksetzen.</span>
                </p>
                {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
                <div className="divTable" style={{width: "50%"}}>
                    <div className="divTableBody">
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="E-Mail" onChange={this.ChangeEMail} errorMessage={this.state.eMailAddressError} value={this.state.eMailAddress} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Rücksetztoken" onChange={this.ChangeToken} errorMessage={this.state.resetTokenError} value={this.state.resetToken} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required type="password" label="Passwort" onChange={this.ChangePassword} revealPasswordAriaLabel="Show password" canRevealPassword errorMessage={this.state.passwordErrorMessage} value={this.state.password} /></div>
                            <div className="divTableCell"><TextField required type="password" label="Passwort wiederholen" revealPasswordAriaLabel="Show password" canRevealPassword onChange={this.ChangePasswordConfirm} errorMessage={this.state.passwordConfirmErrorMessage} value={this.state.passwordConfirm} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                            <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                        </div>
                    </div>
                </div>
            </div >
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.ResetPassword(this.state.eMailAddress!, this.state.resetToken!.trim(), this.state.password!)
            .then(result => {
                // The user was successfully created, close the create popup and refresh. This works only as a callback.
                this.setState({
                    ...this.state,
                    changesSaved: true
                })
            })
            .catch(apiError => {
                if (apiError.body as ErrorInfo !== null) {
                    var errorInfo = apiError.body as ErrorInfo;
                    if (errorInfo !== null && errorInfo !== undefined) {
                        if ((apiError as ApiError).status === 419) {
                            var error = errorInfo.errors !== null && errorInfo.errors !== undefined
                            ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                            this.setState({
                                ...this.state,
                                saveError: error
                            });
                        }
                        else{
                            this.setState({
                                ...this.state,
                                saveError: "Ein unbekannter Fehler ist aufgetreten",
                            });
                        }                        
                    }
                }
            })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich verwerfen?")) {
            this.setState({
                // ...this.state,
                eMailAddress: undefined,
                eMailAddressError: this.emailEmptyMessage,
                passwordConfirm: undefined,
                passwordErrorMessage: this.passwordEmptyMessage,
                passwordConfirmErrorMessage: this.passwordEmptyMessage,
                resetRequested: false,
                valueChanged: false,
                password: undefined,
            });
            this.props.navigate("/");
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })

    }

    private ChangePassword(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.passwordEmptyMessage : undefined;

        if (errorMessage === undefined) {
            if (newValue !== null && newValue!.length < 6) {
                errorMessage = this.passwordTooShort;
            }
        }

        if (errorMessage === undefined && newValue !== this.state.passwordConfirm) {
            errorMessage = this.passwordsDoNotMatch;
        }

        this.setState({
            ...this.state,
            valueChanged: true,
            password: newValue,
            passwordErrorMessage: errorMessage,
        });
    }

    private ChangePasswordConfirm(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.passwordEmptyMessage : undefined;

        if (errorMessage === undefined && newValue !== null && newValue!.length < 6) {
            errorMessage = this.passwordTooShort;
        }

        var otherError: string | undefined = this.state.passwordErrorMessage;

        if (otherError === undefined || otherError === this.passwordsDoNotMatch) {
            if (newValue !== this.state.password) {
                otherError = this.passwordsDoNotMatch;
            }
            else {
                otherError = undefined;
            }
        }

        this.setState({
            ...this.state,
            valueChanged: true,
            passwordConfirm: newValue,
            passwordErrorMessage: otherError,
            passwordConfirmErrorMessage: errorMessage,
        });
    }

    private ChangeEMail(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.emailEmptyMessage : undefined;
        if (errorMessage === undefined && newValue !== undefined) {
            if (!isValidMailAddress(newValue)) {
                errorMessage = "Keine gültige Mailadresse";
            }
        }

        if (!this.state.eMailAddress || this.state.eMailAddress !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                eMailAddress: newValue,
                eMailAddressError: errorMessage,
            })
        };
    }

    private ChangeToken(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.tokenEmptyMessage : undefined;

        if (!this.state.eMailAddress || this.state.eMailAddress !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                resetToken: newValue,
                resetTokenError: errorMessage,
            })
        };
    }

    private HasErrors(): boolean {
        return this.state.eMailAddressError !== undefined
            || this.state.passwordErrorMessage !== undefined
            || this.state.resetTokenError !== undefined
            || this.state.passwordConfirmErrorMessage !== undefined
    }
}

export default withRouter(ResetPassword);