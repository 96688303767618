/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateAccountCommand } from '../models/ActivateAccountCommand';
import type { CreateUserCommand } from '../models/CreateUserCommand';
import type { DeregisterUserCommand } from '../models/DeregisterUserCommand';
import type { RegisterUserCommand } from '../models/RegisterUserCommand';
import type { UpdateUserCommand } from '../models/UpdateUserCommand';
import type { UserDto } from '../models/UserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a new user.
     * @param requestBody The user data.
     * @returns string Created
     * @throws ApiError
     */
    public usersCreate(
        requestBody?: CreateUserCommand,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/users/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Updates a user.
     * @param id The id of the user to update.
     * @param requestBody The data to update.
     * @returns void
     * @throws ApiError
     */
    public usersUpdate(
        id: string,
        requestBody?: UpdateUserCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.0/users/{id}/update',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Deletes a user.
     * @param id The id of the user to delete.
     * @returns void
     * @throws ApiError
     */
    public usersDelete(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1.0/users/{id}/delete',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets a user by its id.
     * @param id The id of the user to get.
     * @returns UserDto Success
     * @throws ApiError
     */
    public usersGet(
        id: string,
    ): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets a user by its login name.
     * @param userName Name of the user.
     * @returns UserDto Success
     * @throws ApiError
     */
    public usersGetByLogin(
        userName: string,
    ): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/users/ByLogin/{userName}',
            path: {
                'userName': userName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets a list of all users.
     * @returns UserDto Success
     * @throws ApiError
     */
    public usersGetAll(): CancelablePromise<Array<UserDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/users',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Registers the specified user.
     * With this method, a user can self-register himself by using his first and last name and the tag-Id that has been supplied by the issuer.
     *
     * **Note**: as long as the user does not confirm the e-mail-address, it can be re-used by other registrations!
     * @param requestBody The registration request.
     * @returns void
     * @throws ApiError
     */
    public usersRegister(
        requestBody?: RegisterUserCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/users/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
            },
        });
    }

    /**
     * Deregisters the specified user name.
     * @param requestBody Name of the user.
     * @returns void
     * @throws ApiError
     */
    public usersDeregister(
        requestBody?: DeregisterUserCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/users/deregister',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Activates the account.
     * This method allows the confirmation of an e-mail address.
     *
     * The status code **419** will be returned when the token has expired, **400** if anything else is incorrect with the request, like the token is wrong or the address is not available.
     *
     * If the account could be activated successfully, the response is **200** without any body. This will also be returned if the account is already activated.
     * @param requestBody The activate account command.
     * @returns any If this status code is returned, the activation was successful.
     * @throws ApiError
     */
    public usersActiveAccount(
        requestBody?: ActivateAccountCommand,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/users/activate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `If anything is badly formatted, this response will be returned`,
                404: `Will be returned if no matching ***inactive*** account has been found. Registration must be restarted.`,
                419: `Will be returned when the token has expired.`,
            },
        });
    }

}
