import { DefaultEffects, DefaultPalette, DocumentCard, FontSizes, FontWeights, IDocumentCardStyles, ILabelStyles, IRawStyle, IStyle, IStyleSet, Label, Panel, Spinner, Text } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import Carousel from "nuka-carousel";
import { Component, CSSProperties } from "react";
import { PublicDisplayDto, RaceTrackDto } from "../generated";
import { EntityChangedEventArgs, TrackAssignmentToDisplayChangedEventArgs } from "../Services/ISignalRClient";
import { LapInfoCalculatedNotification } from "../Services/LapInfoCalculatedNotification";
import { InjectedProps } from "./InjectedProps";
import Logout from "../Components/Logout";
import { ResettableTimer } from "../Utils/ResettableTimer";
import IframeResizer from "iframe-resizer-react";
const gerbil = require('../images/gerbil_left_noback.png');

interface ExtendedLapInfoCalculation extends LapInfoCalculatedNotification {
    id: string,

    receptionTimeStamp: Date;
}

interface PublicDisplayState {
    /** Defines the information for this public display */
    displayInfo: PublicDisplayDto | undefined;

    /** Gets or sets a flag indicating whether this instance is initializing. */
    isInitializing: boolean;

    /** Gets or sets the track id that is currently displayed. */
    activeTrackId: string;

    lastRunTimes: ExtendedLapInfoCalculation[];

    isLogOutPanelVisible: boolean,

    idleDisplayTarget: string;

    idleTargetDisplayed: boolean;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class PublicDisplay extends Component<InjectedProps, PublicDisplayState>{
    static defaultProps = {} as InjectedProps;
    timer: ResettableTimer | null;

    /**
     * Creates a new instance of the PublicDisplay-Class.
     */
    constructor(props: InjectedProps, state: PublicDisplayState) {
        super(props);
        this.TrackAssignmentToDisplayChanged = this.TrackAssignmentToDisplayChanged.bind(this);
        this.PublicDisplayChanged = this.PublicDisplayChanged.bind(this);

        this.LapTimeReportReceived = this.LapTimeReportReceived.bind(this);
        this.DismissLogoutPanel = this.DismissLogoutPanel.bind(this);
        this.HandleKeyDown = this.HandleKeyDown.bind(this);
        this.IdleTimerElapsed = this.IdleTimerElapsed.bind(this);
    }

    state: Readonly<PublicDisplayState> = {
        displayInfo: undefined,
        isInitializing: true,
        activeTrackId: "",
        isLogOutPanelVisible: false,
        lastRunTimes: [],
        idleDisplayTarget: "https://screensaver.gerbil.run/",
        idleTargetDisplayed: false,
    };

    componentDidMount() {
        console.log("PublicDisplay-UI mounting");
        this.UpdateDisplayInfoAsync();
        this.AttachToSignalR();

        document.addEventListener('keydown', this.HandleKeyDown);
    }

    componentWillUnmount(): void {
        console.log("PublicDisplay-UI unmounting");
        this.DetachFromSignalR();
        document.removeEventListener('keydown', this.HandleKeyDown);
    }

    render() {
        return (
            <div >
                {/* This div renders the normal layout */}
                <div style={{ visibility: this.state.idleTargetDisplayed ? "hidden" : "visible" }}>
                    <img src={String(gerbil)} alt="Gerbil Logo" style={{
                        position: "fixed",
                        right: "7%",
                        width: "10%",
                        bottom: "5%"
                    }} />
                    {this.state.isInitializing ? this.RenderUpdating() : (
                        <div>
                            <Panel
                                isOpen={this.state.isLogOutPanelVisible}
                                onDismiss={this.DismissLogoutPanel}
                                closeButtonAriaLabel="Close"
                                headerText="Abmelden">
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span>
                                        <p>
                                            Wollen Sie wirklich das aktive Display abmelden? Dann klicken Sie auf &quot;Logout&quot;.
                                        </p>
                                        <p>
                                            <Logout />
                                        </p>
                                    </span>
                                </div>
                            </Panel>
                            {this.RenderTrack()}
                        </div>
                    )}
                </div>

                {/* This div renders the commercial overlay.*/}
                <div id="wrap" style={{ visibility: this.state.idleTargetDisplayed ? "visible" : "hidden" }}>
                    <IframeResizer log
                        src={this.state.idleDisplayTarget}
                        style={{ position: "absolute", top: 0, width: '1px', minWidth: '100%', minHeight: '100%' }}
                    />
                </div>
            </div>
        );
    }

    private HandleKeyDown(event: any): void {
        if (event.altKey && event.key === 'c') {
            // Perform your action here when Ctrl+C is pressed
            this.setState({
                ...this.state,
                isLogOutPanelVisible: true,
            })
            // Display something or trigger a function
        }
    }

    private AttachToSignalR(): void {
        this.props.signalRClient.onLapTimeReportedEvent.subscribe(this.LapTimeReportReceived);
        this.props.signalRClient.onTrackAssignmentToDisplayChangedEvent.subscribe(this.TrackAssignmentToDisplayChanged);
        this.props.signalRClient.onPublicDisplayModified.subscribe(this.PublicDisplayChanged);
    }

    private DetachFromSignalR(): void {
        this.props.signalRClient.onLapTimeReportedEvent.unsubscribe(this.LapTimeReportReceived);
        this.props.signalRClient.onTrackAssignmentToDisplayChangedEvent.unsubscribe(this.TrackAssignmentToDisplayChanged);
        this.props.signalRClient.onPublicDisplayModified.unsubscribe(this.PublicDisplayChanged);
    }

    private PublicDisplayChanged(publicDisplayChanged: EntityChangedEventArgs) {
        if (publicDisplayChanged.id == this.state.displayInfo?.id) {
            this.UpdateDisplayInfoAsync();
        }
    }

    private TrackAssignmentToDisplayChanged(trackAssignmentToDisplayChanged: TrackAssignmentToDisplayChangedEventArgs) {
        if (this.state.displayInfo?.id === trackAssignmentToDisplayChanged.displayId) {
            this.HandleTrackAssignmentChangeAsync(trackAssignmentToDisplayChanged.trackId, trackAssignmentToDisplayChanged.wasAssigned);
        }
    }

    // private PublicDisplayChanged(publicDisplayChangedArgs: EntityChangedEventArgs) {
    //     if (this.state.displayInfo?.id === publicDisplayChangedArgs..displayId) {

    // }

    private async HandleTrackAssignmentChangeAsync(trackId: string, wasAssigned: boolean): Promise<void> {
        // It concerns this display.
        var oldTrackInfo = this.state.displayInfo!.onTracks;
        if (oldTrackInfo === null || oldTrackInfo === undefined) {
            oldTrackInfo = [];
        }

        if (wasAssigned) {
            // Add newly assigned track
            var trackInfo = await this.props.webApiClient.GetTrackInfo(this.props.userStore.accessToken, trackId);

            oldTrackInfo.push(trackInfo);
            this.setState({ ...this.state });
            // this.setState({ ...this.state, displayInfo =  })
        }
        else {
            // Remove the unassigned track
            var itemToDelete = oldTrackInfo.findIndex(value => value.id === trackId);

            if (itemToDelete !== -1) {
                oldTrackInfo.splice(itemToDelete, 1);
                this.setState({ ...this.state });
            }
        }
    }

    private LapTimeReportReceived(report: LapInfoCalculatedNotification): void {
        var currentRunTimes = this.state.lastRunTimes;

        // if (report.timeSinceStart !== 0) {
            console.log(`Received a new lap timing for runner ${report.runnerName}`);
            var newId = this.uuidv4();
            currentRunTimes.push({
                id: newId,
                lapTime: report.lapTime,
                timeSinceStart: report.timeSinceStart,
                receptionTimeStamp: new Date(),
                runnerName: report.runnerName,
                trackId: report.trackId
            });

            setTimeout(() => {
                const filteredArray = this.state.lastRunTimes.filter(obj => obj.id !== newId);

                this.setState({
                    ...this.state,
                    lastRunTimes: filteredArray,
                    idleTargetDisplayed: false,
                })
            }, window.retentionPeriodMinutes === undefined ? 5 * 60_000 : window.retentionPeriodMinutes * 60_000);

            this.setState({
                ...this.state,
                lastRunTimes: currentRunTimes,
                idleTargetDisplayed: false,
            });

            this.timer?.start();        
    }

    uuidv4(): string {
        // @ts-ignore  
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            // tslint:disable-next-line:no-bitwise  
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    private RenderUpdating(): JSX.Element {
        return (<Spinner />);
    }

    private toHHMMSS(secNum: number): string {
        let hours: number = Math.floor(secNum / 3600);
        let minutes: number = Math.floor((secNum - (hours * 3600)) / 60);
        let seconds: number = secNum - (hours * 3600) - (minutes * 60);
        return hours == 0 ?
            `${minutes.toString().padStart(2, '0')}:${seconds.toFixed(0).toString().padStart(2, '0')}` :
            `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toFixed(0).toString().padStart(2, '0')}`;
    }

    private RenderTrack(): JSX.Element {
        var trackArray: JSX.Element[] = [];

        if (this.state.displayInfo !== null && this.state.displayInfo !== undefined
            && this.state.displayInfo.onTracks !== null && this.state.displayInfo.onTracks !== undefined) {
            for (let index = 0; index < this.state.displayInfo.onTracks.length; index++) {
                const element = this.state.displayInfo.onTracks[index];
                trackArray.push(this.RenderTrackCard(element));
            }
        }
        return (
            <Carousel
                autoplay
                autoplayInterval={window.trackRotationDurationSeconds === undefined ? 5000 : window.trackRotationDurationSeconds * 1000}
                wrapAround
                disableEdgeSwiping
                dragging={false}
                pauseOnHover={false}
                withoutControls={true}
                onDragEnd={() => console.log("Drag")}
            >
                {trackArray}
            </Carousel>);

    }

    private RenderTrackCard(raceTrack: RaceTrackDto): JSX.Element {
        const pageStyle: CSSProperties = {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1em",
            margin: "10pt 10pt 10pt 10pt",
            padding: "0.5cm 0cm 0cm 0cm"
        };

        const cardStyle: Partial<IStyleSet<IDocumentCardStyles>> = {
            root: {
                padding: "2pt 2pt 2pt 2pt",
                borderRadius: DefaultEffects.roundedCorner6,
                background: DefaultPalette.white,
                boxShadow: DefaultEffects.elevation4
            }
        }

        const cardLabelStyle: Partial<IStyleSet<ILabelStyles>> =
        {
            root: {
                // color: DefaultPalette.neutralPrimary,
                fontWeight: FontWeights.regular,
                fontSize: FontSizes.medium,
                color: DefaultPalette.black,
            }
        };

        // const activeTrack: RaceTrackDto | undefined = this.state.displayInfo?.onTracks?.filter(p => p.id == this.state.activeTrackId)[0];

        var runTimeInfoRenderings: JSX.Element[] = [];
        for (var i = 0; i < this.state.lastRunTimes.sort((a, b) => {
            if (a.receptionTimeStamp > b.receptionTimeStamp) {
                return -1
            }
            else if (a.receptionTimeStamp == b.receptionTimeStamp) {
                return 0
            }
            else {
                return 1;
            }
        }).length; i++) {
            if (this.state.lastRunTimes[i].trackId == raceTrack.id) {
                runTimeInfoRenderings.push(this.RenderLapTime(this.state.lastRunTimes[i]));
            }
        }

        return (
            <div style={pageStyle} id={raceTrack.id!}>
                <div>
                    <DocumentCard aria-label="Trackinfo" styles={cardStyle}>
                        <div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
                            <Label styles={cardLabelStyle}>{raceTrack.trackName}</Label>
                            <Label styles={cardLabelStyle}>Länge: {raceTrack.lengthInM}m</Label>
                        </div>
                    </DocumentCard>
                </div>
                <div style={{ width: "100vw", height: "350px" }}>
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "50%" }}>Name</th>
                                <th style={{ width: "25%", textAlign: "center" }}>Letzte</th>
                                <th style={{ width: "25%", textAlign: "center" }}>Gesamtzeit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {runTimeInfoRenderings}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    private DismissLogoutPanel(event: any): void {
        this.setState({
            ...this.state,
            isLogOutPanelVisible: false
        })
    }

    private RenderLapTime(time: LapInfoCalculatedNotification): JSX.Element {
        const runnerNameStyle: CSSProperties = {
            fontSize: FontSizes.medium,
            color: DefaultPalette.themeLight,
        }

        const runTimeStyle: CSSProperties = {
            fontSize: FontSizes.smallPlus,
            color: DefaultPalette.themeLighter,
            textAlign: "center"
        }

        return (
            <tr>
                <td style={runnerNameStyle}>{time.runnerName}</td>
                {time.lapTime == 0 ? <td style={runTimeStyle}>---</td> : <td style={runTimeStyle}>{(this.toHHMMSS(time.lapTime / 1000))}</td>}
                {time.timeSinceStart == 0 ? <td style={runTimeStyle}>Lauf gestartet</td> : <td style={runTimeStyle}>{(this.toHHMMSS(time.timeSinceStart / 1000))}</td>}
            </tr>
        );
    }

    private IdleTimerElapsed(): void {
        console.log("Displaying ads");
        this.setState({
            ...this.state,
            idleTargetDisplayed: true,
        })
    }
    private async UpdateDisplayInfoAsync(): Promise<void> {
        try {
            // Updating only a part of the state works like this: this.setState({...this.state, isOpen: true}); (https://stackoverflow.com/a/45557794/14842155)
            if (this.props.userStore.userName !== undefined) {
                var info = await this.props.webApiClient.GetPublicDisplayInfoByLogin(this.props.userStore.accessToken, this.props.userStore.userName);
                var trackId = (info.onTracks !== null && info.onTracks !== undefined) ? info.onTracks[0].id! : "";

                if (this.timer != null) {
                    this.timer.reset();
                    this.timer = null;
                }

                if (info.displayIdleTimeSeconds != 0) {
                    console.log(`Setting the idle timer to ${info.displayIdleTimeSeconds} seconds`);
                    this.timer = new ResettableTimer((info.displayIdleTimeSeconds ?? 600) * 1000, () => this.IdleTimerElapsed());

                    this.timer.start();
                } else {
                    console.log("The marketing display is disabled.");
                }

                this.setState({
                    displayInfo: info,
                    isInitializing: false,
                    activeTrackId: trackId,
                    idleDisplayTarget: info.idleDisplayTarget ?? "https://www.tagitron.de",
                });
            }
        }
        catch (error) {
            console.error(`Could not request the information about the display, error message was ${error.message}`);
        }
    }
}