import { ActionButton, ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, INavLink, INavLinkGroup, INavStyles, Label, Nav, PrimaryButton, SelectionMode, TooltipHost } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { InjectedProps } from "../InjectedProps";
import { LogMessageEventArgs } from "../../Services/ISignalRClient";

interface LiveLogState {
    messages: LogMessageEventArgs[];
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class LiveLog extends Component<InjectedProps, LiveLogState> {
    static defaultProps = {} as InjectedProps;

    /**
     *
     */
    constructor(props: InjectedProps) {
        super(props);

        this.state = {
            messages: []
        };

        this.LogMessageReceived = this.LogMessageReceived.bind(this);
        this.ClearLog = this.ClearLog.bind(this);
    }
    componentDidMount(): void {
        this.DetachFromSignalR();
        this.AttachToSignalR();
    }

    componentWillUnmount(): void {
        this.DetachFromSignalR();
    }

    private DetachFromSignalR(): void {
        this.props.signalRClient.onLogMessage.unsubscribe(this.LogMessageReceived);
    }

    private AttachToSignalR(): void {
        this.props.signalRClient.onLogMessage.subscribe(this.LogMessageReceived);
    }

    LogMessageReceived(logMessage: LogMessageEventArgs) {
        var currentMessages = this.state.messages;
        currentMessages.push(logMessage);
        this.setState({
            ...this.state,
            messages: currentMessages.sort((a, b) => {
                if (a.occurrenceTimeStamp > b.occurrenceTimeStamp) {
                    return -1;
                }
                else if (a.occurrenceTimeStamp == b.occurrenceTimeStamp) {
                    return 0;
                }
                else {
                    return 1;
                }
            })
        });
    }

    render(): ReactNode {
        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Zeitstempel',
                fieldName: 'occurrenceTimeStamp',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                // onColumnClick: this._onColumnClick,
                data: 'Date',
                onRender: (item: LogMessageEventArgs) => (
                    <div>
                        <Label>{`${new Date(item.occurrenceTimeStamp).toLocaleDateString("de-DE")} ${new Date(item.occurrenceTimeStamp).toLocaleTimeString("de-DE",
                            {
                                hour: "2-digit",
                                minute: "2-digit",
                                fractionalSecondDigits: 3
                            })}`}</Label>
                    </div>
                ),
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Eventquelle',
                fieldName: 'category',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                // onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Level',
                fieldName: 'logLevel',
                minWidth: 100,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                // onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Message',
                fieldName: 'message',
                minWidth: 210,
                isRowHeader: true,
                isResizable: true,
                // onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: LogMessageEventArgs) => {
                    return (<TooltipHost content={item.message}>
                        <span><em>{item.message}</em></span>
                    </TooltipHost>)
                }
            }]


        return (
            <div id="logList" style={{ maxHeight: 500, overflowY: "auto", display: "flex", flexWrap: "wrap", width: "100%" }}>
                <DetailsList
                    styles={{
                        focusZone: {
                            width: "100%"
                        },
                        headerWrapper: {
                            width: "100%"
                        }

                    }}
                    constrainMode={ConstrainMode.unconstrained}
                    items={this.state.messages}
                    compact={true}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    setKey="multiple"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selectionPreservedOnEmptyClick={true}
                />
                <PrimaryButton text="Löschen" style={{ margin: "10px" }} onClick={() => this.ClearLog()}></PrimaryButton>

            </div>
        );
    }

    private ClearLog(): void {
        this.setState({
            ...this.state,
            messages: []
        })
    }
}