/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MainUserRole {
    UNDEFINED = 'Undefined',
    USER = 'User',
    READ_POINT = 'ReadPoint',
    PUBLIC_DISPLAY = 'PublicDisplay',
}
