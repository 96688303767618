import { DefaultButton, IStackProps, IStackStyles, MessageBar, MessageBarType, PrimaryButton, TagItemSuggestion, TextField, Toggle } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent, ReactNode } from "react";
import { toast } from "react-toastify";
import { isValidMailAddress } from "../../Components/Utils";
import { ApiError, ErrorInfo, UserDto } from "../../generated";
import { InjectedProps } from "../InjectedProps";


interface CreateUserState {
    displayName: string | undefined;
    givenName: string | undefined;
    surName: string | undefined;
    eMail: string | undefined;
    login: string | undefined;
    password: string | undefined;
    valueChanged: boolean;
    hasAdminRights: boolean;
    mustChangePassword: boolean;
    saveError: string | undefined;

    displayNameErrorMessage: string | undefined;
    givenNameErrorMessage: string | undefined,
    surNameErrorMessage: string | undefined,
    eMailErrorMessage: string | undefined,
    loginErrorMessage: string | undefined,
    passwordErrorMessage: string | undefined
}

interface CreateUserProps extends InjectedProps {
    onCreated: () => void | undefined;
    onCancel: () => void | undefined;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class CreateUser extends Component<CreateUserProps, CreateUserState>{
    static defaultProps = {} as CreateUserProps;

    readonly displayNameEmptyMessage: string = "Der Anzeigename muss gefüllt sein.";
    readonly emailEmptyMessage: string = "Die E-Mail-Adresse muss gefüllt sein";
    readonly surNameEmptyMessage: string = "Der Nachname muss gefüllt sein";
    readonly givenNameEmptyMessage: string = "Der Vorname muss gefüllt sein";
    readonly loginEmptyMessage: string = "Das Login muss gefüllt sein";
    readonly passwordEmptyMessage: string = "Das Password muss gefüllt sein";

    /**
     * Initializes a new instance of CreateUser.
     */
    constructor(props: CreateUserProps) {
        super(props);

        this.ChangeGivenName = this.ChangeGivenName.bind(this);
        this.ChangeSurName = this.ChangeSurName.bind(this);
        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.ChangeEMail = this.ChangeEMail.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);
        this.ChangeDisplayName = this.ChangeDisplayName.bind(this);
        this.ChangeLogin = this.ChangeLogin.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);
        this.HasErrors = this.HasErrors.bind(this);
        this.ChangeHasAdminRights = this.ChangeHasAdminRights.bind(this);
        this.ChangeMustChangePassword = this.ChangeMustChangePassword.bind(this);

        this.state = {
            displayName: undefined,
            displayNameErrorMessage: this.displayNameEmptyMessage,
            eMail: undefined,
            eMailErrorMessage: this.emailEmptyMessage,
            givenName: undefined,
            givenNameErrorMessage: this.givenNameEmptyMessage,
            hasAdminRights: false,
            surName: undefined,
            surNameErrorMessage: this.surNameEmptyMessage,
            login: undefined,
            loginErrorMessage: this.loginEmptyMessage,
            password: undefined,
            passwordErrorMessage: this.passwordEmptyMessage,
            valueChanged: false,
            saveError: undefined,
            mustChangePassword: false
        }
    }
    render() {
        return (
            <div>
                <h2>Benutzer erstellen</h2>
                <p>
                    <span>Hier k&ouml;nnen Sie einen neuen Benutzer anlegen. Bitte beachten Sie, dass Sie hier auch ein Passwort vergeben m&uuml;ssen!</span>
                </p>
                {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
                <div className="divTable">
                    <div className="divTableBody">
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Anzeigename" onChange={this.ChangeDisplayName} errorMessage={this.state.displayNameErrorMessage} value={this.state.displayName} /></div>
                            <div></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Vorname" onChange={this.ChangeGivenName} errorMessage={this.state.givenNameErrorMessage} value={this.state.givenName} /></div>
                            <div className="divTableCell"><TextField required label="Nachname" onChange={this.ChangeSurName} errorMessage={this.state.surNameErrorMessage} value={this.state.surName} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="E-Mail" onChange={this.ChangeEMail} errorMessage={this.state.eMailErrorMessage} value={this.state.eMail} /></div>
                            <div className="divTableCell"><Toggle label="Hat Administratorrechte?" onChange={this.ChangeHasAdminRights} checked={this.state.hasAdminRights} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Login" onChange={this.ChangeLogin} errorMessage={this.state.loginErrorMessage} value={this.state.login} /></div>
                            <div className="divTableCell"><TextField required label="Passwort (min. 6 Zeichen)" type="password" canRevealPassword revealPasswordAriaLabel="Show password" onChange={this.ChangePassword} errorMessage={this.state.passwordErrorMessage} value={this.state.password} /></div>
                        </div>

                        <div className="divTableRow">
                            <div className="divTableCell">&nbsp;</div>
                            <div className="divTableCell"><Toggle label="Muss Passwort beim ersten Login ändern?" onChange={this.ChangeMustChangePassword} checked={this.state.mustChangePassword} /></div>
                        </div>

                        <div className="divTableRow">
                            <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                            <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.CreateUser(this.props.userStore.accessToken,
            this.state.displayName!,
            this.state.eMail!,
            this.state.login!,
            this.state.password!,
            this.state.givenName === undefined ? null : this.state.givenName,
            this.state.surName === undefined ? null : this.state.surName,
            this.state.hasAdminRights,
            this.state.mustChangePassword)
            .then(result => {
                // The user was successfully created, close the create popup and refresh. This works only as a callback.
                if (this.props.onCreated !== undefined) {
                    this.props.onCreated();
                }
            })
            .catch(apiError => {
                if (apiError.body as ErrorInfo !== null){
                    var errorInfo = apiError.body as ErrorInfo;
                    var error = errorInfo.errors !== null && errorInfo.errors !== undefined
                    ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    this.setState({
                        ...this.state,
                        saveError: error
                    });
                }
            })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich verwerfen?")) {
            this.setState({
                // ...this.state,
                eMail: undefined,
                givenName: undefined,
                surName: undefined,
                displayName: "sdlfgh",
                valueChanged: false,
                login: undefined,
                password: undefined,
            });

            if (this.props.onCancel !== undefined){
                this.props.onCancel();
            }
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private HasErrors(): boolean {
        return this.state.displayNameErrorMessage !== undefined
            || this.state.givenNameErrorMessage !== undefined
            || this.state.surNameErrorMessage !== undefined
            || this.state.eMailErrorMessage !== undefined
            || this.state.loginErrorMessage !== undefined
            || this.state.passwordErrorMessage !== undefined
    }

    private ChangeGivenName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Vorname muss gefüllt sein" : undefined;
        if (this.state.givenName !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                givenName: newValue,
                givenNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeLogin(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.loginEmptyMessage : undefined;
        if (this.state.login !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                login: newValue,
                loginErrorMessage: errorMessage,
            })
        };
    }

    private ChangePassword(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.passwordEmptyMessage : undefined;

        if (errorMessage === undefined) {
            if (newValue !== null && newValue!.length < 6) {
                errorMessage = "Die Länge des Passwortes muss mindestens 6 Zeichen sein.";
            }
        }
        if (this.state.password !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                password: newValue,
                passwordErrorMessage: errorMessage,
            })
        };
    }

    private ChangeDisplayName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Anzeigename muss gefüllt sein" : undefined;
        if (this.state.displayName !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                displayName: newValue,
                displayNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeSurName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Nachname muss gefüllt sein" : undefined;
        if (this.state.surName !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                surName: newValue,
                surNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeEMail(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.emailEmptyMessage : undefined;
        if (errorMessage === undefined && newValue !== undefined) {
            if (!isValidMailAddress(newValue)) {
                errorMessage = "Keine gültige Mailadresse";
            }
        }

        if (!this.state.eMail || this.state.eMail !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                eMail: newValue,
                eMailErrorMessage: errorMessage,
            })
        };
    }

    private ChangeHasAdminRights(event: any, checked?: boolean | undefined): void {
        // TODO: Change the parameter of event from any to the correct type.
        console.log("Check changed?");
        if (checked !== undefined) {
            this.setState({
                ...this.state,
                hasAdminRights: checked
            })
        }
    }

    private ChangeMustChangePassword(event: any, checked?: boolean | undefined): void {
        console.log("check changes.");
        if (checked !== undefined) {
            this.setState({
                ...this.state,
                mustChangePassword: checked
            })
        }
    }
}
