import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 129.924 36.226"
    {...props}
  >
    <defs>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(180 95.269 41.88) scale(36.57658)"
        spreadMethod="pad"
        id="a"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.016}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.203}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#931913",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-36.57447 0 0 -36.57447 190.536 111.168)"
        spreadMethod="pad"
        id="b"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.016}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.203}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#931913",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-36.57476 0 0 -36.57476 190.536 94.214)"
        spreadMethod="pad"
        id="c"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.016}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.203}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#931913",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-36.57085 0 0 -36.57085 190.533 104.722)"
        spreadMethod="pad"
        id="d"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.016}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.203}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#931913",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-36.5708 0 0 -36.5708 190.535 72.91)"
        spreadMethod="pad"
        id="e"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.016}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.203}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#931913",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(84.35654 -84.35654) rotate(-80 .8 -1.58)"
        spreadMethod="pad"
        id="f"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.016}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#cf2b20",
          }}
          offset={0.203}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#931913",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="rotate(180 95.269 41.88) scale(36.57658)"
        gradientUnits="userSpaceOnUse"
        id="i"
        xlinkHref="#a"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="matrix(-36.57447 0 0 -36.57447 190.536 111.168)"
        gradientUnits="userSpaceOnUse"
        id="k"
        xlinkHref="#b"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="matrix(-36.57476 0 0 -36.57476 190.536 94.214)"
        gradientUnits="userSpaceOnUse"
        id="m"
        xlinkHref="#c"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="matrix(-36.57085 0 0 -36.57085 190.533 104.722)"
        gradientUnits="userSpaceOnUse"
        id="o"
        xlinkHref="#d"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="matrix(-36.5708 0 0 -36.5708 190.535 72.91)"
        gradientUnits="userSpaceOnUse"
        id="q"
        xlinkHref="#e"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="scale(84.35654 -84.35654) rotate(-80 .8 -1.58)"
        gradientUnits="userSpaceOnUse"
        id="s"
        xlinkHref="#f"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="rotate(180 95.269 41.88) scale(36.57658)"
        gradientUnits="userSpaceOnUse"
        id="v"
        xlinkHref="#a"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="matrix(-36.57447 0 0 -36.57447 190.536 111.168)"
        gradientUnits="userSpaceOnUse"
        id="x"
        xlinkHref="#b"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="matrix(-36.57476 0 0 -36.57476 190.536 94.214)"
        gradientUnits="userSpaceOnUse"
        id="z"
        xlinkHref="#c"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="matrix(-36.57085 0 0 -36.57085 190.533 104.722)"
        gradientUnits="userSpaceOnUse"
        id="B"
        xlinkHref="#d"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="matrix(-36.5708 0 0 -36.5708 190.535 72.91)"
        gradientUnits="userSpaceOnUse"
        id="D"
        xlinkHref="#e"
      />
      <linearGradient
        spreadMethod="pad"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
        gradientTransform="scale(84.35654 -84.35654) rotate(-80 .8 -1.58)"
        gradientUnits="userSpaceOnUse"
        id="F"
        xlinkHref="#f"
      />
      <clipPath clipPathUnits="userSpaceOnUse" id="g">
        <path d="M0 148h425.2V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="h">
        <path d="M158.27 78.84h9.84v9.837h-9.84z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="j">
        <path d="M164.22 106.249h9.835v9.837h-9.835z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="l">
        <path d="M169.892 91.081h6.268v6.266h-6.268z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="n">
        <path d="M152.004 101.588h6.266v6.268h-6.266z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="p">
        <path d="M173.026 69.775h6.266v6.268h-6.266z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="r">
        <path d="M177.118 124.976v-9.616l8.97-1.639v-3.325h5.796V99.743h-5.796V70.871h4.742v-6.244h-4.742v-7.996l-8.97-1.639v-9.561h34.671v9.561l-8.969 1.639v57.09l8.969 1.639v9.616zm12.48-29.029h6.208V89.74h-6.208zm2.142-12.03h8.902v-8.903h-8.902z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="t">
        <path d="M0 148h425.2V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="u">
        <path d="M158.27 78.84h9.84v9.837h-9.84z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="w">
        <path d="M164.22 106.249h9.835v9.837h-9.835z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="y">
        <path d="M169.892 91.081h6.268v6.266h-6.268z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="A">
        <path d="M152.004 101.588h6.266v6.268h-6.266z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="C">
        <path d="M173.026 69.775h6.266v6.268h-6.266z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="E">
        <path d="M177.118 124.976v-9.616l8.97-1.639v-3.325h5.796V99.743h-5.796V70.871h4.742v-6.244h-4.742v-7.996l-8.97-1.639v-9.561h34.671v9.561l-8.969 1.639v57.09l8.969 1.639v9.616zm12.48-29.029h6.208V89.74h-6.208zm2.142-12.03h8.902v-8.903h-8.902z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="G">
        <path d="M0 148h425.2V0H0Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#g)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M0 0v-12.324h10.589v-4.36H0V-48.05c0-3.144.571-5.376 1.713-6.696 1.142-1.32 2.662-1.979 4.56-1.979.861 0 1.698.036 2.514.111.816.074 1.757.2 2.825.378l.801-3.96c-.89-.385-1.973-.681-3.248-.889a23.732 23.732 0 0 0-3.826-.312c-3.381 0-6.013 1.068-7.897 3.203-1.884 2.136-2.825 5.517-2.825 10.144v31.366h-8.276v4.36h8.276V0Z"
        style={{
          fill: "#371218",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(42.38 105.27)"
      />
      <path
        d="M0 0c3.856 0 7.252.89 10.188 2.669 2.937 1.78 5.057 4.078 6.362 6.896v9.655H5.828c-4.834 0-8.698-1.001-11.59-3.003-2.891-2.002-4.337-4.531-4.337-7.586 0-2.58.897-4.664 2.691-6.251C-5.613.793-3.144 0 0 0m17.529-3.782c-.356 1.631-.607 3.04-.756 4.227a28.881 28.881 0 0 0-.223 3.604c-1.779-2.522-4.152-4.613-7.118-6.274-2.966-1.66-6.303-2.491-10.01-2.491-4.716 0-8.365 1.215-10.945 3.648-2.58 2.432-3.871 5.724-3.871 9.877 0 4.449 1.913 7.978 5.74 10.589 3.826 2.61 9.016 3.915 15.571 3.915H16.55v6.362c0 3.44-1.105 6.14-3.314 8.097-2.209 1.958-5.302 2.937-9.276 2.937-2.67 0-5.11-.364-7.319-1.09-2.209-.727-4.056-1.713-5.539-2.959l-.445-6.451h-4.405l.045 9.254c2.224 1.72 4.887 3.114 7.986 4.182 3.099 1.068 6.399 1.602 9.899 1.602 5.31 0 9.581-1.35 12.813-4.049 3.233-2.699 4.85-6.57 4.85-11.612V5.917c0-.979.022-1.921.067-2.825.044-.905.14-1.788.289-2.647l4.805-.312v-3.915z"
        style={{
          fill: "#371218",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(78.46 48.59)"
      />
      <path
        d="M0 0c0-5.784 1.179-10.404 3.537-13.859 2.358-3.456 5.88-5.183 10.566-5.183 3.471 0 6.355.786 8.654 2.358 2.298 1.571 4.071 3.737 5.317 6.496v22.334c-1.187 2.817-2.892 5.108-5.117 6.874-2.224 1.764-5.146 2.647-8.765 2.647-4.686 0-8.223-1.928-10.611-5.784C1.194 12.027 0 7.044 0 .934Zm-5.339.934c0 7.682 1.639 13.829 4.916 18.442 3.278 4.611 7.824 6.918 13.637 6.918 3.41 0 6.369-.749 8.876-2.247 2.506-1.498 4.575-3.612 6.206-6.34l.623 7.697h4.493v-47.516c0-6.198-1.675-11.092-5.027-14.682-3.352-3.588-8.023-5.383-14.015-5.383-2.313 0-4.782.282-7.407.845-2.625.564-4.916 1.335-6.874 2.314l1.424 4.493a30.409 30.409 0 0 1 6.073-2.135c2.12-.504 4.353-.757 6.695-.757 4.568 0 8.009 1.35 10.322 4.049 2.314 2.7 3.471 6.451 3.471 11.256v6.095c-1.632-2.462-3.693-4.353-6.185-5.672-2.491-1.32-5.412-1.98-8.764-1.98-5.784 0-10.307 2.15-13.57 6.451S-5.339-7.178-5.339 0z"
        style={{
          fill: "#371218",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(118.591 67.543)"
      />
      <path
        d="M0 0v-11.79h8.453v-9.121H0v-24.514c0-1.868.385-3.203 1.157-4.004.77-.801 1.809-1.201 3.114-1.201.89 0 1.668.036 2.336.111.667.074 1.416.2 2.247.378l1.112-9.387a31.125 31.125 0 0 0-4.316-1.001c-1.423-.223-2.966-.334-4.627-.334-4.479 0-7.927 1.231-10.344 3.693-2.417 2.461-3.626 6.362-3.626 11.701v24.558h-7.074v9.121h7.074V0Z"
        style={{
          fill: "#371218",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(239.298 104.737)"
      />
      <path
        d="m0 0 6.229 1.335v29.853l-6.896 1.335v7.83H18.33l.578-7.03c1.127 2.492 2.581 4.434 4.361 5.829 1.779 1.393 3.84 2.091 6.184 2.091.681 0 1.401-.053 2.158-.156a13.28 13.28 0 0 0 1.89-.378l-1.379-11.835-5.339.134c-1.899 0-3.47-.371-4.716-1.112-1.245-.743-2.195-1.795-2.847-3.159V1.335L25.449 0v-7.786H0Z"
        style={{
          fill: "#371218",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(255.092 52.594)"
      />
      <path
        d="M0 0c0-4.39.771-7.912 2.314-10.566 1.542-2.656 4.033-3.982 7.474-3.982 3.351 0 5.806 1.334 7.363 4.004C18.708-7.875 19.487-4.36 19.487 0v.934c0 4.271-.787 7.756-2.358 10.456-1.572 2.698-4.049 4.048-7.43 4.048-3.381 0-5.843-1.35-7.385-4.048C.771 8.69 0 5.205 0 .934Zm-12.991.934c0 7.177 2.009 13.058 6.028 17.641 4.019 4.582 9.573 6.874 16.662 6.874 7.118 0 12.687-2.285 16.706-6.852 4.018-4.568 6.029-10.455 6.029-17.663V0c0-7.238-2.011-13.133-6.029-17.685-4.019-4.553-9.558-6.829-16.617-6.829-7.149 0-12.732 2.276-16.751 6.829C-10.982-13.133-12.991-7.238-12.991 0z"
        style={{
          fill: "#371218",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(304.566 68.388)"
      />
      <path
        d="m0 0 6.229 1.335v29.853l-6.896 1.335v7.83H18.33l.578-6.896c1.602 2.461 3.574 4.374 5.918 5.739 2.343 1.364 4.967 2.047 7.875 2.047 4.863 0 8.66-1.528 11.389-4.583 2.728-3.055 4.093-7.845 4.093-14.37V1.335L54.412 0v-7.786H29.586V0l5.561 1.335v20.91c0 3.233-.653 5.524-1.957 6.874-1.305 1.349-3.278 2.024-5.917 2.024-1.721 0-3.263-.348-4.627-1.045a9.517 9.517 0 0 1-3.426-2.959V1.335L24.47 0v-7.786H0Z"
        style={{
          fill: "#371218",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(341.938 52.594)"
      />
    </g>
    <g clipPath="url(#h)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M158.27 78.84h9.84v9.837h-9.84z"
        style={{
          fill: "url(#i)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#j)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M164.22 106.249h9.835v9.837h-9.835z"
        style={{
          fill: "url(#k)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#l)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M169.892 91.081h6.268v6.266h-6.268z"
        style={{
          fill: "url(#m)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#n)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M152.004 101.588h6.266v6.268h-6.266z"
        style={{
          fill: "url(#o)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#p)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M173.026 69.775h6.266v6.268h-6.266z"
        style={{
          fill: "url(#q)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#r)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M177.118 124.976v-9.616l8.97-1.639v-3.325h5.796V99.743h-5.796V70.871h4.742v-6.244h-4.742v-7.996l-8.97-1.639v-9.561h34.671v9.561l-8.969 1.639v57.09l8.969 1.639v9.616zm12.48-29.029h6.208V89.74h-6.208zm2.142-12.03h8.902v-8.903h-8.902z"
        style={{
          fill: "url(#s)",
          stroke: "none",
        }}
      />
    </g>
    <text
      transform="matrix(.35278 0 0 .35278 83.078 35.03)"
      style={{
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: "15.9px",
        fontFamily: "'Roboto Slab'",
        writingMode: "horizontal-tb",
        fill: "white",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    >
      <tspan
        x="0 13.1493 25.424101 32.944801 46.348499 54.918598 66.986702 78.387001 88.880997 97.085403 108.0246 125.4351"
        y={0}
      >
        {"RFID-Systems"}
      </tspan>
    </text>
    <g clipPath="url(#t)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M0 0v-12.324h10.589v-4.36H0V-48.05c0-3.144.571-5.376 1.713-6.696 1.142-1.32 2.662-1.979 4.56-1.979.861 0 1.698.036 2.514.111.816.074 1.757.2 2.825.378l.801-3.96c-.89-.385-1.973-.681-3.248-.889a23.732 23.732 0 0 0-3.826-.312c-3.381 0-6.013 1.068-7.897 3.203-1.884 2.136-2.825 5.517-2.825 10.144v31.366h-8.276v4.36h8.276V0Z"
        style={{
          fill: "white",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(42.38 105.27)"
      />
      <path
        d="M0 0c3.856 0 7.252.89 10.188 2.669 2.937 1.78 5.057 4.078 6.362 6.896v9.655H5.828c-4.834 0-8.698-1.001-11.59-3.003-2.891-2.002-4.337-4.531-4.337-7.586 0-2.58.897-4.664 2.691-6.251C-5.613.793-3.144 0 0 0m17.529-3.782c-.356 1.631-.607 3.04-.756 4.227a28.881 28.881 0 0 0-.223 3.604c-1.779-2.522-4.152-4.613-7.118-6.274-2.966-1.66-6.303-2.491-10.01-2.491-4.716 0-8.365 1.215-10.945 3.648-2.58 2.432-3.871 5.724-3.871 9.877 0 4.449 1.913 7.978 5.74 10.589 3.826 2.61 9.016 3.915 15.571 3.915H16.55v6.362c0 3.44-1.105 6.14-3.314 8.097-2.209 1.958-5.302 2.937-9.276 2.937-2.67 0-5.11-.364-7.319-1.09-2.209-.727-4.056-1.713-5.539-2.959l-.445-6.451h-4.405l.045 9.254c2.224 1.72 4.887 3.114 7.986 4.182 3.099 1.068 6.399 1.602 9.899 1.602 5.31 0 9.581-1.35 12.813-4.049 3.233-2.699 4.85-6.57 4.85-11.612V5.917c0-.979.022-1.921.067-2.825.044-.905.14-1.788.289-2.647l4.805-.312v-3.915z"
        style={{
          fill: "white",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(78.46 48.59)"
      />
      <path
        d="M0 0c0-5.784 1.179-10.404 3.537-13.859 2.358-3.456 5.88-5.183 10.566-5.183 3.471 0 6.355.786 8.654 2.358 2.298 1.571 4.071 3.737 5.317 6.496v22.334c-1.187 2.817-2.892 5.108-5.117 6.874-2.224 1.764-5.146 2.647-8.765 2.647-4.686 0-8.223-1.928-10.611-5.784C1.194 12.027 0 7.044 0 .934Zm-5.339.934c0 7.682 1.639 13.829 4.916 18.442 3.278 4.611 7.824 6.918 13.637 6.918 3.41 0 6.369-.749 8.876-2.247 2.506-1.498 4.575-3.612 6.206-6.34l.623 7.697h4.493v-47.516c0-6.198-1.675-11.092-5.027-14.682-3.352-3.588-8.023-5.383-14.015-5.383-2.313 0-4.782.282-7.407.845-2.625.564-4.916 1.335-6.874 2.314l1.424 4.493a30.409 30.409 0 0 1 6.073-2.135c2.12-.504 4.353-.757 6.695-.757 4.568 0 8.009 1.35 10.322 4.049 2.314 2.7 3.471 6.451 3.471 11.256v6.095c-1.632-2.462-3.693-4.353-6.185-5.672-2.491-1.32-5.412-1.98-8.764-1.98-5.784 0-10.307 2.15-13.57 6.451S-5.339-7.178-5.339 0z"
        style={{
          fill: "white",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(118.591 67.543)"
      />
      <path
        d="M0 0v-11.79h8.453v-9.121H0v-24.514c0-1.868.385-3.203 1.157-4.004.77-.801 1.809-1.201 3.114-1.201.89 0 1.668.036 2.336.111.667.074 1.416.2 2.247.378l1.112-9.387a31.125 31.125 0 0 0-4.316-1.001c-1.423-.223-2.966-.334-4.627-.334-4.479 0-7.927 1.231-10.344 3.693-2.417 2.461-3.626 6.362-3.626 11.701v24.558h-7.074v9.121h7.074V0Z"
        style={{
          fill: "white",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(239.298 104.737)"
      />
      <path
        d="m0 0 6.229 1.335v29.853l-6.896 1.335v7.83H18.33l.578-7.03c1.127 2.492 2.581 4.434 4.361 5.829 1.779 1.393 3.84 2.091 6.184 2.091.681 0 1.401-.053 2.158-.156a13.28 13.28 0 0 0 1.89-.378l-1.379-11.835-5.339.134c-1.899 0-3.47-.371-4.716-1.112-1.245-.743-2.195-1.795-2.847-3.159V1.335L25.449 0v-7.786H0Z"
        style={{
          fill: "white",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(255.092 52.594)"
      />
      <path
        d="M0 0c0-4.39.771-7.912 2.314-10.566 1.542-2.656 4.033-3.982 7.474-3.982 3.351 0 5.806 1.334 7.363 4.004C18.708-7.875 19.487-4.36 19.487 0v.934c0 4.271-.787 7.756-2.358 10.456-1.572 2.698-4.049 4.048-7.43 4.048-3.381 0-5.843-1.35-7.385-4.048C.771 8.69 0 5.205 0 .934Zm-12.991.934c0 7.177 2.009 13.058 6.028 17.641 4.019 4.582 9.573 6.874 16.662 6.874 7.118 0 12.687-2.285 16.706-6.852 4.018-4.568 6.029-10.455 6.029-17.663V0c0-7.238-2.011-13.133-6.029-17.685-4.019-4.553-9.558-6.829-16.617-6.829-7.149 0-12.732 2.276-16.751 6.829C-10.982-13.133-12.991-7.238-12.991 0z"
        style={{
          fill: "white",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(304.566 68.388)"
      />
      <path
        d="m0 0 6.229 1.335v29.853l-6.896 1.335v7.83H18.33l.578-6.896c1.602 2.461 3.574 4.374 5.918 5.739 2.343 1.364 4.967 2.047 7.875 2.047 4.863 0 8.66-1.528 11.389-4.583 2.728-3.055 4.093-7.845 4.093-14.37V1.335L54.412 0v-7.786H29.586V0l5.561 1.335v20.91c0 3.233-.653 5.524-1.957 6.874-1.305 1.349-3.278 2.024-5.917 2.024-1.721 0-3.263-.348-4.627-1.045a9.517 9.517 0 0 1-3.426-2.959V1.335L24.47 0v-7.786H0Z"
        style={{
          fill: "white",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(341.938 52.594)"
      />
    </g>
    <g clipPath="url(#u)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M158.27 78.84h9.84v9.837h-9.84z"
        style={{
          fill: "url(#v)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#w)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M164.22 106.249h9.835v9.837h-9.835z"
        style={{
          fill: "url(#x)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#y)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M169.892 91.081h6.268v6.266h-6.268z"
        style={{
          fill: "url(#z)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#A)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M152.004 101.588h6.266v6.268h-6.266z"
        style={{
          fill: "url(#B)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#C)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M173.026 69.775h6.266v6.268h-6.266z"
        style={{
          fill: "url(#D)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#E)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M177.118 124.976v-9.616l8.97-1.639v-3.325h5.796V99.743h-5.796V70.871h4.742v-6.244h-4.742v-7.996l-8.97-1.639v-9.561h34.671v9.561l-8.969 1.639v57.09l8.969 1.639v9.616zm12.48-29.029h6.208V89.74h-6.208zm2.142-12.03h8.902v-8.903h-8.902z"
        style={{
          fill: "url(#F)",
          stroke: "none",
        }}
      />
    </g>
    <g clipPath="url(#G)" transform="matrix(.35278 0 0 -.35278 -10.132 44.089)">
      <path
        d="M0 0h2.011c.854 0 1.475.163 1.863.489.389.325.583.802.583 1.428 0 .596-.186 1.066-.56 1.413-.372.347-.95.521-1.731.521H0Zm2.166 5.046c1.232 0 2.177-.273 2.834-.818.657-.547.986-1.322.986-2.326a2.44 2.44 0 0 0-.458-1.46C5.223.018 4.785-.316 4.216-.56c.616-.201 1.059-.53 1.331-.985.272-.456.408-1.018.408-1.684v-.91c0-.346.044-.602.132-.768a.559.559 0 0 1 .434-.295l.374-.046V-6.25h-.824c-.594 0-1.018.207-1.269.621-.251.414-.376.916-.376 1.506v.878c0 .615-.179 1.11-.536 1.482-.358.373-.836.56-1.437.56H0v-3.852l1.266-.216v-.979h-4.061v.979l1.266.216v8.906l-1.266.217v.978h1.266z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(267.563 31.93)"
      />
      <path
        d="m0 0 1.266.217v8.905L0 9.34v.985h8.733V7.608H7.43l-.124 1.514H2.795V5.225h4.371V4.021H2.795V.217L4.061 0v-.979H0Z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(277.913 26.66)"
      />
      <path
        d="M0 0v.985h4.061V0L2.795-.218v-8.905l1.266-.217v-.978H0v.978l1.266.217v8.905z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(290.174 35.999)"
      />
      <path
        d="M0 0v-8.905h1.94c1.046 0 1.871.344 2.473 1.033.604.687.904 1.57.904 2.647v1.561c0 1.065-.3 1.943-.904 2.632C3.811-.345 2.986 0 1.94 0Zm1.94 1.203c1.476 0 2.663-.454 3.56-1.362.898-.908 1.348-2.082 1.348-3.521v-1.545c0-1.444-.45-2.618-1.348-3.521-.897-.903-2.084-1.355-3.56-1.355h-4.735v.979l1.266.217V0l-1.266.218v.985h1.266z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(300.501 35.781)"
      />
      <path
        d="M315.549 29.857h-3.797v1.195h3.797z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M0 0h-1.157l-.287 1.614c-.234.198-.544.361-.932.49a4.29 4.29 0 0 1-1.351.194c-.813 0-1.436-.172-1.871-.517A1.635 1.635 0 0 1-6.25.435c0-.493.221-.903.663-1.231.443-.329 1.158-.617 2.148-.866 1.19-.295 2.094-.73 2.712-1.304.619-.574.928-1.286.928-2.135 0-.911-.371-1.643-1.114-2.197-.742-.554-1.714-.831-2.915-.831-.869 0-1.642.143-2.318.427-.675.285-1.225.657-1.649 1.118v2.105h1.149l.294-1.624A3.736 3.736 0 0 1-5.295-6.7c.408-.155.898-.234 1.467-.234.771 0 1.381.163 1.828.49.448.326.672.768.672 1.328 0 .517-.194.951-.582 1.3-.389.349-1.074.643-2.058.881-1.195.285-2.13.704-2.803 1.258-.672.554-1.008 1.249-1.008 2.088 0 .885.375 1.621 1.125 2.209.751.587 1.726.881 2.927.881.791 0 1.512-.136 2.163-.407.649-.272 1.17-.602 1.564-.99Z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(327.863 33.647)"
      />
      <path
        d="m0 0-.753-.116-3.199-8.587a4.329 4.329 0 0 0-.916-1.475c-.399-.42-.934-.629-1.607-.629a5.521 5.521 0 0 0-.909.124l.155 1.204c-.03.005.062 0 .277-.016.215-.015.35-.023.407-.023.326 0 .59.141.792.423.202.282.378.607.528.975l.365.869-2.772 7.127L-8.4 0v.986h3.392V0l-.947-.155 1.452-4.146.279-1.071h.047L-2.43-.155-3.385 0v.986H0Z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(339.867 33.095)"
      />
      <path
        d="M0 0h-1.149l-.248 1.009a2.073 2.073 0 0 1-.704.424 2.73 2.73 0 0 1-.958.159c-.544 0-.947-.119-1.212-.358a1.121 1.121 0 0 1-.395-.862c0-.331.116-.592.35-.783.232-.193.706-.362 1.42-.513 1.061-.227 1.85-.537 2.368-.928.517-.39.776-.915.776-1.572 0-.745-.302-1.348-.904-1.808-.603-.461-1.391-.692-2.365-.692a6.12 6.12 0 0 0-1.715.222c-.507.147-.96.36-1.359.64l-.008 1.917h1.15l.287-1.126c.176-.16.415-.276.718-.348.302-.073.612-.11.927-.11.544 0 .97.111 1.278.331.307.219.462.508.462.866 0 .336-.128.613-.384.83-.257.218-.742.406-1.456.567-1.02.222-1.788.526-2.306.908-.517.383-.776.901-.776 1.553 0 .683.291 1.266.873 1.747.582.481 1.34.722 2.271.722.637 0 1.208-.083 1.716-.249.507-.165.929-.396 1.265-.691z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(349.72 31.441)"
      />
      <path
        d="M0 0v-2.026h1.591V-3.16H0v-5.101c0-.393.081-.67.244-.831.163-.16.379-.24.649-.24.134 0 .286.012.454.035.168.024.309.048.423.073l.209-1.047c-.145-.093-.357-.17-.636-.23a4.055 4.055 0 0 0-.839-.089c-.621 0-1.115.188-1.483.563-.367.376-.551.964-.551 1.766v5.101h-1.327v1.134h1.327V0Z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(356.454 36.107)"
      />
      <path
        d="M0 0c-.513 0-.95-.217-1.312-.648-.362-.433-.584-.975-.667-1.627l.015-.038H1.91v.201c0 .6-.161 1.102-.481 1.507C1.107-.202.632 0 0 0m.233-7.516c-1.17 0-2.1.39-2.791 1.169-.691.779-1.037 1.789-1.037 3.032v.341c0 1.196.357 2.191 1.068 2.986C-1.815.806-.973 1.203 0 1.203c1.128 0 1.983-.342 2.566-1.024.582-.684.873-1.595.873-2.734v-.955h-5.45l-.023-.038c.015-.807.22-1.472.613-1.992.394-.52.944-.78 1.654-.78.518 0 .972.074 1.363.221.39.148.728.351 1.012.61l.598-.994c-.3-.289-.697-.535-1.191-.734-.494-.199-1.088-.299-1.782-.299"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(365.593 33.033)"
      />
      <path
        d="m0 0 1.266.217v6.002L0 6.436v.986h2.64l.1-1.095c.27.399.616.706 1.04.924.425.218.916.326 1.476.326.558 0 1.039-.13 1.44-.389.401-.258.702-.646.905-1.164.263.476.613.854 1.047 1.134.435.279.943.419 1.522.419.859 0 1.537-.294 2.034-.881.497-.588.745-1.471.745-2.651V.217L14.215 0v-.979h-4.069V0l1.266.217v3.844c0 .827-.142 1.414-.427 1.757-.285.345-.706.517-1.265.517-.533 0-.961-.188-1.285-.563-.324-.376-.511-.847-.563-1.417V.217L9.138 0v-.979H5.077V0l1.266.217v3.844c0 .786-.147 1.362-.439 1.726-.293.365-.716.548-1.269.548-.466 0-.849-.096-1.15-.287a1.862 1.862 0 0 1-.69-.808V.217L4.061 0v-.979H0Z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(372.621 26.66)"
      />
      <path
        d="M0 0h-1.148l-.249 1.009c-.186.177-.42.317-.703.424a2.731 2.731 0 0 1-.959.159c-.543 0-.947-.119-1.211-.358a1.122 1.122 0 0 1-.396-.862c0-.331.117-.592.35-.783.233-.193.707-.362 1.42-.513 1.061-.227 1.851-.537 2.369-.928.517-.39.776-.915.776-1.572 0-.745-.302-1.348-.904-1.808-.604-.461-1.392-.692-2.365-.692-.636 0-1.209.074-1.715.222a4.54 4.54 0 0 0-1.359.64l-.008 1.917h1.149l.287-1.126c.177-.16.416-.276.719-.348.302-.073.612-.11.927-.11.543 0 .969.111 1.278.331.307.219.462.508.462.866 0 .336-.129.613-.385.83-.256.218-.741.406-1.456.567-1.02.222-1.788.526-2.306.908-.517.383-.776.901-.776 1.553 0 .683.291 1.266.874 1.747.582.481 1.339.722 2.27.722.637 0 1.209-.083 1.716-.249.507-.165.929-.396 1.266-.691z"
        style={{
          fill: "#70706f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(396.759 31.441)"
      />
    </g>
  </svg>
)

export default SvgComponent
