import { action, makeAutoObservable, observable } from "mobx";
import { IUserStore } from "../Stores/IUserStore";
import { UserStore } from "../Stores/UserStore";
import * as signalR from "@microsoft/signalr";
import { TokenState } from "../Stores/TokenState";
import { toast, ToastOptions } from 'react-toastify';
import { EntityChangedEventArgs, EventId, ISignalRClient, LogMessageEventArgs, TrackAssignmentToDisplayChangedEventArgs } from "./ISignalRClient";
import { AuthTokenInfo } from "../generated";
import { LapInfoCalculatedNotification } from "./LapInfoCalculatedNotification";
import { ISimpleEvent, SimpleEventDispatcher } from "strongly-typed-events";
import { Id, Toast } from "react-toastify/dist/types";
import { ModificationType } from "../Enumerations/ModificationType";

/**
 * This type is used to communicate with the SignalR-Hub in Gerbil
 * 
 * @remarks TODO: It has to be checked whether the proxy can be generated, see https://learn.microsoft.com/en-us/aspnet/signalr/overview/guide-to-the-api/hubs-api-guide-javascript-client#how-to-create-a-physical-file-for-the-signalr-generated-proxy
 * The problem ist, that the article refers to an older version of ASP.NET.
 */
export default class SignalRClient implements ISignalRClient {
    private static instance: ISignalRClient | null = null;
    private host: string;
    private hubConnection: signalR.HubConnection | undefined = undefined;
    private userStore: IUserStore;
    private unauthorizedConnection: boolean;

    private lapTimeReportedEvent = new SimpleEventDispatcher<LapInfoCalculatedNotification>();
    private trackAssignmentToDisplayChangedEvent = new SimpleEventDispatcher<TrackAssignmentToDisplayChangedEventArgs>();
    private broadcastMessageReceived = new SimpleEventDispatcher<string>();
    private serverTimeReceived = new SimpleEventDispatcher<Date>();
    private userChangedEventReceived = new SimpleEventDispatcher<EntityChangedEventArgs>;
    private raceTrackChangedEventReceived = new SimpleEventDispatcher<EntityChangedEventArgs>;
    private readPointChangedEventReceived = new SimpleEventDispatcher<EntityChangedEventArgs>;
    private publicDisplayChangedEventReceived = new SimpleEventDispatcher<EntityChangedEventArgs>;
    private logMessageReceived = new SimpleEventDispatcher<LogMessageEventArgs>;

    private reconnectionToast: Id | undefined;
    private isConnecting: boolean;

    /**
     * Returns the singleton instance.
     */
    public static get Instance(): ISignalRClient {
        if (SignalRClient.instance == null) {
            const NODE_ENV = process.env.NODE_ENV;
            console.log(`*** Starting SignalR-Client for environment ${NODE_ENV}`);
            const isDev = NODE_ENV == 'development';
            SignalRClient.instance = new SignalRClient(isDev ? "https://localhost:5001" : "", UserStore.Instance);
            this.instance!.Connect();
        }

        return SignalRClient.instance;
    }

    /**
     * Generates a new instance of the SignalR-Client.
     * 
     */
    constructor(hostName: string, userStore: IUserStore) {
        this.host = hostName;
        this.userStore = userStore;

        // Note: this method most be bound to "this", othewise the method will be detached from the context. See https://www.andreasreiterer.at/bind-callback-function-react/
        this.userStore.accessTokenStateChangedCallback = this.accessTokenChanged.bind(this);
        this.ConnectionClosed = this.ConnectionClosed.bind(this);
        this.Reconnecting = this.Reconnecting.bind(this);
        this.Reconnected = this.Reconnected.bind(this);
        this.Connect = this.Connect.bind(this);
        this.Disconnect = this.Disconnect.bind(this);

        this.BroadcastMessageReceived = this.BroadcastMessageReceived.bind(this);
        this.AccountLoggedInReceived = this.AccountLoggedInReceived.bind(this);
        this.LapTimeReportReceived = this.LapTimeReportReceived.bind(this);
        this.TrackAssignmentToDisplayChangedReceived = this.TrackAssignmentToDisplayChangedReceived.bind(this);
        this.ServerTimeReceived = this.ServerTimeReceived.bind(this);
        this.UserModified = this.UserModified.bind(this);
        this.RaceTrackModified = this.RaceTrackModified.bind(this);
        this.ReadPointModified = this.ReadPointModified.bind(this);
        this.LogMessageReceived = this.LogMessageReceived.bind(this);
        this.PublicDisplayModified = this.PublicDisplayModified.bind(this);

        this.Subscribe = this.Subscribe.bind(this);
        this.Unubscribe = this.Unubscribe.bind(this);
        makeAutoObservable(this);
    }

    public get onLapTimeReportedEvent(): ISimpleEvent<LapInfoCalculatedNotification> {
        return this.lapTimeReportedEvent.asEvent();
    }

    public get onTrackAssignmentToDisplayChangedEvent(): ISimpleEvent<TrackAssignmentToDisplayChangedEventArgs> {
        return this.trackAssignmentToDisplayChangedEvent.asEvent();
    }

    public get onBroadcastMessageReceivedEvent(): ISimpleEvent<string> {
        return this.broadcastMessageReceived.asEvent();
    }

    public get onServerTimeReceivedEvent(): ISimpleEvent<Date> {
        return this.serverTimeReceived.asEvent();
    }

    public get onUserModified(): ISimpleEvent<EntityChangedEventArgs> {
        return this.userChangedEventReceived.asEvent();
    }

    public get onRaceTrackModified(): ISimpleEvent<EntityChangedEventArgs> {
        return this.raceTrackChangedEventReceived.asEvent();
    }

    public get onReadPointModified(): ISimpleEvent<EntityChangedEventArgs> {
        return this.readPointChangedEventReceived.asEvent();
    }

    public get onPublicDisplayModified(): ISimpleEvent<EntityChangedEventArgs> {
        return this.publicDisplayChangedEventReceived.asEvent();
    }

    public get onLogMessage(): ISimpleEvent<LogMessageEventArgs> {
        return this.logMessageReceived.asEvent();
    }

    private async accessTokenChanged(newState: TokenState): Promise<void> {
        console.log("Token state changed, new state is " + newState);
        if (newState === TokenState.valid) {
            if (this.hubConnection !== undefined && this.unauthorizedConnection) {
                // A previously unauthorized connection was established, disconnect first.
                await this.Disconnect();
                this.unauthorizedConnection = false;
            }
            if (this.hubConnection === undefined || this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
                await this.Connect();
            }
        } else {
            console.log("The new token state is invalid");
            await this.Disconnect();
            await this.Connect();
        }
    }

    @action
    public async Connect(): Promise<boolean> {
        if (this.userStore.TokenState === TokenState.valid && this.userStore.accessToken !== "") {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${this.host}/hub`, { accessTokenFactory: () => this.userStore.accessToken })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            this.unauthorizedConnection = false;
        } else {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${this.host}/hub`)
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            this.unauthorizedConnection = true;
        }

        this.isConnecting = true;
        try {
            await this.hubConnection.start();
            this.JoinGroups();

            console.log(`Connected to ${this.hubConnection.baseUrl} with ${this.unauthorizedConnection ? "no authorization" : "authorized user"}.`);
            this.isConnected = true;

            this.hubConnection.onclose(this.ConnectionClosed);
            this.hubConnection.onreconnecting(this.Reconnecting);
            this.hubConnection.onreconnected(this.Reconnected);
            this.Subscribe();

            if (this.reconnectionToast !== undefined) {
                toast.dismiss(this.reconnectionToast);
                this.reconnectionToast = undefined;
                window.location.reload();
            }

            this.isConnecting = false;
        }
        catch (error: any) {
            console.log(`Could not connect to the hub. Error was ${error.message}. Retrying in 5 seconds.`);
            setTimeout(() => this.Connect(), 5000);
            return false;
        }

        return true;
    }

    private Reconnected() {
        this.isConnected = true;
        if (this.reconnectionToast !== undefined) {
            toast.dismiss(this.reconnectionToast);
            this.reconnectionToast = undefined;

            window.location.reload();
        }
    }

    Reconnecting(reconnectionError: Error | undefined) {
        if (this.isConnected && !this.isConnecting) {
            this.isConnected = false;
            console.log("Reconnecting.");
            if (this.reconnectionToast === undefined) {
                // Notify the user
                var options: ToastOptions = {
                    position: "top-right",
                    autoClose: false,
                    closeButton: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                };

                this.reconnectionToast = toast.error("Die Verbindung zum Server wurde verloren.\nVerbindung wird wieder hergestellt...", options);
            }
        }
    }

    ConnectionClosed(connectionClosed: Error | undefined) {
        if (!this.isConnecting && this.hubConnection !== undefined) {
            console.log(`Connection to the SignalR-Hub has been lost. Cleaning up and retrying`);
            this.Unubscribe();
            setTimeout(() => this.Connect(), 10000);
        }
    }

    private BroadcastMessageReceived(message: string, BroadcastMessageReceived: any): void {
        this.broadcastMessageReceived.dispatch(message);
    }

    public async SendBroadcast(message: string): Promise<void> {
        if (this.hubConnection !== undefined) {
            await this.hubConnection.invoke("BroadcastMessage", message);
        }
    }

    private Subscribe(): void {
        if (this.hubConnection !== undefined) {
            // Bind to the context first.
            this.hubConnection.on("BroadcastMessage", this.BroadcastMessageReceived);
            this.hubConnection.on("AccountLoggedIn", this.AccountLoggedInReceived);
            this.hubConnection.on("ReportLapTime", this.LapTimeReportReceived);
            this.hubConnection.on("TrackAssignmentToDisplayChanged", this.TrackAssignmentToDisplayChangedReceived)
            this.hubConnection.on("ServerTime", this.ServerTimeReceived);
            this.hubConnection.on("UserModified", this.UserModified);
            this.hubConnection.on("RaceTrackModified", this.RaceTrackModified);
            this.hubConnection.on("ReadPointModified", this.ReadPointModified);
            this.hubConnection.on("PublicDisplayModified", this.PublicDisplayModified);
            this.hubConnection.on("LogMessage", this.LogMessageReceived);
        }
    }

    private Unubscribe(): void {
        if (this.hubConnection !== undefined) {
            // Bind to the context first.
            this.hubConnection.off("BroadcastMessage", this.BroadcastMessageReceived);
            this.hubConnection.off("AccountLoggedIn", this.AccountLoggedInReceived);
            this.hubConnection.off("ReportLapTime", this.LapTimeReportReceived);
            this.hubConnection.off("TrackAssignmentToDisplayChanged", this.TrackAssignmentToDisplayChangedReceived)
            this.hubConnection.off("ServerTime", this.ServerTimeReceived);
            this.hubConnection.off("UserModified", this.UserModified);
            this.hubConnection.off("RaceTrackModified", this.RaceTrackModified);
            this.hubConnection.off("ReadPointModified", this.ReadPointModified);
            this.hubConnection.off("PublicDisplayModified", this.PublicDisplayModified);
            this.hubConnection.off("LogMessage", this.LogMessageReceived);
        }
    }

    LogMessageReceived(occurrenceTimeStamp: Date, logLevel: number, eventId: EventId, state: string, message: string, exception: object) {
        this.logMessageReceived.dispatch({
            occurrenceTimeStamp: occurrenceTimeStamp,
            logLevel: logLevel,
            eventId: eventId,
            category: state,
            message: message,
            exception: exception
        });
    }

    ServerTimeReceived(dateTime: string, ServerTimeReceived: any) {
        this.serverTimeReceived.dispatch(new Date(dateTime));
    }

    UserModified(id: string, source: string, modificationType: ModificationType) {
        this.userChangedEventReceived.dispatch({
            id: id,
            modificationType: modificationType,
            source: source,
        })
    }

    RaceTrackModified(id: string, source: string, modificationType: ModificationType) {
        this.raceTrackChangedEventReceived.dispatch({
            id: id,
            modificationType: modificationType,
            source: source,
        })
    }

    ReadPointModified(id: string, source: string, modificationType: ModificationType) {
        this.readPointChangedEventReceived.dispatch({
            id: id,
            modificationType: modificationType,
            source: source,
        })
    }

    PublicDisplayModified(id: string, source: string, modificationType: ModificationType) {
        this.publicDisplayChangedEventReceived.dispatch({
            id: id,
            source: source,
            modificationType: modificationType,
        })
    }

    private LapTimeReportReceived(reportData: LapInfoCalculatedNotification, LapTimeReportReceived: any): void {
        // if (this.LapTimeReported !== undefined) {
        //     this.LapTimeReported(reportData);
        // }

        this.lapTimeReportedEvent.dispatch(reportData);
    }

    private TrackAssignmentToDisplayChangedReceived(trackId: string, displayId: string, wasAssigned: boolean) {
        var data: TrackAssignmentToDisplayChangedEventArgs = {
            displayId: displayId,
            trackId: trackId,
            wasAssigned: wasAssigned
        };

        this.trackAssignmentToDisplayChangedEvent.dispatch(data);
    }

    private AccountLoggedInReceived(logIn: string, AccountLoggedInReceived: any): void {
        if (this.userStore.userName === logIn) {
            // Notify the user
            var options: ToastOptions = {
                autoClose: false,
                type: toast.TYPE.ERROR,
                hideProgressBar: true,
                position: 'top-center'
            };

            toast("Der Account wurde automatisch ausgeloggt, da er sich von einem anderen Gerät aus angemeldet hat", options);
            console.log(`Logging out the user ${logIn} as the account is used in another instance.`);
            this.Disconnect();
            this.userStore.PerformLogout();
        }
    }

    private JoinGroups(): void {
        if (this.hubConnection !== undefined) {
            this.hubConnection.send("JoinGroups");
        }
    }

    private async LeaveGroups(): Promise<void> {
        if (this.hubConnection !== undefined) {
            await this.hubConnection.send("LeaveGroups");
        }
    }

    public async RefreshTokenAsync(accessToken: string, refreshToken: string): Promise<void> {
        if (this.hubConnection !== undefined) {
            console.log('Refreshing the token.')
            var result = await this.hubConnection.invoke("RefreshToken", {
                AccessToken: accessToken,
                RefreshToken: refreshToken
            })

            if (result as AuthTokenInfo !== undefined) {
                console.log('Token refresh successfull.')
                this.userStore.SetLoginInfo(result);
            } else {
               console.log('Token refresh failed.')
            }
        }
    }

    public async Disconnect(): Promise<void> {
        console.log(`Disconnecting SignalR (using authentication: ${this.unauthorizedConnection ? "no" : "yes"})`);
        await this.LeaveGroups();
        this.hubConnection?.stop();
        this.hubConnection = undefined;
    }

    @observable
    public autoconnect: boolean = true;

    @observable
    public isConnected: boolean = false;
}