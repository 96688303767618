/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePublicDisplayCommand } from '../models/CreatePublicDisplayCommand';
import type { PublicDisplayDto } from '../models/PublicDisplayDto';
import type { TrackPublicDisplayAssignmentCommand } from '../models/TrackPublicDisplayAssignmentCommand';
import type { UpdatePublicDisplayCommand } from '../models/UpdatePublicDisplayCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PublicDisplaysService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a new public display.
     * Using this method a display can be created. It contains the resolution as well as the geo-location.
     * @param requestBody The public display data.
     * @returns string Created
     * @throws ApiError
     */
    public publicDisplaysCreate(
        requestBody?: CreatePublicDisplayCommand,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/publicdisplays/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Updates a public display.
     * Using this method the information about an already stored display can be updated.<br /><hr />
     * ***Note*** Fields that are not provided are set to null! Make sure to retrieve the current information before setting.
     * @param id The id of the public display to update.
     * @param requestBody The data to update.
     * @returns void
     * @throws ApiError
     */
    public publicDisplaysUpdate(
        id: string,
        requestBody?: UpdatePublicDisplayCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.0/publicdisplays/{id}/update',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Deletes a public display.
     * @param id The id of the public display to delete.
     * @returns void
     * @throws ApiError
     */
    public publicDisplaysDelete(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1.0/publicdisplays/{id}/delete',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets a public display by its id.
     * @param id The id of the display to get.
     * @returns PublicDisplayDto Success
     * @throws ApiError
     */
    public publicDisplaysGet(
        id: string,
    ): CancelablePromise<PublicDisplayDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/publicdisplays/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Gets a public display by its login name.
     * @param login The login of the display to get.
     * @returns PublicDisplayDto Success
     * @throws ApiError
     */
    public publicDisplaysGetByLogin(
        login: string,
    ): CancelablePromise<PublicDisplayDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/publicdisplays/ByLogin/{login}',
            path: {
                'login': login,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Gets a list of all configured public displays.
     * @returns PublicDisplayDto Success
     * @throws ApiError
     */
    public publicDisplaysGetAll(): CancelablePromise<Array<PublicDisplayDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/publicdisplays',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Assigns a track to a display.
     * @param requestBody The command.
     * @returns void
     * @throws ApiError
     */
    public publicDisplaysAssign(
        requestBody?: TrackPublicDisplayAssignmentCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/publicdisplays/assign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Unassigns a track from public display.
     * @param requestBody The command.
     * @returns void
     * @throws ApiError
     */
    public publicDisplaysUnassign(
        requestBody?: TrackPublicDisplayAssignmentCommand,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/publicdisplays/unassign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
