/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommonService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Indicates if the api is available.
     * Use this to determine if the **API** is available.
     * This action does not perform any work on the server side. <br />It's just used to check if the service itself is up and running.
     * @returns void
     * @throws ApiError
     */
    public apiAvailableGet(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/ApiAvailable',
        });
    }

}
