/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KnownTagDto } from '../models/KnownTagDto';
import type { TagRecordDtoFilteredResponse } from '../models/TagRecordDtoFilteredResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TagsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Gets the filtered tag records.
     * @param startDate
     * @param endDate
     * @returns TagRecordDtoFilteredResponse Success
     * @throws ApiError
     */
    public tagsGetTagRecordsFiltered(
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<TagRecordDtoFilteredResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/Tags/TagRecords',
            query: {
                'StartDate': startDate,
                'EndDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Gets the known tags.
     * @returns KnownTagDto Success
     * @throws ApiError
     */
    public tagsGetKnownTags(): CancelablePromise<Array<KnownTagDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/Tags/KnownTags',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
