import { Selection, CheckboxVisibility, CommandBar, Label, Link, MarqueeSelection, SelectionMode, ShimmeredDetailsList, mergeStyleSets, ColumnActionsMode, Panel, PanelType, FontIcon, DefaultPalette, SemanticColorSlots, DetailsListLayoutMode } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { useId } from '@fluentui/react-hooks';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { toast } from "react-toastify";
import { ErrorInfo } from "../../generated";
import { InjectedProps } from "../InjectedProps";
import CreateRaceTrack from "../RaceTracks/CreateRaceTrack";
import EditRaceTrack from "../RaceTracks/EditRaceTrack";
import { IReadPointInfo } from "./ReadPointsList";
import ChangeReadPointOrder from "../RaceTracks/ChangeReadPointOrder";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export interface IRaceTrackInfo {
    key: string | number,
    name: string,
    length: number,
    lowerBounds: number,
    upperBounds: number,
    assignedReadPoints: IReadPointInfo[] | undefined,
    conflictingTrackIds: string[] | undefined,
}

interface RaceTrackListState {
    knownTracks: IRaceTrackInfo[],
    selectionDetails: string,
    isEditPanelOpen: boolean,
    isNewPanelOpen: boolean,
    raceTrackId: string | undefined,
    isChangeOrderPanelOpen: boolean,
    // userDto: UserDto[] | undefined,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class RaceTrackList extends Component<InjectedProps, RaceTrackListState> {
    static defaultProps = {} as InjectedProps;

    private listSelection: Selection;
    classNames = mergeStyleSets({
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
    });
    controlStyles = {
        root: {
            margin: '0 30px 20px 0',
            maxWidth: '300px',
        },
    };

    constructor(props: InjectedProps, state: RaceTrackListState) {
        super(props);

        this.ListItemInvoked = this.ListItemInvoked.bind(this);
        this.RaceTrackChanged = this.RaceTrackChanged.bind(this);
        this.RaceTrackChangeCanceled = this.RaceTrackChangeCanceled.bind(this);
        this.DownloadRaceTracks = this.DownloadRaceTracks.bind(this);
        this.UpdateCurrentRaceTracksAsync = this.UpdateCurrentRaceTracksAsync.bind(this);

        this.listSelection = new Selection({
            onSelectionChanged: () => this.setState({ ...this.state, selectionDetails: this.GetSelectionDetails() })
        });

        this.state = {
            knownTracks: [],
            selectionDetails: this.GetSelectionDetails(),
            isEditPanelOpen: false,
            raceTrackId: undefined,
            isNewPanelOpen: false,
            isChangeOrderPanelOpen: false,
        }

        this.ToggleNewPanel = this.ToggleNewPanel.bind(this);
    }

    render(): ReactNode {
        return (<div style={{ marginTop: "10px", marginLeft: "30px" }} data-is-scrollable="true">
            {this.RenderHeader()}
            {this.RenderToolbar()}
            {this.RenderTable()}
            {this.RenderEditPanel()}
            {this.RenderChangeOrderPanel()}
            {this.RenderNewPanel()}
        </div>);
    }

    componentDidMount(): void {
        this.UpdateCurrentRaceTracksAsync();
    }

    private RenderNewPanel(): ReactNode {
        if (this.state.isNewPanelOpen) {
            return (<Panel
                type={PanelType.custom}
                customWidth="40vw"
                isBlocking={true}
                isOpen={this.state.isNewPanelOpen}
                hasCloseButton={false}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close">
                <CreateRaceTrack onCreated={this.RaceTrackChanged} onCancel={this.RaceTrackChangeCanceled} />
            </Panel>)
        }
        else {
            <div />
        }
    }

    private GetSelectionDetails(): string {
        const selectionCount = this.listSelection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'Kein Eintrag ausgewählt';
            case 1:
                return (
                    'Ein Eintrag gewählt: ' +
                    (this.listSelection.getSelection()[0] as IRaceTrackInfo).name
                );
            default:
                return `${selectionCount} Einträge ausgewählt`;
        }
    }

    private RenderHeader(): ReactNode {
        return (
            <div>
                <h2>Laufstrecken</h2>
                <p>Auf dieser Seite k&ouml;nnen Sie die Laufstrecken bearbeiten.<br/>
                Das bedeutet neue anzulegen, vorhandene zu bearbeiten und auch alte zu l&ouml;schen.</p>
                <p>
                    Zu jeder Laufstrecke m&uuml;ssen auch Lesepunkte zugeordnet werden, um erfasste Messungen einsortieren zu können.<br/>
                    <WarningAmberIcon/><strong>Bitte beachten Sie, dass die Reihenfolge der zugeordneten Lesepunkte eindeutig über alle Laufstrecken sein sollte.</strong><br/>
                    Ist dies nicht m&ouml;glich d&uuml;rfen sich die Grenzen für die jeweiligen Strecken mit den selben Lesern nicht &uuml;berschneiden.<br/>
                    </p>
            </div>);
    }

    private RenderToolbar(): ReactNode {
        return (
            // <FocusTrapZone>
            <CommandBar
                items={[
                    {
                        key: 'newItem',
                        text: 'Neu',
                        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                        iconProps: { iconName: 'Add' },
                        onClick: () => this.ToggleNewPanel(),
                    },
                    {
                        key: 'download',
                        text: 'Liste herunterladen',
                        iconProps: { iconName: 'Download' },
                        onClick: () => this.DownloadRaceTracks(),
                    },
                    {
                        key: 'delete',
                        text: 'Löschen',
                        iconProps: { iconName: 'Delete' },
                        onClick: () => { this.DeleteRaceTracksAsync() },
                    },
                    {
                        key: 'refresh',
                        ariaLabel: "Refresh",
                        iconProps: { iconName: 'Refresh' },
                        onClick: () => { this.UpdateCurrentRaceTracksAsync() },
                    }
                ]}
                farItems={[
                    // {
                    //     key: 'info',
                    //     text: 'Info',
                    //     // This needs an ariaLabel since it's icon-only
                    //     ariaLabel: 'Info',
                    //     iconOnly: true,
                    //     iconProps: { iconName: 'Info' },
                    //     onClick: () => console.log('Info'),
                    // },
                ]}
                ariaLabel="Aktionen"
            />

            // </FocusTrapZone>
        );
    }

    private RenderTable(): ReactNode {
        return (
            <div className={this.classNames.selectionDetails}>
                <Label>{this.state.selectionDetails}</Label>
                <MarqueeSelection selection={this.listSelection}>
                    <ShimmeredDetailsList
                        checkboxVisibility={CheckboxVisibility.always}
                        setKey="knownUsers"
                        onRenderDetailsHeader={(headerProps, defaultRender)=> {
                            if (!headerProps || !defaultRender) {
                                //technically these may be undefined...
                                return null;
                            }
                            return defaultRender({
                                ...headerProps,
                                styles: {
                                    root: {
                                        selectors: {
                                            '.ms-DetailsHeader-cell': {
                                                whiteSpace: 'normal',
                                                textOverflow: 'clip',
                                                lineHeight: 'normal',
                                            },
                                            '.ms-DetailsHeader-cellTitle': {
                                                height: '100%',
                                                alignItems: 'center',
                                            },
                                        },
                                    },
                                },
                            }) 
                        }}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        items={this.listSelection.getItems()}
                        columns={[
                            {
                                key: "keyId",
                                name: "Id",
                                ariaLabel: "Id",
                                fieldName: "key",
                                minWidth: 250,
                                isResizable: true,
                                columnActionsMode: ColumnActionsMode.clickable,
                                onRender: (item) => {
                                    { var userItem = item as IRaceTrackInfo; }
                                    return (<Link data-selection-invoke={true} >{userItem.key}</Link>)
                                }
                            }, {
                                key: "keyName",
                                name: "Name",
                                ariaLabel: "Name",
                                fieldName: "name",
                                minWidth: 100,
                                isResizable: true
                            }, {
                                key: "keyLength",
                                name: "Länge in Metern",
                                ariaLabel: "Länge in Metern",
                                fieldName: "length",
                                minWidth: 100,
                                isResizable: true
                            }, {
                                key: "keyLowerBounds",
                                name: "Untergrenze in Sekunden",
                                ariaLabel: "Untergrenze in Sekunden",
                                fieldName: "lowerBounds",
                                minWidth: 100,
                                isResizable: true
                            }, {
                                key: "keyUpperBounds",
                                name: "Obergrenze in Sekunden",
                                ariaLabel: "Obergrenze in Sekunden",
                                fieldName: "upperBounds",
                                minWidth: 100,
                                isResizable: true
                            }, {
                                key: "keyReadPoints",
                                name: "Zugeordnete Lesepunkte",
                                ariaLabel: "Zugeordnete Lesepunkte",
                                isResizable: true,
                                minWidth: 100,
                                onRender: (item => <Link data-selection-invoke={false} onClick={() => this.OrderAssignment((item as IRaceTrackInfo).key)} >{(item as IRaceTrackInfo).assignedReadPoints?.length}</Link>)
                            }, {
                                key: "keyHasConflicts",
                                name: "Konflikte",
                                ariaLabel: "Konflikte mit anderen Strecken",
                                isResizable: true,
                                minWidth: 200,
                                onRender: (item) => {
                                    var trackInfo = (item as IRaceTrackInfo);
                                    var conflictingTrackIdsArray: JSX.Element[] = [];
                                    trackInfo.conflictingTrackIds?.forEach(id => conflictingTrackIdsArray.push(this.RenderConflictRaceTrackTooltip(id)))

                                    if (trackInfo.conflictingTrackIds?.length != 0) {
                                        return (<TooltipHost
                                            content="tooltip">
                                            {conflictingTrackIdsArray}
                                            {/* <FontIcon aria-label="Warning" iconName="Warning" className="iconStyle" /> */}

                                        </TooltipHost>);
                                    }
                                    else {
                                        return "";
                                    }
                                }
                            }
                        ]}
                        selection={this.listSelection}
                        selectionMode={SelectionMode.multiple}
                        enterModalSelectionOnTouch={true}
                        enableShimmer={!this.state.knownTracks}
                        ariaLabelForShimmer="Content is being fetched"
                        ariaLabelForGrid="Item details"
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={this.ListItemInvoked}
                        listProps={{
                            renderedWindowsAhead: 0,
                            renderedWindowsBehind: 0,
                        }}
                    />
                </MarqueeSelection>
            </div>
        );
    }

    private OrderAssignment(key: string | number): void {
        this.setState({
            ...this.state,
            isChangeOrderPanelOpen: true,
            // isEditAssignmentPanelOpen: true,
            raceTrackId: key as string,
        })
    }

    private RenderEditPanel(): ReactNode {
        if (this.state.isEditPanelOpen && this.state.raceTrackId !== undefined) {
            return (
                <Panel
                    type={PanelType.custom}
                    customWidth="40vw"
                    isBlocking={true}
                    isOpen={this.state.isEditPanelOpen}
                    hasCloseButton={false}
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel="Close">
                    <EditRaceTrack onUpdated={this.RaceTrackChanged} onCancel={this.RaceTrackChangeCanceled} raceTrackId={this.state.raceTrackId} />
                </Panel>)
        }
        else {
            <div />
        }
    }

    private RenderChangeOrderPanel(): ReactNode {
        if (this.state.isChangeOrderPanelOpen && this.state.raceTrackId !== undefined) {
            return (
                <Panel
                    type={PanelType.custom}
                    customWidth="40vw"
                    isBlocking={true}
                    isOpen={this.state.isChangeOrderPanelOpen}
                    hasCloseButton={false}
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel="Close">
                    <ChangeReadPointOrder onUpdated={this.RaceTrackChanged} onCancel={this.RaceTrackChangeCanceled} raceTrackId={this.state.raceTrackId} />
                    {/* <EditRaceTrack onUpdated={this.RaceTrackChanged} onCancel={this.RaceTrackChangeCanceled} raceTrackId={this.state.raceTrackId} /> */}
                </Panel>)
        }
        else {
            <div />
        }
    }

    private RaceTrackChanged(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: false,
            isEditPanelOpen: false,
            isChangeOrderPanelOpen: false,
        });
        this.UpdateCurrentRaceTracksAsync();
    }

    private RaceTrackChangeCanceled(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: false,
            isEditPanelOpen: false,
            isChangeOrderPanelOpen: false,
        })
    }


    private ListItemInvoked(item: IRaceTrackInfo): void {
        console.log(`Item invoked: ${item.key}`);
        this.setState({
            ... this.state,
            isEditPanelOpen: true,
            raceTrackId: String(item.key)
        })
    };

    private async DeleteRaceTracksAsync(): Promise<void> {
        try {
            var selectedItems = this.listSelection.getSelection();
            for (var i = 0; i < selectedItems.length; i++) {
                await this.props.webApiClient.DeleteRaceTrackAsync(this.props.userStore.accessToken, selectedItems[i].key as string)
            }
            // this.listSelection.getSelection().forEach(async element => await this.props.webApiClient.DeleteUserAsync(this.props.userStore.accessToken, element.key as string));
        }
        catch (reason) {
            var error = "Ein unbekannter Fehler ist aufgetreten.";

            if (reason.body as ErrorInfo !== null) {
                var errorInfo = reason.body as ErrorInfo;
                error = errorInfo.errors !== null && errorInfo.errors !== undefined
                    ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
            }

            toast.error(`Konnte die ausgewählten Daten nicht löschen: ${error}`);
        }

        await this.UpdateCurrentRaceTracksAsync();
    }

    private ToggleNewPanel(): void {
        this.setState({
            ...this.state,
            isNewPanelOpen: true,
        });
    }

    private DownloadRaceTracks(): void {
        var lines = [];
        lines.push("ID;Name;Länge in Metern;Untergrenze in Sekunden;Obergrenze in Sekunden\r\n");

        if (this.state.knownTracks !== undefined) {
            this.state.knownTracks!.forEach(element => {
                lines.push(`${element.key};${element.name};${element.length};${element.lowerBounds};${element.upperBounds}\r\n`);
            });
        }

        var blob = new Blob(lines, { type: 'text/csv' })

        const a = document.createElement('a')
        a.download = "RaceTracks.csv"
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    private async UpdateCurrentRaceTracksAsync(): Promise<void> {
        console.log("Refreshing the current known race tracks.");
        var raceTracks = await this.props.webApiClient.GetAllRaceTracksAsync(this.props.userStore.accessToken);

        var trackInfo: IRaceTrackInfo[] = [];
        raceTracks.forEach(element => {
            let newLocal: IRaceTrackInfo = {
                key: element.id!,
                name: element.trackName!,
                length: element.lengthInM!,
                lowerBounds: element.lowerTimeBoundMs! / 1000,
                upperBounds: element.upperTimeBoundMs! / 1000,
                assignedReadPoints: undefined,
                conflictingTrackIds: element.conflictingTrackIds === null ? undefined : element.conflictingTrackIds,
            };

            if (element.assignedReadPoints !== null && element.assignedReadPoints !== undefined) {
                newLocal.assignedReadPoints = [];
                element.assignedReadPoints.forEach(point => {
                    let readPointInfo: IReadPointInfo = {
                        key: point.id!,
                        isIntermediate: point.isIntermediate!,
                        name: point.pointIdentifier!,
                        account: undefined,
                        assignedRaceTracks: undefined,
                        accountId: undefined,
                        distanceToStart: undefined,
                    };
                    newLocal.assignedReadPoints!.push(readPointInfo)

                })
            }
            trackInfo.push(newLocal);
        });
        this.setState({
            ...this.state,
            knownTracks: trackInfo,
        })

        this.listSelection.setItems(trackInfo);
    }

    private RenderConflictRaceTrackTooltip(raceTrackId: string): JSX.Element {
        return (<div>
            <label style={{ color: DefaultPalette.yellow }}>{(this.listSelection.getItems().find(item => (item as IRaceTrackInfo).key == raceTrackId) as IRaceTrackInfo).name}</label>
        </div>)
    }
}