import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, TextField, Toggle } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { stringify } from "querystring";
import { Component, FormEvent } from "react";
import { ErrorInfo } from "../../generated";
import { InjectedProps } from "../InjectedProps";
import EditPublicDisplay from "./EditPublicDisplay";


interface CreateReadPointState {
    displayName: string | undefined;
    login: string | undefined;
    password: string | undefined;
    valueChanged: boolean;
    horizontalResolution: number | undefined;
    verticalResolution: number | undefined;
    mustChangePassword: boolean;
    latitude: number | undefined;
    longitude: number | undefined;
    saveError: string | undefined;

    displayNameErrorMessage: string | undefined;
    loginErrorMessage: string | undefined;
    passwordErrorMessage: string | undefined;
    idleTargetErrorMessage: string | undefined;
    idleTimeErrorMessage: string | undefined;
    horizontalResolutionErrorMessage: string | undefined;
    verticalResolutionErrorMessage: string | undefined;
    latitudeErrorMessage: string | undefined;
    longitudeErrorMessage: string | undefined;
    unparsedLatitude: string | undefined;
    unparsedLongitude: string | undefined;
    idleTime: number | undefined;
    idleTarget: string | undefined;
    unparsedIdleTime: string | undefined;
}

interface CreateReadPointProps extends InjectedProps {
    onCreated: () => void | undefined;
    onCancel: () => void | undefined;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class CreatePublicDisplay extends Component<CreateReadPointProps, CreateReadPointState>{
    static defaultProps = {} as CreateReadPointProps;

    readonly displayNameEmptyMessage: string = "Der Anzeigename muss gefüllt sein.";
    readonly loginEmptyMessage: string = "Das Login muss gefüllt sein";
    readonly passwordEmptyMessage: string = "Das Password muss gefüllt sein";
    readonly horizontalResolutionEmptyMessage: string = "Die horizontale Auflösung muss gefüllt sein";
    readonly verticalResolutionEmptyMessage: string = "Die vertikale Auflösung muss gefüllt sein";
    readonly idleTimeEmptyMessage: string = "Die Inaktivitätszeit muss gefüllt sein";
    readonly idleTargetEmptyMessage: string = "Die Zieladresse muss gefüllt sein";

    /**
     * Initializes a new instance of CreateUser.
     */
    constructor(props: CreateReadPointProps) {
        super(props);

        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);
        this.ChangeDisplayName = this.ChangeDisplayName.bind(this);
        this.ChangeHorizontalResolution = this.ChangeHorizontalResolution.bind(this);
        this.ChangeVerticalResolution = this.ChangeVerticalResolution.bind(this);
        this.ChangeLatitude = this.ChangeLatitude.bind(this);
        this.ChangeLongitude = this.ChangeLongitude.bind(this);
        this.ChangeLogin = this.ChangeLogin.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);
        this.HasErrors = this.HasErrors.bind(this);
        this.ChangeIdleTime = this.ChangeIdleTime.bind(this);
        this.IdleTargetChanged = this.IdleTargetChanged.bind(this);

        this.state = {
            displayName: undefined,
            displayNameErrorMessage: this.displayNameEmptyMessage,
            horizontalResolution: undefined,
            horizontalResolutionErrorMessage: this.horizontalResolutionEmptyMessage,
            verticalResolution: undefined,
            verticalResolutionErrorMessage: this.verticalResolutionEmptyMessage,
            latitude: undefined,
            latitudeErrorMessage: undefined,
            longitude: undefined,
            unparsedLatitude: undefined,
            unparsedLongitude: undefined,
            longitudeErrorMessage: undefined,
            login: undefined,
            loginErrorMessage: this.loginEmptyMessage,
            password: undefined,
            passwordErrorMessage: this.passwordEmptyMessage,
            valueChanged: false,
            saveError: undefined,
            mustChangePassword: false,
            idleTarget: undefined,
            idleTargetErrorMessage: this.idleTargetEmptyMessage,
            idleTime: undefined,
            idleTimeErrorMessage: this.idleTimeEmptyMessage,
            unparsedIdleTime: undefined,
        }
    }
    render() {
        return (
            <div>
                <h2>&Ouml;ffentliche Anzeige erstellen</h2>
                <p>
                    <span>Hier k&ouml;nnen Sie eine neue &oumnl;ffentliche Anzeige (<em>imoled-Display</em>) anlegen. Bitte beachten Sie, dass Sie hier auch ein Passwort vergeben m&uuml;ssen!</span>
                </p>
                {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
                <div className="divTable">
                    <div className="divTableBody">
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Name" onChange={this.ChangeDisplayName} errorMessage={this.state.displayNameErrorMessage} value={this.state.displayName} /></div>
                            <div>&nbsp;</div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Inaktivität in Sekunden (0 zum Deaktivieren)" onChange={this.ChangeIdleTime} errorMessage={this.state.idleTimeErrorMessage} value={this.state.idleTime?.toString()} /></div>
                            <div className="divTableCell"><TextField required label="Anzuzeigende Zieladresse" onChange={this.IdleTargetChanged} errorMessage={this.state.idleTargetErrorMessage} value={this.state.idleTarget} /></div>
                        </div>

                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Horizontale Auflösung" onChange={this.ChangeHorizontalResolution} errorMessage={this.state.horizontalResolutionErrorMessage} value={this.state.horizontalResolution?.toString()} /></div>
                            <div className="divTableCell"><TextField required label="Vertikal Auflösung" onChange={this.ChangeVerticalResolution} errorMessage={this.state.verticalResolutionErrorMessage} value={this.state.verticalResolution?.toString()} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField step={0.1} min={-180.0} max={180.0} label="Breitengrad" onChange={this.ChangeLatitude} errorMessage={this.state.latitudeErrorMessage} value={this.state.unparsedLatitude} /></div>
                            <div className="divTableCell"><TextField label="Längengrad" onChange={this.ChangeLongitude} errorMessage={this.state.longitudeErrorMessage} value={this.state.unparsedLongitude} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Login" onChange={this.ChangeLogin} errorMessage={this.state.loginErrorMessage} value={this.state.login} /></div>
                            <div className="divTableCell"><TextField required label="Passwort (min. 6 Zeichen)" type="password" canRevealPassword revealPasswordAriaLabel="Show password" onChange={this.ChangePassword} errorMessage={this.state.passwordErrorMessage} value={this.state.password} /></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                            <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    private ApplyChanges(event: any): void {
        console.log("Applying changes.");
        this.props.webApiClient.CreatePublicDisplay(this.props.userStore.accessToken,
            this.state.displayName!,
            this.state.idleTime!,
            this.state.idleTarget!,
            this.state.horizontalResolution!,
            this.state.verticalResolution!,
            this.state.login!,
            this.state.password!,
            this.state.latitude,
            this.state.longitude)
            .then(result => {
                // The displaywas successfully created, close the create popup and refresh. This works only as a callback.
                if (this.props.onCreated !== undefined) {
                    this.props.onCreated();
                }
            })
            .catch(apiError => {
                if (apiError.body as ErrorInfo !== null) {
                    var errorInfo = apiError.body as ErrorInfo;
                    var error = errorInfo.errors !== null && errorInfo.errors !== undefined
                        ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    this.setState({
                        ...this.state,
                        saveError: error
                    });
                }
            })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich verwerfen?")) {
            this.setState({
                // ...this.state,
                displayName: undefined,
                valueChanged: false,
                login: undefined,
                password: undefined,
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private HasErrors(): boolean {
        return this.state.displayNameErrorMessage !== undefined
            || this.state.loginErrorMessage !== undefined
            || this.state.passwordErrorMessage !== undefined
            || this.state.idleTimeErrorMessage !== undefined
            || this.state.idleTargetErrorMessage !== undefined
            || this.state.latitudeErrorMessage !== undefined
            || this.state.longitudeErrorMessage !== undefined
            || this.state.verticalResolutionErrorMessage !== undefined
            || this.state.horizontalResolutionErrorMessage !== undefined
    }

    private ChangeLogin(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.loginEmptyMessage : undefined;
        if (this.state.login !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                login: newValue,
                loginErrorMessage: errorMessage,
            })
        };
    }

    private ChangePassword(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.passwordEmptyMessage : undefined;

        if (errorMessage === undefined) {
            if (newValue !== null && newValue!.length < 6) {
                errorMessage = "Die Länge des Passwortes muss mindestens 6 Zeichen sein.";
            }
        }
        if (this.state.password !== newValue) {

            this.setState({
                ...this.state,
                valueChanged: true,
                password: newValue,
                passwordErrorMessage: errorMessage,
            })
        };
    }

    private ChangeDisplayName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Anzeigename muss gefüllt sein" : undefined;
        if (this.state.displayName !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                displayName: newValue,
                displayNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeIdleTime(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = undefined;

        var numericalValue: number | undefined = 0;
        if (newValue !== null && newValue !== undefined && newValue !== "") {
            numericalValue = this.localeParseFloat(newValue, "de");

            if (Number.isNaN(numericalValue)) {
                errorMessage = "Die eingegebene Zahl ist ungültig";
                numericalValue = undefined;
            }
            else if (numericalValue < 0) {
                errorMessage = "Die eingegebene Zahl liegt außerhalb des erlaubten Bereichs (größer oder gleich 0)";
                numericalValue = undefined;
            }

            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            idleTime: numericalValue,
            unparsedIdleTime: newValue,
            idleTimeErrorMessage: errorMessage,
        })
    }

    private IdleTargetChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Das Ziel muss gefüllt sein" : undefined;
        
        if (this.state.idleTarget !== newValue) {
            if (newValue !== undefined)
            if (!EditPublicDisplay.isValidURI(newValue)){
                errorMessage = "Die angegebene Adresse ist keine gültige URI";
            }

            this.setState({
                ...this.state,
                valueChanged: true,
                idleTarget: newValue,
                idleTargetErrorMessage: errorMessage,
            })
        };
    }

    private localeParseFloat(s: string, locale?: string | undefined): number {
        if (locale === null) {
            locale = undefined;
        }

        // Get the thousands and decimal separator characters used in the locale.
        let [, thousandsSeparator, , , , decimalSeparator] = 1111.1.toLocaleString(locale);
        // Remove thousand separators, and put a point where the decimal separator occurs
        s = Array.from(s, c => c === thousandsSeparator ? ""
            : c === decimalSeparator ? "." : c).join("");
        // Now it can be parsed
        return parseFloat(s);
    }

    private ChangeHorizontalResolution(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.horizontalResolutionEmptyMessage : undefined;

        var numericalValue = 0;
        if (errorMessage === undefined) {
            var numericalValue = parseInt(newValue!);
            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            horizontalResolution: numericalValue,
            horizontalResolutionErrorMessage: errorMessage,
        })
    }

    private ChangeVerticalResolution(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.verticalResolutionEmptyMessage : undefined;

        var numericalValue = 0;
        if (errorMessage === undefined) {
            var numericalValue = parseInt(newValue!);
            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            verticalResolution: numericalValue,
            verticalResolutionErrorMessage: errorMessage,
        })
    }

    private ChangeLatitude(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = undefined;

        var numericalValue: number | undefined = 0;
        if (newValue !== null && newValue !== undefined && newValue !== "") {
            numericalValue = parseFloat(newValue!);

            if (Number.isNaN(numericalValue)) {
                errorMessage = "Die eingegebene Zahl ist ungültig";
                numericalValue = undefined;
            }
            else if (numericalValue < -180.0 || numericalValue > 180) {
                errorMessage = "Die eingegebene Zahl liegt außerhalb des erlaubten Bereichs";
                numericalValue = undefined;
            }

            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            latitude: numericalValue,
            unparsedLatitude: newValue,
            latitudeErrorMessage: errorMessage,
        })
    }

    private ChangeLongitude(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = undefined;

        var numericalValue: number | undefined = 0;
        if (newValue !== null && newValue !== undefined && newValue !== "") {
            numericalValue = parseFloat(newValue!);

            if (Number.isNaN(numericalValue)) {
                errorMessage = "Die eingegebene Zahl ist ungültig";
                numericalValue = undefined;
            }
            else if (numericalValue < -180.0 || numericalValue > 180) {
                errorMessage = "Die eingegebene Zahl liegt außerhalb des erlaubten Bereichs";
                numericalValue = undefined;
            }

            console.log(`Numerical value: ${numericalValue}`);
        }

        this.setState({
            ... this.state,
            longitude: numericalValue,
            unparsedLongitude: newValue,
            longitudeErrorMessage: errorMessage,
        })
    }
}
