import { inject, observer } from "mobx-react";
import { CSSProperties, Component, FormEvent, ReactNode } from "react";
import { InjectedPropsWithRouter } from "../InjectedProps";
import { withRouter } from "../../Components/Utils";
import { IStackProps, IStackStyles, Stack, MessageBar, MessageBarType, TextField, PrimaryButton } from "@fluentui/react";
import { Link, Navigate } from "react-router-dom";
import { ApiError, ErrorInfo } from "../../generated";

interface ResetPasswordState {
    eMailAddress: string,
    eMailAddressError: string | undefined,
    confirmationRequested: boolean,
    confirmationToken: string,
    confirmationTokenError: string | undefined,
    saveError: string | undefined,
    changesSaved: boolean,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
class ConfirmAccount extends Component<InjectedPropsWithRouter, ResetPasswordState> {
    private readonly eMailEmptyError = "Die E-Mail-Adresse darf nicht leer sein";
    private readonly confirmationTokenEmpty = "Das Bestätigunstoken darf nicht leer sein";

    /**
     *
     */
    constructor(props: InjectedPropsWithRouter) {
        super(props);

        this.EMailAddressChanged = this.EMailAddressChanged.bind(this);
        this.ConfirmationTokenChanged = this.ConfirmationTokenChanged.bind(this);

        this.CannotSendRequest = this.CannotSendRequest.bind(this);
        this.SubmitConfirmationRequest = this.SubmitConfirmationRequest.bind(this);

        var searchParams = new URLSearchParams(props.location.search);

        this.state = {
            eMailAddress: searchParams.get('requester') ?? '',
            eMailAddressError:  searchParams.get('requester') == null ? this.eMailEmptyError : undefined,
            changesSaved: false,
            confirmationRequested: false,
            confirmationToken: searchParams.get('confirmationToken') ?? '',
            confirmationTokenError: searchParams.get('confirmationToken') == null ? this.confirmationTokenEmpty : undefined,
            saveError: undefined
        }
    }

    render(): ReactNode {
        const documentStyle: CSSProperties = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1em",
            verticalAlign: "middle",
            height: "100vh"
        }

        const columnProps: Partial<IStackProps> = {
            tokens: { childrenGap: 5 },
            styles: { root: { width: 300 } },
        };

        const stackTokens = { childrenGap: 20 };
        const stackStyles: Partial<IStackStyles> = { root: { width: "100%" } };

        return this.state.confirmationRequested ? <div style={documentStyle}>
            <h1 style={{ fontFamily: "Roboto Slab" }}>tagItron Gerbil - Kontoaktivierung</h1>

            <em><strong>Ihr Konto wurde erfolgreich aktiviert.</strong></em>
            Sie können sich jetzt in der App oder <a href="/">auf der Startseite</a> einloggen.
            Sie werden automatisch innerhalb von 10 Sekunden auf die Startseite geleitet.            
        </div>
            :
            <div style={documentStyle}>
                <h1 style={{ fontFamily: "Roboto Slab" }}>tagItron Gerbil - Kontoaktivierung</h1>
                <div>
                    <p>
                        Geben Sie bitte hier Ihre E-Mail-Adresse ein sowie das Best&auml;tigungstoken ein, welches Sie per E-Mail erhalten haben.<br />                        
                    </p>
                </div>
                <div >
                    <Stack tokens={stackTokens} styles={stackStyles}>
                        {this.state.saveError !== undefined && (
                            <MessageBar
                                delayedRender={false}
                                isMultiline={true}
                                // Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
                                messageBarType={MessageBarType.error}
                            // Or you could set the role manually, IF an alert role is appropriate for the message
                            // role="alert"
                            >
                                {this.state.saveError}
                            </MessageBar>
                        )}
                        <Stack horizontal tokens={stackTokens} styles={stackStyles}>

                            <Stack {...columnProps}>
                                <TextField label='Das Bestätigungstoken:' placeholder="Das Token aus der E-Mail" value={this.state.confirmationToken} type="text" name="uName" required about="Ihr Bestätigungstoken" id="confirmationTokenTextField" errorMessage={this.state.confirmationTokenError} onChange={this.ConfirmationTokenChanged} />
                                <TextField value={this.state.eMailAddress} placeholder="Ihre E-Mail-Adresse" label='E-Mail-Adresse:' type="text" name="eMail" required about="Die E-Mail-Adresse" id="eMailTextField" errorMessage={this.state.eMailAddressError} onChange={this.EMailAddressChanged} />
                            </Stack>
                        </Stack>
                        <PrimaryButton text="Konto aktivieren" onClick={this.SubmitConfirmationRequest} disabled={this.CannotSendRequest()} />
                        <Link to="/">Zurück zur Startseite</Link>
                    </Stack>
                </div>
            </div>
    }

    private CannotSendRequest(): boolean {
        var result: boolean =
            this.state.eMailAddressError !== undefined
            || this.state.confirmationTokenError !== undefined;
        console.log(`Checking whether the button must be enabled; form has error =  ${result}`);
        return result;
    }

    private ConfirmationTokenChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        this.setState({
            ...this.state,
            confirmationToken: newValue ?? '',
            confirmationTokenError: newValue === undefined || newValue === "" ? this.confirmationTokenEmpty : undefined,
        })
    }

    private EMailAddressChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        // Is the E-Mail-Address syntactically correct?
        var error: string | undefined = undefined;
        if (newValue === "" || newValue === undefined) {
            error = this.eMailEmptyError;
        }
        else {
            var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            if (!regex.test(newValue)) {
                error = "Ungültige E-Mail-Adresse";
            }
        }
        this.setState({
            ...this.state,
            eMailAddress: newValue ?? '',
            eMailAddressError: error,
        });
    }

    private async SubmitConfirmationRequest(event: any) {
        console.log("Submitting registration request.")
        try {
            await this.props.webApiClient.ConfirmAccount(this.state.eMailAddress, this.state.confirmationToken);
            this.setState({
                ...this.state,
                saveError: undefined,
                confirmationRequested: true,
            })

            setTimeout(() => this.props.navigate("/"), 10000);
        }
        catch (error: any) {
            if (error instanceof ApiError) {
                var apiError = error as ApiError;

                console.error(error);

                if (apiError.status == 400) {
                    if (apiError.body as ErrorInfo !== null) {
                        var errorInfo = apiError.body as ErrorInfo;
                        error = errorInfo.errors !== null && errorInfo.errors !== undefined
                            ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    }

                    this.setState({
                        ...this.state,
                        saveError: "Die Anfrage war ungültig: " + error,
                    });
                }
                else if (apiError.status == 404) {
                    if (apiError.body as ErrorInfo !== null) {
                        var errorInfo = apiError.body as ErrorInfo;
                        error = errorInfo.errors !== null && errorInfo.errors !== undefined
                            ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    }

                    this.setState({
                        ...this.state,
                        saveError: "Es wurde kein passendes inaktives Konto gefunden. Wurde Ihr Konto bereits aktiviert?",
                    });
                }
                else if (apiError.status == 419) {
                    if (apiError.body as ErrorInfo !== null) {
                        var errorInfo = apiError.body as ErrorInfo;
                        error = errorInfo.errors !== null && errorInfo.errors !== undefined
                            ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    }

                    this.setState({
                        ...this.state,
                        saveError: "Das angegebene Bestätigungstoken ist abgelaufen. Bitte starten Sie den Registrierungsvorgang neu",
                    });
                }
                else{
                    this.setState({
                        ...this.state,
                        saveError: "Die Registrierung konnte nicht abgeschlossen werden. Versuchen Sie es später erneut.",
                    });
                }
            }
        }
    }
}


export default withRouter(ConfirmAccount);