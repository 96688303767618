import { CheckboxVisibility, DefaultButton, Selection, MarqueeSelection, MessageBar, MessageBarButton, MessageBarType, PrimaryButton, SelectionMode, ShimmeredDetailsList, mergeStyleSets, Label } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent, ReactNode } from "react";
import { toast } from "react-toastify";
import { ModificationType } from "../../Enumerations/ModificationType";
import { ApiError, ErrorInfo, PublicDisplayDto, ReadPointDto } from "../../generated";
import { EntityChangedEventArgs } from "../../Services/ISignalRClient";
import { IRaceTrackInfo } from "../Admin/RaceTrackList";
import { InjectedProps } from "../InjectedProps";
import { IReadPointInfo } from "../Admin/ReadPointsList";

interface ChangePublicDisplayTrackAssignmentProps extends InjectedProps {
    publicDisplayId: string,
    onUpdated: () => void | undefined;
    onCancel: () => void | undefined;
}

interface ChangePublicDisplayTrackAssignmentState {
    activePublicDisplay: PublicDisplayDto | undefined;
    name: string | undefined;
    valueChanged: boolean;
    saveError: string | undefined;
    externalChange: boolean;
    modificationType: ModificationType;
    knownRaceTracks: IRaceTrackInfo[] | undefined;
    selectionDetails: string,
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class ChangePublicDisplayTrackAssignment extends Component<ChangePublicDisplayTrackAssignmentProps, ChangePublicDisplayTrackAssignmentState>{
    static defaultProps = {} as ChangePublicDisplayTrackAssignmentProps;
    private listSelection: Selection;
    private classNames = mergeStyleSets({
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
    });
    controlStyles = {
        root: {
            margin: '0 30px 20px 0',
            maxWidth: '300px',
        },
    };

    // TODO: Must react to external assignment changes!

    /**
     * Initializes a new instance of EditRaceTrack
     */
    constructor(props: ChangePublicDisplayTrackAssignmentProps) {
        super(props);

        this.ApplyChangesAsync = this.ApplyChangesAsync.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);

        this.state = {
            saveError: undefined,
            activePublicDisplay: undefined,
            name: undefined,
            valueChanged: false,
            selectionDetails: "",
            modificationType: ModificationType.None,
            externalChange: false,
            knownRaceTracks: undefined,
        }

        this.ReadPointModified = this.ReadPointModified.bind(this);

        this.listSelection = new Selection({
            onSelectionChanged: () => this.setState({ ...this.state, selectionDetails: this.GetSelectionDetails() })
        });

    }

    componentDidMount(): void {
        this.GetPublicDisplayInfoAsync(this.props.publicDisplayId);

        this.props.signalRClient.onReadPointModified.subscribe(this.ReadPointModified);
    }

    componentWillUnmount(): void {
        this.props.signalRClient.onReadPointModified.subscribe(this.ReadPointModified);
    }

    render(): ReactNode {
        return (<div>
            <h2>Zuordnungen bearbeiten</h2>
            <p>
                <span>Hier k&ouml;nnen Sie der &ouml;ffentlichen Anzeige &quot;{this.state?.name}&quot; einer oder mehreren Laufstrecken zuordnen.</span><br />
                <span>Damit definieren Sie, auf welchen Anzeigen welche Laufstrecken dargestellt werden.</span><br />
            </p>
            <span>{this.state.externalChange ? this.state.modificationType === ModificationType.Updated ? this.RenderModificationWarning() : this.RenderDeletionError() : <div />}</span>
            {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
            <div className={this.classNames.selectionDetails}>
                <Label>{this.state.selectionDetails}</Label>
                <div className="divTable">
                    <div className="divTableBody">
                        {this.RenderRows(SelectionMode.multiple)}
                        <div className="divTableRow">
                            <div className="divTableCell"><PrimaryButton text="Speichern" onClick={this.ApplyChangesAsync} allowDisabledFocus /></div>
                            <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div >)
    }

    RenderRows(selectionMode: SelectionMode): ReactNode {
        return <MarqueeSelection selection={this.listSelection}>
            <ShimmeredDetailsList
                checkboxVisibility={CheckboxVisibility.always}
                setKey="knownRaceTracks"
                items={this.listSelection.getItems()}
                columns={[
                    {
                        key: "keyName",
                        name: "Name",
                        ariaLabel: "Name",
                        fieldName: "name",
                        minWidth: 100,
                        isResizable: true
                    }]}
                selection={this.listSelection}
                selectionMode={selectionMode}
                enterModalSelectionOnTouch={true}
                enableShimmer={!this.state.knownRaceTracks}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                selectionPreservedOnEmptyClick={true}
                listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                }}
            />
        </MarqueeSelection>
    }

    private GetSelectionDetails(): string {
        const selectionCount = this.listSelection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'Kein Eintrag ausgewählt';
            case 1:
                return (
                    'Ein Eintrag gewählt: ' +
                    (this.listSelection.getSelection()[0] as IRaceTrackInfo).name
                );
            default:
                return `${selectionCount} Einträge ausgewählt`;
        }
    }

    private async ApplyChangesAsync(event: any): Promise<void> {
        var selectedTracks = this.listSelection.getItems();
        var collectedErrors: string = "";

        for (let index = 0; index < selectedTracks.length; index++) {
            const element = selectedTracks[index];

            if (this.listSelection.isKeySelected(element.key as string)) {
                if (!this.state.activePublicDisplay?.onTracks?.some(p => p.id === element.key)) {
                    console.log("Selecting " + element.key);
                    try {
                        await this.props.webApiClient.AssignTrackToDisplay(this.props.userStore.accessToken, this.props.publicDisplayId, element.key as string);
                        console.log(`Updated assignment of ${element.key}`);
                    }
                    catch (reason) {
                        if (reason instanceof ApiError) {
                            var apiError = reason as ApiError;

                            console.error(reason);
                            var error = "Ein unbekannter Fehler ist aufgetreten.";

                            if (apiError.body as ErrorInfo !== null) {
                                var errorInfo = apiError.body as ErrorInfo;
                                error = errorInfo.errors !== null && errorInfo.errors !== undefined
                                    ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                            }
                            collectedErrors += error + "\r\n";
                        }
                        else {
                            collectedErrors += reason + "\r\n";
                        }
                    }
                }
            } else {
                if (this.state.activePublicDisplay?.onTracks?.some(p => p.id === element.key)) {
                    console.log("Deselecting " + element.key);
                    try {
                        await this.props.webApiClient.UnassignTrackFromDisplay(this.props.userStore.accessToken, this.props.publicDisplayId, element.key as string);
                        console.log(`Updated unassignment of ${element.key}`);
                    }
                    catch (reason: any) {
                        if (reason instanceof ApiError) {
                            var apiError = reason as ApiError;

                            console.error(reason);
                            var error = "Ein unbekannter Fehler ist aufgetreten.";

                            if (apiError.body as ErrorInfo !== null) {
                                var errorInfo = apiError.body as ErrorInfo;
                                error = errorInfo.errors !== null && errorInfo.errors !== undefined
                                    ? Object.entries(errorInfo.errors).map(([k, v]) => (v)).join(", ") : "Keine Errorinfo erhalten";
                            }
                            collectedErrors += error + "\r\n";
                        }
                        else {
                            collectedErrors += reason + "\r\n";
                        }
                    }
                }
            }
        }

        console.log("Updated the readpoint");
        if (collectedErrors === "") {
            toast.success('Der Lesepunkt wurde erfolgreich aktualisiert.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            this.setState({
                ...this.state,
                saveError: undefined
            });

            if (this.props.onUpdated !== undefined) {
                this.props.onUpdated();
            }
        }
        else {
            this.setState({
                ...this.state,
                saveError: collectedErrors
            })
        }
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich rückgängig machen?")) {
            this.setState({
                ...this.state,
                name: this.state.activePublicDisplay?.displayName ?? undefined,
                valueChanged: false
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private async GetPublicDisplayInfoAsync(publicDisplayId: string): Promise<void> {
        var publicDisplay = await this.props.webApiClient.GetPublicDisplayInfo(this.props.userStore.accessToken, publicDisplayId);
        var knownTracks = await this.props.webApiClient.GetAllRaceTracksAsync(this.props.userStore.accessToken);

        var raceTrackInfo: IRaceTrackInfo[] = [];

        var selectedKeys: string[] = [];

        knownTracks.forEach(element => {
            let newLocal: IRaceTrackInfo = {
                key: element.id!,
                length: element.lengthInM!,
                lowerBounds: element.lowerTimeBoundMs!,
                upperBounds: element.upperTimeBoundMs!,
                name: element.trackName!,
                assignedReadPoints: undefined,
                conflictingTrackIds: undefined
            };

            if (element.assignedReadPoints !== null && element.assignedReadPoints !== undefined) {
                element.assignedReadPoints.forEach(readPointAssociation => {
                    newLocal.assignedReadPoints?.push({
                        key: readPointAssociation!.id!,
                        name: readPointAssociation!.pointIdentifier!,
                        account: undefined,
                        isIntermediate: readPointAssociation.isIntermediate!,
                        assignedRaceTracks: undefined,
                        accountId: undefined,
                        distanceToStart: undefined,
                    })
                })
            }

            raceTrackInfo.push(newLocal);

            if (publicDisplay.onTracks?.some(p => p.id == element.id)) {
                selectedKeys.push(element.id!);
            }
        })

        this.listSelection.setItems(raceTrackInfo);
        selectedKeys.forEach(element => {
            console.log(`Setting ${element} to selected`);
            this.listSelection.setKeySelected(element, true, false);
        });

        this.setState({
            ...this.state,
            name: publicDisplay.displayName!,
            activePublicDisplay: publicDisplay,
            valueChanged: false,
            knownRaceTracks: raceTrackInfo,
        })
    }

    private RenderModificationWarning(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.severeWarning}
        >
            Diese Laufstrecke wurde von jemand anderem bearbeitet. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen und die geänderten Daten zu benutzen.
        </MessageBar>;
    }

    private RenderDeletionError(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.error}
            actions={<div>
                <MessageBarButton onClick={this.DiscardChanges}>Bearbeitung abbrechen</MessageBarButton>
            </div>}
        >
            <p>Diese Laufstrecke wurde serverseitig von jemand anderem gelöscht. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen!</p>
        </MessageBar>;
    }

    private ReadPointModified(raceTrackModifiedArgs: EntityChangedEventArgs): void {
        if (raceTrackModifiedArgs.source !== this.props.userStore.userName && raceTrackModifiedArgs.id === this.state.activePublicDisplay?.id) {
            this.setState({
                ... this.state,
                externalChange: true,
                modificationType: raceTrackModifiedArgs.modificationType,
            })
        }

        console.log("ReadPointModified handled." + this.state);
    }
}