import { DefaultButton, IStackProps, IStackStyles, MessageBar, MessageBarType, PrimaryButton, TagItemSuggestion, TextField, Toggle } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent, ReactNode } from "react";
import { toast } from "react-toastify";
import { ApiError, ErrorInfo, UserDto } from "../../generated";
import { InjectedProps } from "../InjectedProps";


interface CreateRaceTracState {
    displayName: string | undefined;
    valueChanged: boolean;
    length: number | undefined;
    lowerBounds: number | undefined,
    upperBounds: number | undefined,
    isIntermediate: boolean;
    mustChangePassword: boolean;
    saveError: string | undefined;

    displayNameErrorMessage: string | undefined;
    trackLengthErrorMessage: string | undefined;
    lowerBoundsErrorMessage: string | undefined;
    upperBoundsErrorMessage: string | undefined;
}

interface CreateRaceTrackProps extends InjectedProps {
    onCreated: () => void | undefined;
    onCancel: () => void | undefined;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class CreateRaceTrack extends Component<CreateRaceTrackProps, CreateRaceTracState>{
    static defaultProps = {} as CreateRaceTrackProps;

    readonly displayNameEmptyMessage: string = "Der Anzeigename muss gefüllt sein.";
    readonly trackLengthEmptyMessage: string = "Die Länge der Strecke muss gefüllt sein.";
    readonly loginEmptyMessage: string = "Das Login muss gefüllt sein";
    readonly passwordEmptyMessage: string = "Das Password muss gefüllt sein";
    readonly lowerBoundsEmptyMessage: string = "Die Untergrenze muss gefüllt sein";
    readonly upperBoundsEmptyMessage: string = "Die Obergrenze muss gefüllt sein"

    /**
     * Initializes a new instance of CreateUser.
     */
    constructor(props: CreateRaceTrackProps) {
        super(props);

        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);
        this.ChangeDisplayName = this.ChangeDisplayName.bind(this);
        this.ChangeLength = this.ChangeLength.bind(this);
        this.ChangeLowerBounds = this.ChangeLowerBounds.bind(this);
        this.ChangeUpperBounds = this.ChangeUpperBounds.bind(this);
        this.CheckBoundsRelation = this.CheckBoundsRelation.bind(this);

        this.HasErrors = this.HasErrors.bind(this);

        this.state = {
            displayName: undefined,
            displayNameErrorMessage: this.displayNameEmptyMessage,
            isIntermediate: false,
            valueChanged: false,
            saveError: undefined,
            mustChangePassword: false,
            length: undefined,
            trackLengthErrorMessage: this.trackLengthEmptyMessage,
            lowerBounds: undefined,
            upperBounds: undefined,
            lowerBoundsErrorMessage: this.lowerBoundsEmptyMessage,
            upperBoundsErrorMessage: this.upperBoundsEmptyMessage
        }
    }
    render() {
        return (
            <div>
                <h2>Laufstrecke erstellen</h2>
                <p>
                    <span>Hier k&ouml;nnen Sie eine neue Laufstrecke anlegen.</span><br />
                    <span>Die angegebenen Zeiten werden genutzt, um herauszufinden, auf welcher Strecke ein L&auml;ufer sich basierend auf den erhaltenten
                        Lesungen befinden kann.
                    </span>
                </p>
                {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
                <div className="divTable">
                    <div className="divTableBody">
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Name" onChange={this.ChangeDisplayName} errorMessage={this.state.displayNameErrorMessage} value={this.state.displayName} /></div>
                            <div className="divTableCell"><TextField required label="Länge in Metern" onChange={this.ChangeLength} errorMessage={this.state.trackLengthErrorMessage} value={this.state.length?.toString()} /></div>
                            <div></div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell"><TextField required label="Untergrenze in Sekunden" onChange={this.ChangeLowerBounds} errorMessage={this.state.lowerBoundsErrorMessage} value={this.state.lowerBounds?.toString()} /></div>
                            <div className="divTableCell"><TextField required label="Obergrenze in Sekunden" onChange={this.ChangeUpperBounds} errorMessage={this.state.upperBoundsErrorMessage} value={this.state.upperBounds?.toString()} /></div>
                            <div></div>
                        </div>

                        <div className="divTableRow">
                            <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                            <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.CreateRaceTrack(this.props.userStore.accessToken,
            this.state.displayName!,
            this.state.length!,
            this.state.lowerBounds! * 1000,
            this.state.upperBounds! * 1000)
            .then(result => {
                // The race track was successfully created, close the create popup and refresh. This works only as a callback.
                if (this.props.onCreated !== undefined) {
                    this.props.onCreated();
                }
            })
            .catch(apiError => {
                if (apiError.body as ErrorInfo !== null) {
                    var errorInfo = apiError.body as ErrorInfo;
                    var error = errorInfo.errors !== null && errorInfo.errors !== undefined
                    ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
                    this.setState({
                        ...this.state,
                        saveError: error
                    });
                }
            })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich verwerfen?")) {
            this.setState({
                // ...this.state,
                displayName: undefined,
                valueChanged: false,
                length: undefined,
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private HasErrors(): boolean {
        return this.state.displayNameErrorMessage !== undefined
            || this.state.trackLengthErrorMessage !== undefined
    }

    private ChangeDisplayName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Anzeigename muss gefüllt sein" : undefined;
        if (this.state.displayName !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                displayName: newValue,
                displayNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeLength(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Die Länge muss gefüllt sein" : undefined;

        var newNumberValue: number = 0;

        if (newValue !== null && newValue !== undefined) {
            newNumberValue = Number.parseInt(newValue!);
            if (newNumberValue === NaN) {
                errorMessage = "Der eingegebene Wert ist keine gültige Zahl";
                newNumberValue = 0;
            };
        }

        if (newNumberValue < 0) {
            errorMessage = "Die Länge muss größer als 0 sein";
            newNumberValue = 0;
        }
        else {
            if (this.state.length !== newNumberValue) {
                this.setState({
                    ...this.state,
                    valueChanged: true,
                    length: newNumberValue,
                    trackLengthErrorMessage: errorMessage,
                })
            }
        }
    }

    // TODO: the relation check between the lower and the upper bound is not yet working fully...
    private ChangeLowerBounds(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.lowerBoundsEmptyMessage : undefined;

        var newNumberValue: number = 0;

        if (newValue !== null && newValue !== undefined) {
            newNumberValue = Number.parseInt(newValue!);
            if (newNumberValue === NaN) {
                errorMessage = "Der eingegebene Wert ist keine gültige Zahl";
                newNumberValue = 0;
            };
        }

        if (newNumberValue < 0) {
            errorMessage = "Die Grenze muss größer als 0 sein";
            newNumberValue = 0;
        }
        else if (newNumberValue > (this.state.upperBounds === undefined ? 0 : this.state.upperBounds)) {
            errorMessage = "Der Wert für die Untergrenze muss kleiner sein als der für die Obergrenze";
        }

        if (this.state.lowerBounds !== newNumberValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                lowerBounds: newNumberValue,
                lowerBoundsErrorMessage: errorMessage,
            })
        }

        this.CheckBoundsRelation();
    }

    private ChangeUpperBounds(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? this.upperBoundsEmptyMessage : undefined;

        var newNumberValue: number = 0;

        if (newValue !== null && newValue !== undefined) {
            newNumberValue = Number.parseInt(newValue!);
            if (newNumberValue === NaN) {
                errorMessage = "Der eingegebene Wert ist keine gültige Zahl";
                newNumberValue = 0;
            };
        }

        if (newNumberValue < 0) {
            errorMessage = "Die Grenze muss größer als 0 sein";
            newNumberValue = 0;
        }
        else if (newNumberValue < (this.state.lowerBounds === undefined ? 0 : this.state.lowerBounds)) {
            errorMessage = "Der Wert für die Obergrenze muss größer sein als der für die Untergrenze";
        }

        if (this.state.upperBounds !== newNumberValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                upperBounds: newNumberValue,
                upperBoundsErrorMessage: errorMessage,
            })
        }

        // Re-Evaluate the lower bounds
        this.CheckBoundsRelation();
    }

    private CheckBoundsRelation() {
        // if (this.state.lowerBounds !== undefined && this.state.upperBounds !== undefined) {
        //     if (this.state.lowerBounds > this.state.upperBounds) {
        //         this.setState({
        //             ...this.state,
        //             upperBoundsErrorMessage: "Der Wert für die Obergrenze muss größer sein als der für die Untergrenze",
        //             lowerBoundsErrorMessage: "Der Wert für die Untergrenze muss kleiner sein als der für die Obergrenze",
        //         });
        //     } else {
        //         this.setState({
        //             ...this.state,
        //             upperBoundsErrorMessage: undefined,
        //             lowerBoundsErrorMessage: undefined,
        //         });
        //     }
        // }
    }
}