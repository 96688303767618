import { DefaultButton, IStackProps, IStackStyles, MessageBar, MessageBarButton, MessageBarType, PrimaryButton, TextField, Toggle } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent, ReactNode } from "react";
import { toast } from "react-toastify";
import { ModificationType } from "../../Enumerations/ModificationType";
import { ApiError, ErrorInfo, RaceTrackDto, UserDto } from "../../generated";
import { EntityChangedEventArgs } from "../../Services/ISignalRClient";
import { InjectedProps } from "../InjectedProps";

interface EditReadPointProps extends InjectedProps {
    readPointId: string,
    onUpdated: () => void | undefined;
    onCancel: () => void | undefined;
}

interface EditReadPointState {
    activeReadPoint: RaceTrackDto | undefined;
    name: string | undefined;
    isIntermediate: boolean;
    distanceToStart: number | undefined;
    unparsedDistanceToStart: string | undefined;

    valueChanged: boolean;
    saveError: string | undefined;
    externalChange: boolean;
    modificationType: ModificationType;

    readPointNameErrorMessage: string | undefined;
    distanceErrorMessage: string | undefined;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class EditReadPoint extends Component<EditReadPointProps, EditReadPointState>{
    static defaultProps = {} as EditReadPointProps;

    /**
     * Initializes a new instance of EditRaceTrack
     */
    constructor(props: EditReadPointProps) {
        super(props);

        this.ChangeReadPointName = this.ChangeReadPointName.bind(this);
        this.ChangeIsIntermediate = this.ChangeIsIntermediate.bind(this);
        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);
        this.ChangeDistanceToStart = this.ChangeDistanceToStart.bind(this);

        this.HasErrors = this.HasErrors.bind(this);

        this.state = {
            saveError: undefined,
            activeReadPoint: undefined,
            isIntermediate: false,
            name: undefined,
            valueChanged: false,
            modificationType: ModificationType.None,
            externalChange: false,
            distanceToStart: undefined,
            unparsedDistanceToStart: undefined,
            distanceErrorMessage: undefined,

            readPointNameErrorMessage: undefined,
        }

        this.ReadPointModified = this.ReadPointModified.bind(this);
    }

    componentDidMount(): void {
        this.GetReadPointInfoAsync(this.props.readPointId);

        this.props.signalRClient.onReadPointModified.subscribe(this.ReadPointModified);
    }

    componentWillUnmount(): void {
        this.props.signalRClient.onReadPointModified.subscribe(this.ReadPointModified);
    }

    render(): ReactNode {

        return (<div>
            <h2>Lesepunkt bearbeiten</h2>
            <p>
                <span>Hier können Sie den Lesepunkt &quot;{this.state?.name}&quot; bearbeiten.</span>
            </p>
            <span>{this.state.externalChange ? this.state.modificationType === ModificationType.Updated ? this.RenderModificationWarning() : this.RenderDeletionError() : <div />}</span>
            {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
            <div className="divTable">
                <div className="divTableBody">
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="Name" onChange={this.ChangeReadPointName} errorMessage={this.state.readPointNameErrorMessage} value={this.state.name} /></div>
                        <div className="divTableCell"><Toggle label="Ist Zwischenmessung?" onChange={this.ChangeIsIntermediate.bind(this)} checked={this.state.isIntermediate} /></div>
                    </div>
                    <div className="divTableRow" style={{ visibility: this.state.isIntermediate ? "visible" : "collapse" }}>
                            <div className="divTableCell"><TextField label="Abstand zum Startpunkt in m" onChange={this.ChangeDistanceToStart} errorMessage={this.state.distanceErrorMessage} value={this.state.unparsedDistanceToStart} /></div>
                            <div></div>
                        </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                        <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                    </div>
                </div>
            </div>
        </div >)
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.UpdateReadPointAsync(this.props.userStore.accessToken, this.state.activeReadPoint?.id!, this.state.name!, this.state.isIntermediate, this.state.distanceToStart)
        .then(() => {
            toast.success('Der Lesepunkt wurde erfolgreich aktualisiert.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            this.setState({
                ...this.state,
                saveError: undefined
            });
            if (this.props.onUpdated !== undefined){
                this.props.onUpdated();
            }
        })
        .catch((reason: ApiError)=> {
            console.error(reason);
            var error = "Ein unbekannter Fehler ist aufgetreten.";

            if (reason.body as ErrorInfo !== null){
                var errorInfo = reason.body as ErrorInfo;
                error = errorInfo.errors !== null && errorInfo.errors !== undefined
                ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
            }
            this.setState({
                ...this.state,
                saveError: error
            })
        })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich rückgängig machen?")) {
            this.setState({
                ...this.state,
                name: this.state.activeReadPoint?.trackName ?? undefined,
                valueChanged: false
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private ChangeReadPointName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Name muss gefüllt sein" : undefined;
        if (this.state.name !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                name: newValue,
                readPointNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeIsIntermediate(event: any, checked?: boolean | undefined): void {
        // TODO: Change the parameter of event from any to the correct type.
        this.setState({
            ...this.state,
            isIntermediate: checked ?? false,
            distanceToStart: 0,
            unparsedDistanceToStart: "",
            distanceErrorMessage: undefined,
        })
    }

    private ChangeDistanceToStart(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = undefined;
        var numericalValue: number | undefined = 0;

        // Define a regular expression to match a valid number
        const numberPattern = /^[-+]?\d*\.?\d*$/;

        // Check if the entire string matches the number pattern
        if (!numberPattern.test(newValue!)) {
            errorMessage = "Die eingegebene Zahl ist ungültig";
            numericalValue = undefined;
        } else {
            numericalValue = parseFloat(newValue!);

            if (numericalValue < 0) {
                errorMessage = "Die eingegebene Zahl liegt außerhalb des erlaubten Bereichs (größer oder gleich 0)";
                numericalValue = undefined;
            }
        }

        this.setState({
            ...this.state,
            distanceErrorMessage: errorMessage,
            distanceToStart: numericalValue,
            unparsedDistanceToStart: newValue,
        });
    }


    private HasErrors(): boolean {
        return this.state.readPointNameErrorMessage !== undefined
        || (this.state.isIntermediate && this.state.distanceErrorMessage !== undefined);
    }

    private async GetReadPointInfoAsync(trackId: string): Promise<void> {
        var readPoint = await this.props.webApiClient.GetReadPointInfo(this.props.userStore.accessToken, trackId);

        this.setState({
            ...this.state,
            name: readPoint.pointIdentifier!,
            isIntermediate: readPoint.isIntermediate!,
            distanceToStart: readPoint.isIntermediate ? readPoint.distanceToStartInM : 0,
            unparsedDistanceToStart: readPoint.isIntermediate ? readPoint.distanceToStartInM?.toString() : undefined,
            activeReadPoint: readPoint,
            valueChanged: false
        })
    }

    private RenderModificationWarning(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.severeWarning}
        >
            Diese Laufstrecke wurde von jemand anderem bearbeitet. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen und die geänderten Daten zu benutzen.
        </MessageBar>;
    }

    private RenderDeletionError(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.error}
            actions={<div>
                <MessageBarButton onClick={this.DiscardChanges}>Bearbeitung abbrechen</MessageBarButton>
            </div>}
        >
            <p>Diese Laufstrecke wurde serverseitig von jemand anderem gelöscht. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen!</p>
        </MessageBar>;
    }

    private ReadPointModified(raceTrackModifiedArgs: EntityChangedEventArgs): void {
        if (raceTrackModifiedArgs.source !== this.props.userStore.userName && raceTrackModifiedArgs.id === this.state.activeReadPoint?.id) {
            this.setState({
                ... this.state,
                externalChange: true,
                modificationType: raceTrackModifiedArgs.modificationType,
            })
        }

        console.log("ReadPointModified handled." + this.state);
    }
}