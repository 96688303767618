/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilteredLogResponse } from '../models/FilteredLogResponse';
import type { LogLevel } from '../models/LogLevel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SystemLogService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param startDate
     * @param endDate
     * @param pageNumber
     * @param pageSize
     * @param minLogLevel
     * @param maxLogLevel
     * @param categories
     * @param eventIds
     * @param includeAny
     * @param includeAll
     * @param excludeAny
     * @param regexPattern
     * @returns FilteredLogResponse Success
     * @throws ApiError
     */
    public systemLogGetFiltered(
        startDate?: string,
        endDate?: string,
        pageNumber?: number,
        pageSize?: number,
        minLogLevel?: LogLevel,
        maxLogLevel?: LogLevel,
        categories?: Array<string>,
        eventIds?: Array<number>,
        includeAny?: Array<string>,
        includeAll?: Array<string>,
        excludeAny?: Array<string>,
        regexPattern?: string,
    ): CancelablePromise<FilteredLogResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/SystemLog',
            query: {
                'StartDate': startDate,
                'EndDate': endDate,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'MinLogLevel': minLogLevel,
                'MaxLogLevel': maxLogLevel,
                'Categories': categories,
                'EventIds': eventIds,
                'IncludeAny': includeAny,
                'IncludeAll': includeAll,
                'ExcludeAny': excludeAny,
                'RegexPattern': regexPattern,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param startDate
     * @param endDate
     * @param focusedEntryId
     * @param pageSize
     * @param minLogLevel
     * @param maxLogLevel
     * @param categories
     * @param eventIds
     * @param includeAny
     * @param includeAll
     * @param excludeAny
     * @param regexPattern
     * @returns FilteredLogResponse Success
     * @throws ApiError
     */
    public systemLogGetFilteredWithFocusedId(
        startDate?: string,
        endDate?: string,
        focusedEntryId?: number,
        pageSize?: number,
        minLogLevel?: LogLevel,
        maxLogLevel?: LogLevel,
        categories?: Array<string>,
        eventIds?: Array<number>,
        includeAny?: Array<string>,
        includeAll?: Array<string>,
        excludeAny?: Array<string>,
        regexPattern?: string,
    ): CancelablePromise<FilteredLogResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/SystemLog/Focused',
            query: {
                'StartDate': startDate,
                'EndDate': endDate,
                'FocusedEntryId': focusedEntryId,
                'PageSize': pageSize,
                'MinLogLevel': minLogLevel,
                'MaxLogLevel': maxLogLevel,
                'Categories': categories,
                'EventIds': eventIds,
                'IncludeAny': includeAny,
                'IncludeAll': includeAll,
                'ExcludeAny': excludeAny,
                'RegexPattern': regexPattern,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
