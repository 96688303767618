import { INavLink, INavLinkGroup, INavStyles, Nav } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, ReactNode } from "react";
import { InjectedProps } from "../InjectedProps";
import PublicDisplaysList from "./PublicDisplaysList";
import RaceTrackList from "./RaceTrackList";
import ReadPointsList from "./ReadPointsList";
import UserList from "./UserList";

interface MainAdminState {
    selectedKey: string;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class MainAdmin extends Component<InjectedProps, MainAdminState> {
    static defaultProps = {} as InjectedProps;

    constructor(props: InjectedProps, state: MainAdminState) {
        super(props);

        this.LinkClicked = this.LinkClicked.bind(this);
        this.state = {
            selectedKey: "keyUsers",
        }
    }

    navStyles: Partial<INavStyles> = {
        root: {
            width: "200",
            height: "100vh",

            overflowY: 'auto',
        },

        // these link styles override the default truncation behavior
        link: {
            whiteSpace: 'normal',
            lineHeight: 'inherit',
        },
    };

    navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: 'Teilnehmer',
                    expandAriaLabel: 'Zeigt alle teilnehmenden Entitäten an',
                    url: "#",
                    links: [
                        {
                            name: 'Benutzer',
                            url: '#',
                            key: 'keyUsers',
                        },
                        {
                            name: 'Laufstrecken',
                            url: '#',
                            key: 'keyRaceTracks',
                        },
                        {
                            name: 'Lesepunkte',
                            url: '#',
                            key: 'keyReadPoints',
                        },                
                        {        
                        name: 'Öffentliche Anzeigen',
                        url: '#',
                        key: 'keyPublicDisplays',                        
                    },
                    ],
                    isExpanded: true,
                }
            ],
        },
    ];

    render(): ReactNode {
        var contentArea: ReactNode = this.RenderContent(this.state.selectedKey);

        return <div style={{ display: "flex" }}>
            <Nav groups={this.navLinkGroups}
                onLinkClick={this.LinkClicked}
                selectedKey={this.state.selectedKey}
                ariaLabel="Nav basic example"
                styles={this.navStyles} />
            {contentArea}
        </div>;
    }

    RenderContent(selectedKey: string): ReactNode {

        switch (this.state.selectedKey) {
            case "keyUsers":
                return (<UserList/>);
            case "keyRaceTracks":
                return (<RaceTrackList />);
            case "keyReadPoints":
                return (<ReadPointsList/>);
                case "keyPublicDisplays":
                    return (<PublicDisplaysList />)
            default:
                return (<div>Unbekannter Navigationseintrag</div>);
        }
    }

    LinkClicked(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        if (item) {
            this.setState({ ...this.state, selectedKey: item.key! })
        }
    }
}