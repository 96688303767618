import { IUserStore } from "./IUserStore";
import { observable, action, makeAutoObservable } from 'mobx';
import { TokenState } from "./TokenState";
import jwt from 'jsonwebtoken';
import { AccountRole } from "./AccountRole";
import { GerbilWebApiClient } from "../Services/GerbilWebApiClient";
import { AuthTokenInfo, GerbilClient } from "../generated";
import { Console } from "console";

export class UserStore implements IUserStore {
    private static instance: IUserStore | null;

    private readonly accessTokenStorageName: string = "accessToken";
    private readonly refreshTokenStorageName: string = "refreshToken";
    private readonly refreshTokenExpirationStorageName: string = "refreshTokenExpiration";
    public accessTokenStateChangedCallback: (newState: TokenState) => Promise<void>;

    /**
     * Returns the singleton instance.
     */
    public static get Instance(): IUserStore {
        if (UserStore.instance == null) {
            UserStore.instance = new UserStore(window.location.origin);
        }

        return UserStore.instance;
    }

    /**
     * Creates a new instance of the UserStore.
     */
    constructor(hostName: string) {
        this.host = hostName;

        makeAutoObservable(this);

        this.CheckAutoLogin();
    }

    @observable
    public userName: string = "";

    @observable
    public refreshTokenExpiration: Date = new Date();

    @observable
    public refreshToken: string = "";

    @observable
    public accessToken: string = "";

    @observable
    public isLoggedIn: boolean = false;

    @observable
    public host: string;

    @observable
    public hasAdminRole: boolean = false;

    @observable
    public role: AccountRole = AccountRole.User;

    @action
    public async LoginAsync(userName: string, passWord: string): Promise<boolean> {
        // Try to log in the user on the server
        // var client = new GerbilClient({
        //     BASE: this.host,
        // });

        try {
            console.log('Logging in user...')
            var result = await GerbilWebApiClient.Instance.Login(userName, passWord);
            // var result = await client.authentication.authenticationLogin({
            //     login: userName,
            //     password: passWord
            // });
            this.SetLoginInfo(result);
        }
        catch (error: any) {
            console.error(`An exception occurred while trying to log in the user: ${error.message}`)
            return false;
        }

        return true;
    }

    public get TokenState(): TokenState {
        // Is there a stored token?
        var storedToken = localStorage.getItem(this.accessTokenStorageName);

        if (storedToken != null) {
            var decodedToken: string | jwt.JwtPayload | null = jwt.decode(storedToken);
            var tokenInfo = decodedToken as {
                exp: number,
                role: string[],
                iat: number
            };

            var issuedAt: Date = new Date(tokenInfo.iat * 1000);
            var exp: Date = new Date(tokenInfo.exp * 1000);
            console.log("Token was issued at " + issuedAt + ". It expires at " + exp);

            return (exp.getTime() > Date.now()) ? TokenState.valid : TokenState.expired;
        } else {

            return TokenState.undefined;
        }
    }

    @action
    private async CheckAutoLogin(): Promise<void> {
        console.log('Checking auto login ...');
        if (this.TokenState === TokenState.undefined) {
            console.log('Not logged in.');
            // Not logged in       
        } else {
            // expired or still valid: refresh.
            // Do we have a refresh token?
            console.log('Token expired ...');
            var refreshToken = localStorage.getItem(this.refreshTokenStorageName);
            var accessToken = localStorage.getItem(this.accessTokenStorageName);
            if (refreshToken != null && localStorage.getItem(this.refreshTokenExpirationStorageName) != null && accessToken != null) {
                if (new Date(localStorage.getItem(this.refreshTokenExpirationStorageName)!.toString()).getTime() > Date.now()) {
                    // The refresh token still seems to be valid. Refresh
                    await this.RefreshLoginAsync(accessToken, refreshToken);
                }
                else {
                    console.log("RefreshToken expired.")

                }
            }
            else {
                console.log("No RefreshToken found.")
            }
        }
    }

    @action
    public async RefreshLoginAsync(accessToken: string, refreshToken: string): Promise<void> {
        console.log('Refreshing token ...')

        var client = new GerbilClient({
            BASE: this.host,
        });

        try {
            var result = await client.authentication.authenticationRefreshToken({
                accessToken: accessToken,
                refreshToken: refreshToken
            });
            this.SetLoginInfo(result);
        }
        catch {
            console.error("Could not refresh the token. Not logging in the user.");

            // if the token is still valid in 30 seconds, retry, else log out the user
            var decodedToken: string | jwt.JwtPayload | null = jwt.decode(this.accessToken);
            var tokenInfo = decodedToken as {
                exp: number
            };

            var exp: Date = new Date(tokenInfo.exp * 1000);
            var duration: number = exp.getTime() - new Date().getTime();
            if (duration > 30000) {
                console.log(`An error occurred while refreshing the token, retrying in 30 seconds.`);
                setTimeout(() => this.RefreshLoginAsync(this.accessToken, this.refreshToken), 30000);
            } else {
                this.isLoggedIn = false;
            }
        }
    }

    @action
    public SetLoginInfo(info: AuthTokenInfo): void {
        console.log('Setting login info ...');
        this.accessToken = info.accessToken!;
        this.refreshToken = info.refreshToken!;
        this.refreshTokenExpiration = new Date(info.expiration!);
        this.isLoggedIn = true;
        console.log('AccessToken=' + this.MaskString(this.accessToken) + ', RefreshToken=' + this.MaskString(this.refreshToken) + ', RefreshTokenExpiration=' + this.refreshTokenExpiration.toDateString());

        // Check for token expiration date.        
        var decodedToken: string | jwt.JwtPayload | null = jwt.decode(this.accessToken);
        var tokenInfo = decodedToken as {
            exp: number,
            role: string[],
            sub: string,
        };

        var exp: Date = new Date(tokenInfo.exp * 1000);
        var duration: number = exp.getTime() - new Date().getTime();
        console.log(`Valid for ${duration} milliseconds...`);
        setTimeout(() => this.RefreshLoginAsync(this.accessToken, this.refreshToken), duration - 300000);

        localStorage.setItem(this.accessTokenStorageName, this.accessToken);
        localStorage.setItem(this.refreshTokenStorageName, this.refreshToken);
        localStorage.setItem(this.refreshTokenExpirationStorageName, this.refreshTokenExpiration.toISOString());

        this.userName = tokenInfo.sub;
        console.log(tokenInfo.role);

        // Determine the current roles.
        if (Array.isArray(tokenInfo.role)) {
            tokenInfo.role.forEach(element => this.CheckRole(element));
        } else {
            console.log(`Only role: ${tokenInfo.role}`);
            this.CheckRole(tokenInfo.role);
        }

        if (this.accessTokenStateChangedCallback != null) {
            this.accessTokenStateChangedCallback(this.TokenState);
        }
    }

    private CheckRole(element: string) {
        switch (element) {
            case "Administrator":
                this.hasAdminRole = true;
                break;
            case "User":
                this.role = AccountRole.User;
                break;
            case "PublicDisplay":
                this.role = AccountRole.PublicDisplay;
                break;
            default:
                break;
        }
    }

    @action
    public PerformLogout(): void {
        localStorage.removeItem(this.accessTokenStorageName);
        localStorage.removeItem(this.refreshTokenStorageName);
        localStorage.removeItem(this.refreshTokenExpirationStorageName);

        if (this.isLoggedIn) {
            console.log("Logging out the current user");
            this.userName = '';
            this.isLoggedIn = false;
        }

        if (this.accessTokenStateChangedCallback != null) {
            this.accessTokenStateChangedCallback(this.TokenState);
        }
    }

    private MaskString(input: string): string {
        if (input.length <= 8) {
            return input;
        }

        const firstFourChars = input.slice(0, 4);
        const lastFourChars = input.slice(-4);
        const middleMasked = '*'.repeat(input.length - 8); // Calculate the number of asterisks needed for the middle

        return `${firstFourChars}${middleMasked}${lastFourChars}`;
    }
}