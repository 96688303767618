/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FxConnectTagData } from '../models/FxConnectTagData';
import type { IotConnectSingleTagData } from '../models/IotConnectSingleTagData';
import type { RfidGateTagReaderData } from '../models/RfidGateTagReaderData';
import type { SpeedwayConnectTagData } from '../models/SpeedwayConnectTagData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RfidReaderService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Posts the specified tag event data.
     * This endpoint is used to report one to many detected tags.
     * @param requestBody The tag data.
     * @returns any Success
     * @throws ApiError
     */
    public fxConnectPostTagData(
        requestBody?: FxConnectTagData,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/FxConnect/tags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The request was not correct.
                It is expected that the location name is not null.`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public iotConnectPostTagData(
        requestBody?: Array<IotConnectSingleTagData>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/IotConnect/tags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param xSourceId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public rfidGatePostTagData(
        xSourceId?: string,
        requestBody?: RfidGateTagReaderData,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/RfidGate/tags',
            headers: {
                'X-Source-Id': xSourceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public speedwayConnectPostTagData(
        requestBody?: SpeedwayConnectTagData,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1.0/SpeedwayConnect/tags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
