/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RaceDtoFilteredAndOrderedByDateResponse } from '../models/RaceDtoFilteredAndOrderedByDateResponse';
import type { RaceDtoFilteredResponse } from '../models/RaceDtoFilteredResponse';
import type { SortOrder } from '../models/SortOrder';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RaceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param startDate
     * @param endDate
     * @param sortOrder
     * @param pageNumber
     * @param pageSize
     * @returns RaceDtoFilteredAndOrderedByDateResponse Success
     * @throws ApiError
     */
    public raceGetFiltered(
        startDate?: string,
        endDate?: string,
        sortOrder?: SortOrder,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<RaceDtoFilteredAndOrderedByDateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/Race',
            query: {
                'StartDate': startDate,
                'EndDate': endDate,
                'SortOrder': sortOrder,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns RaceDtoFilteredResponse Success
     * @throws ApiError
     */
    public raceGetById(
        id: string,
    ): CancelablePromise<RaceDtoFilteredResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/Race/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param userId
     * @param startDate
     * @param endDate
     * @param sortOrder
     * @param pageNumber
     * @param pageSize
     * @returns RaceDtoFilteredAndOrderedByDateResponse Success
     * @throws ApiError
     */
    public raceGetFilteredForUserId(
        userId: string,
        startDate?: string,
        endDate?: string,
        sortOrder?: SortOrder,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<RaceDtoFilteredAndOrderedByDateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/Race/ByUserId/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'StartDate': startDate,
                'EndDate': endDate,
                'SortOrder': sortOrder,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param userName
     * @param startDate
     * @param endDate
     * @param sortOrder
     * @param pageNumber
     * @param pageSize
     * @returns RaceDtoFilteredAndOrderedByDateResponse Success
     * @throws ApiError
     */
    public raceGetFilteredByUserName(
        userName: string,
        startDate?: string,
        endDate?: string,
        sortOrder?: SortOrder,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<RaceDtoFilteredAndOrderedByDateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/Race/ByLogin/{userName}',
            path: {
                'userName': userName,
            },
            query: {
                'StartDate': startDate,
                'EndDate': endDate,
                'SortOrder': sortOrder,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Recalculates the races and laps. !! All currently calculated races are deleted and recalculated. !!
     * @returns any Success
     * @throws ApiError
     */
    public raceRecalculateRaces(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1.0/Race/recalculate',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
