import { DefaultButton, IStackProps, IStackStyles, MessageBar, MessageBarButton, MessageBarType, PrimaryButton, TextField, Toggle } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, FormEvent, ReactNode } from "react";
import { toast } from "react-toastify";
import { ModificationType } from "../../Enumerations/ModificationType";
import { ApiError, ErrorInfo, RaceTrackDto, UserDto } from "../../generated";
import { EntityChangedEventArgs } from "../../Services/ISignalRClient";
import { InjectedProps } from "../InjectedProps";

interface EditRaceTrackProps extends InjectedProps {
    raceTrackId: string,
    onUpdated: () => void | undefined;
    onCancel: () => void | undefined;
}

interface EditRaceTrackState {
    activeRaceTrack: RaceTrackDto | undefined;
    name: string | undefined;
    length: number | undefined;
    lowerBounds: number | undefined;
    upperBounds: number | undefined;
    valueChanged: boolean;
    saveError: string | undefined;
    externalChange: boolean;
    modificationType: ModificationType;

    trackNameErrorMessage: string | undefined;
    trackLengthErrorMessage: string | undefined;
    trackLowerBoundsErrorMessage: string | undefined;
    trackUpperBoundsErrorMessage: string | undefined;
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class EditRaceTrack extends Component<EditRaceTrackProps, EditRaceTrackState>{
    static defaultProps = {} as EditRaceTrackProps;

    /**
     * Initializes a new instance of EditRaceTrack
     */
    constructor(props: EditRaceTrackProps) {
        super(props);

        this.ChangeTrackName = this.ChangeTrackName.bind(this);
        this.ChangeTrackLength = this.ChangeTrackLength.bind(this);
        this.ChangeTrackLowerBounds = this.ChangeTrackLowerBounds.bind(this);
        this.ChangeTrackUpperBounds = this.ChangeTrackUpperBounds.bind(this);
        this.ApplyChanges = this.ApplyChanges.bind(this);
        this.DiscardChanges = this.DiscardChanges.bind(this);

        this.HasErrors = this.HasErrors.bind(this);

        this.state = {
            saveError: undefined,
            activeRaceTrack: undefined,
            length: undefined,
            lowerBounds: undefined,
            name: undefined,
            upperBounds: undefined,
            valueChanged: false,
            modificationType: ModificationType.None,
            externalChange: false,

            trackNameErrorMessage: undefined,
            trackLengthErrorMessage: undefined,
            trackLowerBoundsErrorMessage: undefined,
            trackUpperBoundsErrorMessage: undefined,
        }

        this.RaceTrackModified = this.RaceTrackModified.bind(this);
    }

    componentDidMount(): void {
        this.GetTrackInfoAsync(this.props.raceTrackId);

        this.props.signalRClient.onRaceTrackModified.subscribe(this.RaceTrackModified);
    }

    componentWillUnmount(): void {
        this.props.signalRClient.onRaceTrackModified.subscribe(this.RaceTrackModified);
    }

    render(): ReactNode {

        return (<div>
            <h2>Profil bearbeiten</h2>
            <p>
                <span>Hier können Sie die Laufstrecke &quot;{this.state?.name}&quot; bearbeiten.</span>
            </p>
            <span>{this.state.externalChange ? this.state.modificationType === ModificationType.Updated ? this.RenderModificationWarning() : this.RenderDeletionError() : <div />}</span>
            {this.state.saveError !== undefined && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.ResetErrorMessage}>{this.state.saveError}</MessageBar>}
            <div className="divTable">
                <div className="divTableBody">
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="Name" onChange={this.ChangeTrackName} errorMessage={this.state.trackNameErrorMessage} value={this.state.name} /></div>
                        <div className="divTableCell"><TextField required label="Länge in Metern" onChange={this.ChangeTrackLength} errorMessage={this.state.trackLengthErrorMessage} value={this.state.length?.toString()} /></div>
                        <div></div>
                    </div>
                    <div className="divTableRow">
                        <div className="divTableCell"><TextField required label="Untergrenze in Sekunden" onChange={this.ChangeTrackLowerBounds} errorMessage={this.state.trackLowerBoundsErrorMessage} value={this.state.lowerBounds?.toString()} /></div>
                        <div className="divTableCell"><TextField required label="Obergrenze in Sekunden" onChange={this.ChangeTrackUpperBounds} errorMessage={this.state.trackUpperBoundsErrorMessage} value={this.state.upperBounds?.toString()} /></div>
                    </div>                    
                    <div className="divTableRow">
                        <div className="divTableCell"><PrimaryButton disabled={this.HasErrors()} text="Speichern" onClick={this.ApplyChanges} allowDisabledFocus /></div>
                        <div className="divTableCell"><DefaultButton text="Abbrechen" onClick={this.DiscardChanges} /></div>
                    </div> 
                </div>
            </div>
        </div >)
    }

    private ApplyChanges(event: any): void {
        this.props.webApiClient.UpdateRaceTrackAsync(this.props.userStore.accessToken, this.state.activeRaceTrack?.id!, this.state.name!, this.state.length!, this.state.lowerBounds! * 1000, this.state.upperBounds! * 1000)
        .then(() => {
            toast.success('Die Laufstrecke wurde erfolgreich aktualisiert.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            this.setState({
                ...this.state,
                saveError: undefined
            });
            if (this.props.onUpdated !== undefined){
                this.props.onUpdated();
            }
        })
        .catch((reason: ApiError)=> {
            console.error(reason);
            var error = "Ein unbekannter Fehler ist aufgetreten.";

            if (reason.body as ErrorInfo !== null){
                var errorInfo = reason.body as ErrorInfo;
                error = errorInfo.errors !== null && errorInfo.errors !== undefined
                ? Object.entries(errorInfo.errors).map(([k, v]) => (v.errorMessage)).join(", ") : "Keine Errorinfo erhalten";
            }
            this.setState({
                ...this.state,
                saveError: error
            })
        })
    }

    private DiscardChanges(event: any): void {
        if (window.confirm("Wollen Sie die Änderungen wirklich rückgängig machen?")) {
            this.setState({
                ...this.state,
                name: this.state.activeRaceTrack?.trackName ?? undefined,
                length: this.state.activeRaceTrack!.lengthInM ?? undefined,
                lowerBounds: this.state.activeRaceTrack!.lowerTimeBoundMs === undefined ? undefined : this.state.activeRaceTrack!.lowerTimeBoundMs / 1000 ?? undefined,
                upperBounds: this.state.activeRaceTrack!.upperTimeBoundMs === undefined ? undefined : this.state.activeRaceTrack!.upperTimeBoundMs /1000 ?? undefined,
                valueChanged: false
            });

            if (this.props.onCancel !== undefined) {
                this.props.onCancel();
            }
        }
    }

    private ResetErrorMessage(): void {
        this.setState({
            ...this.state,
            saveError: undefined
        })
    }

    private ChangeTrackName(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Der Name muss gefüllt sein" : undefined;
        if (this.state.name !== newValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                name: newValue,
                trackNameErrorMessage: errorMessage,
            })
        };
    }

    private ChangeTrackLength(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Die Streckenlänge muss gefüllt sein" : undefined;

        var newNumberValue: number = 0;

        if (newValue !== null && newValue !== undefined) {
            newNumberValue = Number.parseInt(newValue!);
            if (isNaN(newNumberValue)) {
                errorMessage = "Der eingegebene Wert ist keine gültige Zahl";
                newNumberValue = 0;
            };
        }

        if (this.state.length !== newNumberValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                length: newNumberValue,
                trackLengthErrorMessage: errorMessage,
            });
        };
    }

    private ChangeTrackLowerBounds(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Die Untergrenze muss gefüllt sein" : undefined;

        var newNumberValue: number = 0;

        if (newValue !== null && newValue !== undefined) {
            newNumberValue = Number.parseInt(newValue!);
            if (isNaN(newNumberValue)) {
                errorMessage = "Der eingegebene Wert ist keine gültige Zahl";
                newNumberValue = 0;
            };
        }

        if (this.state.lowerBounds !== newNumberValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                lowerBounds: newNumberValue,
                trackLowerBoundsErrorMessage: errorMessage,
            });
        };
    }

    private ChangeTrackUpperBounds(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void | undefined {
        var errorMessage = newValue === null || newValue === undefined || newValue === "" ? "Die Obergrenze muss gefüllt sein" : undefined;

        var newNumberValue: number = 0;

        if (newValue !== null && newValue !== undefined) {
            newNumberValue = Number.parseInt(newValue!);
            if (isNaN(newNumberValue)) {
                errorMessage = "Der eingegebene Wert ist keine gültige Zahl";
                newNumberValue = 0;
            };
        }

        if (this.state.upperBounds !== newNumberValue) {
            this.setState({
                ...this.state,
                valueChanged: true,
                upperBounds: newNumberValue,
                trackUpperBoundsErrorMessage: errorMessage,
            });
        };
    }

    private HasErrors() : boolean {
        return this.state.trackLengthErrorMessage !== undefined
        || this.state.trackNameErrorMessage !== undefined
        || this.state.trackLowerBoundsErrorMessage !== undefined
        || this.state.trackUpperBoundsErrorMessage !== undefined
    }

    private async GetTrackInfoAsync(trackId: string): Promise<void> {
        var track = await this.props.webApiClient.GetTrackInfo(this.props.userStore.accessToken, trackId);

        this.setState({
            ...this.state,
            name: track.trackName!,
            activeRaceTrack: track,
            length: track.lengthInM,
            lowerBounds: track.lowerTimeBoundMs === undefined ? undefined : track.lowerTimeBoundMs / 1000,
            upperBounds: track.upperTimeBoundMs === undefined ? undefined : track.upperTimeBoundMs / 1000,
            valueChanged: false
        })
    }

    private RenderModificationWarning(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.severeWarning}
        >
            Diese Laufstrecke wurde von jemand anderem bearbeitet. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen und die geänderten Daten zu benutzen.
        </MessageBar>;
    }

    private RenderDeletionError(): ReactNode {
        return <MessageBar
            messageBarType={MessageBarType.error}
            actions={<div>
                <MessageBarButton onClick={this.DiscardChanges}>Bearbeitung abbrechen</MessageBarButton>
            </div>}
        >
            <p>Diese Laufstrecke wurde serverseitig von jemand anderem gelöscht. Es wird <em>dringend</em> empfohlen, die Bearbeitung hier abzubrechen!</p>
        </MessageBar>;
    }

    private RaceTrackModified(raceTrackModifiedArgs: EntityChangedEventArgs): void {
        if (raceTrackModifiedArgs.source !== this.props.userStore.userName && raceTrackModifiedArgs.id === this.state.activeRaceTrack?.id) {
            this.setState({
                ...this.state,
                externalChange: true,
                modificationType: raceTrackModifiedArgs.modificationType,
            })
        }

        console.log("RaceTrackModified handled." + this.state);
    }
}