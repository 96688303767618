import { Component, ReactNode } from "react";

export default class SampleMarketingContent extends Component {
    render(): ReactNode {
        return (
            <div style={{ background: "HotPink", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <h1>Es wurde keine Site mit Informationen hinterlegt!</h1>
            </div>
        )
    }
}