import { ImageFit, Label, PrimaryButton } from '@fluentui/react';
import { DocumentCard, DocumentCardDetails, DocumentCardPreview, DocumentCardType, IDocumentCardPreviewProps, IDocumentCardStyles } from '@fluentui/react/lib/DocumentCard';
import { IStyleSet, Stylesheet } from '@fluentui/react/lib/Styling';
import { TextField } from '@fluentui/react/lib/TextField';
import { inject, observer } from "mobx-react";
import { Component, CSSProperties, FormEvent, ReactNode } from "react";
import { Link } from 'react-router-dom';
import App from '../App';
import '../App.css';
import { InjectedProps } from "./InjectedProps";
import { toast } from 'react-toastify';
const gerbil = require('../images/gerbil_right_back.png');

interface ILoginState {
    userNameError: string | undefined,
    passwordError: string | undefined,
}

@inject("userStore")
@observer
export class Login extends Component<InjectedProps, ILoginState> {
    static defaultProps = {} as InjectedProps;

    private readonly passWordEmptyError = "Das Passwort darf nicht leer sein";
    private readonly userNameEmptyError = "Der Benutzername darf nicht leer sein";
    private userName: string | undefined;
    private passWord: string | undefined;

    /**
     *
     */
    constructor(props: InjectedProps) {
        super(props);

        this.state = {
            passwordError: this.passWordEmptyError,
            userNameError: this.userNameEmptyError,
        }

        this.PassWordChanged = this.PassWordChanged.bind(this);
        this.UserNameChanged = this.UserNameChanged.bind(this);
    }

    private UserNameChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        this.userName = newValue;
        this.setState({
            ...this.state,
            userNameError: newValue === undefined || newValue === "" ? this.userNameEmptyError : undefined,
        });
    }

    private PassWordChanged(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void {
        this.passWord = newValue;

        this.setState({
            ...this.state,
            passwordError: newValue === undefined || newValue === "" ? this.passWordEmptyError : undefined,
        });
    }

    render(): ReactNode {

        const documentStyle: CSSProperties = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1em",
            verticalAlign: "middle",
            height: "100vh"
        }

        const formStyle: CSSProperties = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle",
            marginLeft: "10pt"
        }

        const cardContent: CSSProperties = {
            display: "flex",
            flexDirection: "row"
        }

        return (
            <div style={documentStyle}>
                <h1 style={{ display: "flex", marginLeft: "10px", fontFamily: "Roboto Slab" }}>tagItron Gerbil</h1>

                <DocumentCard aria-label="Login" type={DocumentCardType.normal} className='cardStyle'>
                    <DocumentCardPreview previewImages={[{
                        name: "Gerbil",
                        previewImageSrc: String(gerbil),
                        width: 128,
                        height: 128,
                        imageFit: ImageFit.cover
                    }]} />
                    <DocumentCardDetails>
                        <div style={cardContent}>
                            {/* <img alt='logo' style={{ width: 250 }} src={String(gerbil)} /> */}
                            <div>
                                <form onSubmit={this.handleSubmit.bind(this)} >
                                    <div >
                                        <TextField label='Benutzername:' type="text" name="uname" required about="Der Benutzername" id="userNameTextField" onChange={this.UserNameChanged} errorMessage={this.state.userNameError} />
                                    </div>
                                    <div >
                                        <TextField label='Passwort:' type="password" name="pass" id="passWordTextField" required onChange={this.PassWordChanged} errorMessage={this.state.passwordError} />
                                    </div>
                                    <div className="Button-Container">
                                        <PrimaryButton text="Einloggen" type="submit" />
                                    </div>
                                </form>
                                <div>
                                    <div>
                                        <Link style={{ alignContent: "end", verticalAlign: "middle", margin: "0pt" }} to="/forgotPassword">Passwort vergessen?</Link>
                                    </div>
                                    <div>
                                        <Link style={{ alignContent: "end", verticalAlign: "middle", margin: "0pt" }} to="/register">Neu registrieren</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DocumentCardDetails>
                </DocumentCard>

            </div>
        );
    }

    private handleSubmit(event: FormEvent<HTMLFormElement | undefined>) {
        event.preventDefault();
        if (this.userName !== undefined && this.passWord !== undefined) {

            this.props.userStore.LoginAsync(this.userName, this.passWord).then(success => {
                if (!success) {
                    toast.error("Einloggen nicht möglich. Falschen Benutzernamen oder Passwort verwendet?");
                }
                else {
                    var state = this.props.userStore.TokenState;
                }
            })
        }
    }
}