import { FontSizes, Pivot, PivotItem } from "@fluentui/react";
import { inject, observer } from "mobx-react";
import { Component, CSSProperties } from "react";
import { toast, ToastOptions } from "react-toastify";
import Clock from "../../Components/Clock";
import MainAdmin from "../Admin/MainAdmin";
import { InjectedProps } from "../InjectedProps";
import EditUser from "./EditUser";
import LiveLog from "../Admin/LiveLog";

interface UserPageState {
}

@inject("userStore")
@inject("webApiClient")
@inject("signalRClient")
@observer
export default class UserPage extends Component<InjectedProps, UserPageState> {
    static defaultProps = {} as InjectedProps;

    constructor(props: InjectedProps, state: UserPageState) {
        super(props);
    }

    componentDidMount(): void {
        this.DetachFromSignalR();
        this.AttachToSignalR();
    }

    private DetachFromSignalR(): void {
        this.props.signalRClient.onBroadcastMessageReceivedEvent.unsubscribe(this.BroadcastMessageReceived);
    }

    BroadcastMessageReceived(message: string) {
        toast((
            <div>
                Eine neue Nachricht wurde empfangen:<br />
                {message}
            </div>
        ), {
            autoClose: 10000,
            type: toast.TYPE.INFO,
            hideProgressBar: false,
            position: 'top-center'
        });
    }

    private AttachToSignalR(): void {
        this.props.signalRClient.onBroadcastMessageReceivedEvent.subscribe(this.BroadcastMessageReceived);
    }
    render() {
        var mainStyle: CSSProperties = {
            width: "100%",
            // height: "100vh",
            display: "flex"
        }

        return (<div style={mainStyle}>
            <Pivot style={{width: "100%"}}>
                <PivotItem headerText="Profil bearbeiten" headerButtonProps={{
                    'data-order': 1,
                    'data-title': 'My Files Title',
                }}><EditUser showChangePasswordLink={true} /></PivotItem>
                {this.props.userStore.hasAdminRole ? <PivotItem headerText="Administration"><MainAdmin /></PivotItem> : <div />}
                {this.props.userStore.hasAdminRole ? <PivotItem headerText="Live-Log" style={{width: "100%"}}><LiveLog /></PivotItem> : <div />}
            </Pivot>
        </div>);
    }
}